import { Form, Input, Select } from 'antd'
import { map } from 'lodash'
import { callAxios } from 'plugins/api'
import React, { useState } from 'react'
import { useQueries } from 'react-query'

import Button from 'components/button'
import { useAuth } from 'modules/auth/context/useAuth'
import { useParams } from 'react-router-dom'
import Loading from '../../../../components/loading'

interface QuoteInformationProps {
    formik: any
}

const roundedClass = 'rounded-lg flex-1'
const size = 'large'

const QuoteInformation = (props: QuoteInformationProps) => {
    const { formik } = props
    const { quoteId } = useParams()
    const [users, setUserList] = useState<[]>([])
    const { userExtra } = useAuth()

    const quoteInfos = useQueries([
        {
            queryKey: ['quoteLastInfo'],
            queryFn: () =>
                callAxios({
                    method: 'get',
                    url: 'quote/get/lastQuote',
                }),
            onSuccess: (data: any) => {
                const name = `NLV-QUO-${parseInt(data.data[0].id + 1, 10)}`
                formik.setFieldValue('quotation_name', name)
            },
            enabled: !quoteId,
        },
        {
            queryKey: ['userList'],
            queryFn: () =>
                callAxios({
                    method: 'get',
                    url: 'auth/users',
                }),
            onSuccess: (data: any) => {
                const userList = data?.data.map(
                    (user: { name: string; id: string; email: string; role_name: string }) => {
                        return {
                            label: `${user.name} | ${user.role_name} | [${user.email}]`,
                            value: user.id,
                        }
                    },
                )
                setUserList(userList)
            },
        },
    ])

    const isLoading = quoteInfos.some(result => result.isLoading)
    if (isLoading) {
        return (
            <div className="flex justify-center items-center bg-white px-8 py-4 rounded-xl gap-4">
                <Loading />
            </div>
        )
    }

    return (
        <div className="bg-white w-full py-8 px-8 rounded-lg flex flex-col gap-4">
            <h1 className="text-3xl text-center">Quote Information</h1>
            <div className="flex flex-col justify-center align-center items-center">
                <Form.Item
                    name="quotation_name"
                    className="flex flex-col w-[45%]"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                    ]}
                >
                    <div>
                        <h5 className="capitalize">Quotation Name</h5>
                    </div>
                    <Input
                        name="quotation_name"
                        value={formik.values.quotation_name}
                        onChange={formik.handleChange}
                        placeholder="Enter quotation name"
                        size={size}
                        className={roundedClass}
                    />
                    {formik.errors &&
                        formik.errors.quotation_name &&
                        formik.touched.quotation_name && (
                            <p className="text-red-600 mt-1">{formik.errors.quotation_name}</p>
                        )}
                </Form.Item>
                {userExtra.role_id === 4 && (
                    <Form.Item
                        name="user"
                        className="flex flex-col w-[45%]"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <div>
                            <h5 className="capitalize">User</h5>
                        </div>
                        <Select
                            placeholder="Choose User"
                            defaultValue={formik.values.user || ''}
                            onChange={(value: string) => formik.setFieldValue('user', value)}
                            className="rounded-lg"
                            size={size}
                        >
                            {map(users || [], (option: any) => (
                                <Select.Option value={option?.value}>{option?.label}</Select.Option>
                            ))}
                        </Select>
                        {formik.errors && formik.errors.user && formik.touched.user && (
                            <p className="text-red-600 mt-1">{formik.errors.user}</p>
                        )}
                    </Form.Item>
                )}

                <Form.Item
                    name="quotation_name"
                    className="flex flex-col w-[45%]"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                    ]}
                >
                    <div>
                        <h5 className="capitalize">Customer Name (Quotation For)</h5>
                    </div>
                    <Input
                        name="quotation_for"
                        value={formik.values.quotation_for}
                        onChange={formik.handleChange}
                        placeholder="Enter customer name"
                        size={size}
                        className={roundedClass}
                    />
                    {formik.errors &&
                        formik.errors.quotation_for &&
                        formik.touched.quotation_for && (
                            <p className="text-red-600 mt-1">{formik.errors.quotation_for}</p>
                        )}
                </Form.Item>
                <div>
                    <div className="w-48">
                        <Button
                            variant="primary"
                            type="submit"
                            label="Next"
                            isLoading={false}
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuoteInformation
