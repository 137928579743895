/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import classNames from 'classnames'
import { IoIosArrowBack } from 'react-icons/io'
import { Link, Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { FiDownloadCloud } from 'react-icons/fi'
import { BsDownload, BsTrashFill } from 'react-icons/bs'
import { BiEdit } from 'react-icons/bi'
import { TiCancel } from 'react-icons/ti'
import { MdOutlineSwipe } from 'react-icons/md'
import { useMutation, useQuery } from 'react-query'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { RxHamburgerMenu } from 'react-icons/rx'
import { RiCloseFill } from 'react-icons/ri'

import { getPillsColor } from 'utils/pills'
import { errorToast, successToast } from 'utils/toasterUtil'
import { downloadExcelDoc, downloadWordDoc } from 'utils/downloadDocuments'
import DeleteModal from 'components/modal/deleteModal'
import ButtonLoader from 'components/buttonLoader'
import { useAuth } from 'modules/auth/context/useAuth'
import SmallLoading from 'components/loading/loading'
import { cancelOrder, deleteOrder, getOrderDetail, reOrder } from './queries/order'
import Loading from '../../components/loading'

const cardBox =
    'bg-white drop-shadow-md rounded-md py-6 px-6 flex flex-col gap-4 md:gap-8 overflow-y-auto'

type ContextType = {
    orderConfigurations: [] | null
    isLoading: boolean
}

const OrderLayout = () => {
    const [orderDetail, setOrderDetail] = useState<any>({})
    const [orderConfigurations, setOrderConfigurations] = useState([])
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [color, setColor] = useState<any>({})
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const {
        userExtra: { order_form_visibility, cancel_order, submit_order_form },
    } = useAuth()
    const { orderId } = useParams()
    const navigate = useNavigate()
    const { user, userExtra } = useAuth()

    const downloadWordDocAction = useMutation(['quote.download.word'], (payload: string) =>
        downloadWordDoc(payload),
    )

    const downloadExcelDocAction = useMutation(['quote.download.word'], (payload: string) =>
        downloadExcelDoc(payload),
    )

    const { isLoading, refetch } = useQuery(
        ['orderDetail', orderId],
        () =>
            getOrderDetail({
                orderId: orderId || '',
                userId: user.id,
                isSupport: userExtra.is_support,
                isDealer: userExtra.is_dealer,
                isManager: userExtra.is_manager,
                roleId: userExtra.role_id,
            }),
        {
            onSuccess: data => {
                setOrderConfigurations(data?.data?.configurations || [])
                setOrderDetail(data?.data?.order[0] || null)
                setColor(getPillsColor(data?.data?.order[0].status))
            },
        },
    )

    const deleteOrderAction = useMutation(['deleteOrdr'], () => deleteOrder(orderDetail.order_id), {
        onSuccess: data => {
            if (data.data.message === 'Order Deleted successfully') {
                toast(
                    data.data.message || `Failed to delete order ${orderDetail.order_name}`,
                    successToast,
                )
                navigate(-1)
            } else {
                toast(
                    data.data.message || `Failed to delete order ${orderDetail.order_name}`,
                    errorToast,
                )
            }
        },
        onError: (error: any) => {
            toast(error?.message || `Failed to delete order ${orderDetail.order_name}`, errorToast)
        },
    })

    const handleDelete = () => {
        deleteOrderAction.mutate()
        setDeleteModal(false)
    }

    const handleReOrder = useMutation(
        'reOrder',
        () =>
            reOrder(
                orderDetail.order_id,
                user.id,
                userExtra.is_support,
                userExtra.is_dealer,
                userExtra.is_manager,
                userExtra.role_id,
            ),
        {
            onSuccess: data => {
                toast.success(data?.message || `Order ${orderDetail.order_name} made successfully`)
                refetch()
            },
            onError: (error: any) => {
                toast(error?.message || `Failed to order ${orderDetail.order_name}`, errorToast)
            },
        },
    )

    const handleCancelOrder = useMutation(
        'reOrder',
        () =>
            cancelOrder(
                orderDetail.order_id,
                user.id,
                userExtra.is_support,
                userExtra.is_dealer,
                userExtra.is_manager,
                userExtra.role_id,
            ),
        {
            onSuccess: (data: any) => {
                toast.success(
                    data?.message || `Order ${orderDetail.order_name} marked cancel successfullu`,
                )
                refetch()
            },
            onError: (error: any) => {
                toast(
                    error?.message || `Failed to cancel order ${orderDetail.order_name}`,
                    errorToast,
                )
            },
        },
    )

    if (isLoading) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    if (!isLoading && !orderDetail) {
        return (
            <div className="container my-5 text-center">
                <strong>
                    Either there is something wrong with the order (can be deleted by the user) or
                    you don&#39;t have proper authority to access this order, please contact the
                    administrator. Then we will try to fix it.
                </strong>
            </div>
        )
    }

    return (
        <div className=" w-full flex gap-4">
            <div
                className={classNames(
                    cardBox,
                    openMenu
                        ? 'block absolute z-50 top-[8%] left-5 w-[90%] drop-shadow-2xl border borer-base-400 h-[73vh]'
                        : 'w-1/4 max-w-[22rem] md:block md:relative top-0 left-0 md:drop-shadow-none md:border-none hidden',
                )}
            >
                <div
                    className="flex items-center gap-2 hover:text-primary cursor-pointer"
                    onClick={() => navigate(-1)}
                >
                    <span>
                        <IoIosArrowBack size={24} color="#E30613" />
                    </span>
                    <span className="font-bold">Go Back</span>
                </div>
                <div className="flex flex-col justify-start gap-8 items-center mb-5">
                    <div className="w-full">
                        <p className="font-bold text-placeholderColor text-left">Order Name</p>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to="#">
                            <p className="text-extrabold text-lg text-blue-500">
                                {orderDetail.order_name}
                            </p>
                        </Link>
                    </div>
                    <div className="flex flex-col gap-3 w-full">
                        <div className="flex items-center gap-3">
                            <p className="flex-1 text-placeholderColor text-left">Quote Name</p>
                            <p className="flex-1 text-left flex justify-end text-sm">
                                {orderDetail.quotation_name}
                            </p>
                        </div>
                        <div className="flex items-center gap-3">
                            <p className="flex-1 text-placeholderColor text-left">Quote Number</p>
                            <p className="flex-1 text-left flex justify-end">
                                #{orderDetail.quote_id}
                            </p>
                        </div>
                        <div className="flex items-center justify-end gap-3">
                            <p className="flex-1 text-placeholderColor text-left">Order Status</p>
                            <div className="flex-1 flex justify-end">
                                <div
                                    className={classNames(
                                        'capitalize font-bold text-xs',
                                        color.bg,
                                        color.text,
                                        'rounded-full py-1 px-4 w-fit text-center',
                                    )}
                                >
                                    {String(orderDetail.status).split(' ')[0]}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center gap-3">
                            <p className="flex-1 text-placeholderColor text-left">Customer Name</p>
                            <p className="flex-1 text-left flex justify-end text-sm">
                                {orderDetail.quotation_for}
                            </p>
                        </div>
                        <div className="flex gap-3 items-center">
                            <p className="flex-1 text-placeholderColor text-left">Created By</p>
                            <p className="flex-1 text-left flex justify-end text-sm">
                                {orderDetail?.created_by}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-5">
                    <hr />
                    <div
                        className="flex items-center gap-4 cursor-pointer hover:text-primary"
                        onClick={() => downloadWordDocAction.mutate(orderDetail.quote_id)}
                    >
                        {downloadWordDocAction.isLoading && <SmallLoading />}
                        <FiDownloadCloud size={24} />
                        <span>Download Documents</span>
                    </div>
                    <hr />
                    <div
                        className="flex items-center gap-4 cursor-pointer hover:text-primary"
                        onClick={() =>
                            downloadExcelDocAction.mutate(orderDetail.quotation_file_xls)
                        }
                    >
                        {downloadExcelDocAction.isLoading && <SmallLoading />}
                        <BsDownload size={24} />
                        <span>Download Excel Sheet</span>
                    </div>
                    <hr />
                    {order_form_visibility && (
                        <>
                            <Link to={`/order/${orderDetail.order_id}/order-form`}>
                                <div className="flex items-center gap-4 cursor-pointer hover:text-primary">
                                    <BiEdit size={30} />
                                    <span>
                                        {orderDetail.customer_id && submit_order_form
                                            ? 'Update Order Form'
                                            : 'Order Form'}
                                    </span>
                                </div>
                            </Link>
                            <hr />
                        </>
                    )}

                    {orderDetail.status === 'cancelled' ? (
                        <>
                            <div
                                className="flex items-center gap-4 cursor-pointer hover:text-primary"
                                onClick={() => handleReOrder.mutate()}
                            >
                                <MdOutlineSwipe size={24} />
                                <span>Re Order</span>
                            </div>
                            <hr />
                        </>
                    ) : cancel_order ? (
                        <>
                            <div
                                className="flex items-center gap-4 cursor-pointer hover:text-primary"
                                onClick={() => handleCancelOrder.mutate()}
                            >
                                <TiCancel size={24} />
                                <span>Cancel Order</span>
                            </div>
                            <hr />
                        </>
                    ) : null}

                    <div
                        className="flex items-center justify-start gap-4 cursor-pointer hover:text-primary"
                        onClick={() => setDeleteModal(!deleteModal)}
                    >
                        {deleteOrderAction.isLoading ? (
                            <ButtonLoader />
                        ) : (
                            <>
                                <BsTrashFill size={24} />
                                <span>Delete Order</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className={classNames(cardBox, 'flex-1 h-[86vh] overflow-y-scroll')}>
                <div
                    className="md:hidden block w-fit"
                    title="open quote sidebar"
                    onClick={() => setOpenMenu(!openMenu)}
                >
                    {openMenu ? <RiCloseFill size={30} /> : <RxHamburgerMenu size={30} />}
                </div>
                <Outlet context={{ orderConfigurations }} />
            </div>
            <DeleteModal
                modal={deleteModal}
                closeModal={() => setDeleteModal(!deleteModal)}
                label="Are you sure you want to delete this order?"
                onConfirm={handleDelete}
            />
        </div>
    )
}

export default OrderLayout

export const useOrderLayout = () => {
    return useOutletContext<ContextType>()
}
