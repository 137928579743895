import { Descriptions } from 'antd'
import DescriptionsItem from 'antd/es/descriptions/Item'
import { TbDiscount, TbDiscountOff } from 'react-icons/tb'
import Input from '../../../../components/input'
import Quantity from '../../../../components/quantity'
import { getCurrencySymbol } from '../../../../utils/currency/formatCurrency'

interface ConfigurationCard {
    configurationName: string
    productCategory: string
    retailPrice: number
    dataAccessoriesPrice: number
    discountPercentage: number
    discountPercentageAmount: number
    netTotal: number
    oemDiscountAmount: number
    netNetPurchasePrice: number
    extraDiscount: number
    extraExtraDiscountPercentageAmount: number
    netNetNetPurchasePrice: number
    endCustomerPurchasePrice: number
    configurationQuantity: number
    currencySymbol: string
    discountAmount: number
    customerDiscountPercentage: number
    dealerDiscountAmount: number
    handelDealerDiscountChange: (amount: number) => void
    salesMarginAmount: number
    salesMarginPercentage: number
    totalCostPriceAllMachine: number
    totalPrice: number
    totalMargin: number
    retailMargin: number
    netRetailPrice: number
    isLibrary: boolean
    showRetailMargin: any
    showIsLibrary?: boolean
    setIsLibrary: (name: string, value: boolean) => void
    setDiscountPercentage: (amount: number) => void
    handleDiscountAmountChange: (amount: number) => void
    handleOemDiscountAmountChange: (amount: number) => void
    handleExtraDiscountChanges: (amount: number) => void
    handleEndCustomerPurchasePriceChanges: (amount: number) => void
    setConfigurationQuantity: (name: string, quantity: number) => void
    isExtraDiscountVisible?: boolean
    isExtraDiscountVisibleAndEditable?: boolean
    showDealerView: boolean
    userRole: string
    isCostPriceVisible: boolean
    isProfitMarginVisible: boolean
    isOemEditable: boolean
    isExtraExtraDiscountEditable: boolean
    isSupport: boolean
    isSalesMarginVisible: boolean
    isCustomerDiscountVisible: boolean
    landedCost: number
    tradeInValue: number
    maxDiscountApplicable?: number
    user?: string
    hasDataAccessories?: boolean
    setTradeInValue: (amount: number) => void
}

const ConfigurationCard = (props: ConfigurationCard) => {
    const {
        configurationName,
        productCategory,
        retailPrice,
        netRetailPrice,
        currencySymbol,
        discountAmount,
        handleDiscountAmountChange,
        discountPercentageAmount,
        discountPercentage,
        dealerDiscountAmount,
        handelDealerDiscountChange,
        netTotal,
        oemDiscountAmount,
        setDiscountPercentage,
        handleOemDiscountAmountChange,
        netNetPurchasePrice,
        extraDiscount,
        handleExtraDiscountChanges,
        extraExtraDiscountPercentageAmount,
        netNetNetPurchasePrice,
        endCustomerPurchasePrice,
        totalPrice,
        configurationQuantity,
        setConfigurationQuantity,
        customerDiscountPercentage,
        handleEndCustomerPurchasePriceChanges,
        salesMarginPercentage,
        salesMarginAmount,
        totalCostPriceAllMachine,
        totalMargin,
        retailMargin,
        showDealerView,
        userRole,
        isExtraExtraDiscountEditable,
        isCostPriceVisible,
        isProfitMarginVisible,
        isSalesMarginVisible,
        isOemEditable,
        isCustomerDiscountVisible,
        isExtraDiscountVisible,
        isExtraDiscountVisibleAndEditable,
        isSupport,
        showRetailMargin,
        isLibrary,
        showIsLibrary,
        user,
        setIsLibrary,
        landedCost,
        tradeInValue,
        maxDiscountApplicable,
        setTradeInValue,
        dataAccessoriesPrice,
        hasDataAccessories,
    } = props

    return (
        <div className="container w-full rounded-sm">
            <Descriptions title="" layout="horizontal" column={{ xs: 1, sm: 1, md: 2 }} bordered>
                <DescriptionsItem label="Configuration Name">{configurationName}</DescriptionsItem>
                <DescriptionsItem label="Product Category">{productCategory}</DescriptionsItem>
                {!userRole.toLowerCase().includes('customer') ? (
                    <>
                        <DescriptionsItem label="Net Retail Price">
                            {retailPrice?.toFixed(2)}
                            <span className="ml-2">{getCurrencySymbol(currencySymbol)}</span>
                        </DescriptionsItem>
                        {hasDataAccessories ? (
                            <>
                                <DescriptionsItem
                                    label={
                                        <div className="flex items-center gap-2 cursor-pointer">
                                            <p>
                                                <TbDiscount size={22} className="text-green-300" />
                                            </p>
                                            <p>Discount Applicable Amount</p>
                                        </div>
                                    }
                                >
                                    {netRetailPrice?.toFixed(2)}
                                    <span className="ml-2">
                                        {getCurrencySymbol(currencySymbol)}
                                    </span>
                                </DescriptionsItem>

                                <DescriptionsItem
                                    label={
                                        <div className="flex items-center gap-2 cursor-pointer">
                                            <p>
                                                <TbDiscountOff size={22} className="text-red-300" />
                                            </p>
                                            <p>Discount Not Applicable Amount</p>
                                        </div>
                                    }
                                >
                                    {dataAccessoriesPrice?.toFixed(2)}
                                    <span className="ml-2">
                                        {getCurrencySymbol(currencySymbol)}
                                    </span>
                                </DescriptionsItem>
                            </>
                        ) : null}
                        {showDealerView ? (
                            <>
                                <DescriptionsItem label="Standard Dealer Terms %">
                                    <div className="flex items-center gap-2">
                                        <div className="w-28">
                                            <Input
                                                customInputStyles="w-24 py-0 px-1"
                                                type="number"
                                                value={discountPercentage}
                                                name="discount_amount"
                                                disabled
                                                onChange={e =>
                                                    setDiscountPercentage(
                                                        parseFloat(e.target.value),
                                                    )
                                                }
                                            />
                                        </div>
                                        <p className="font-bold text-lg">%</p>
                                    </div>
                                </DescriptionsItem>
                                <DescriptionsItem label="Dealer Net % Amount">
                                    {discountPercentageAmount.toFixed(2)}
                                    {getCurrencySymbol(currencySymbol)}
                                </DescriptionsItem>
                            </>
                        ) : (
                            <>
                                <DescriptionsItem label="Discount Percentage">
                                    <div className="flex items-center gap-2">
                                        <div className="w-28">
                                            <Input
                                                customInputStyles="w-24 py-0 px-1"
                                                type="number"
                                                value={discountPercentage}
                                                name="discount_amount"
                                                max={maxDiscountApplicable}
                                                onChange={e =>
                                                    setDiscountPercentage(
                                                        parseFloat(e.target.value),
                                                    )
                                                }
                                            />
                                        </div>
                                        <p className="font-bold text-lg">%</p>
                                    </div>
                                </DescriptionsItem>
                                <DescriptionsItem label="Discount Percentage Amount">
                                    {discountPercentageAmount.toFixed(2)}
                                    <span className="ml-2">
                                        {getCurrencySymbol(currencySymbol)}
                                    </span>
                                </DescriptionsItem>
                                <DescriptionsItem label="Discount Amount">
                                    <div className="flex items-center gap-2">
                                        <div className="w-28">
                                            <Input
                                                customInputStyles="w-24 py-0 px-1"
                                                type="number"
                                                value={discountAmount}
                                                name="discount_amount"
                                                onChange={e =>
                                                    handleDiscountAmountChange(
                                                        parseFloat(e.target.value),
                                                    )
                                                }
                                            />
                                        </div>
                                        <p className="font-bold text-md">
                                            {' '}
                                            {getCurrencySymbol(currencySymbol)}
                                        </p>
                                    </div>
                                </DescriptionsItem>
                            </>
                        )}
                        {showDealerView ? (
                            <DescriptionsItem label="Dealer Net Total">
                                <div className="flex items-center gap-1">
                                    <div>{netTotal.toFixed(2)}</div>
                                    <p> {getCurrencySymbol(currencySymbol)}</p>
                                </div>
                            </DescriptionsItem>
                        ) : (
                            <DescriptionsItem label="Net Total">
                                <div className="flex items-center gap-1">
                                    <div>{netTotal.toFixed(2)}</div>
                                    <p> {getCurrencySymbol(currencySymbol)}</p>
                                </div>
                            </DescriptionsItem>
                        )}
                        {showDealerView ? (
                            isOemEditable ? (
                                <>
                                    <DescriptionsItem label="OEM Discount Amount">
                                        <div className="flex items-center gap-2">
                                            <div className="w-28">
                                                <Input
                                                    customInputStyles="w-24 py-0 px-1"
                                                    type="number"
                                                    value={oemDiscountAmount}
                                                    name="discount_amount"
                                                    onChange={e =>
                                                        handleOemDiscountAmountChange(
                                                            parseFloat(e.target.value),
                                                        )
                                                    }
                                                />
                                            </div>
                                            <p className="font-bold text-md">
                                                {getCurrencySymbol(currencySymbol)}
                                            </p>
                                        </div>
                                    </DescriptionsItem>
                                    <DescriptionsItem label="Net Net Purchase Price">
                                        <div className="flex items-center gap-1">
                                            <div>{netNetPurchasePrice.toFixed(2)}</div>
                                            <p> {getCurrencySymbol(currencySymbol)}</p>
                                        </div>
                                    </DescriptionsItem>
                                </>
                            ) : (
                                <>
                                    <DescriptionsItem label="OEM Discount Amount">
                                        {oemDiscountAmount} {getCurrencySymbol(currencySymbol)}
                                    </DescriptionsItem>
                                    <DescriptionsItem label="Net Net Purchase Price">
                                        <div className="flex items-center gap-1">
                                            <div>{netNetPurchasePrice.toFixed(2)}</div>
                                            <p> {getCurrencySymbol(currencySymbol)}</p>
                                        </div>
                                    </DescriptionsItem>
                                </>
                            )
                        ) : null}
                        {showDealerView ? (
                            isExtraExtraDiscountEditable ? (
                                <>
                                    <DescriptionsItem label="Extra Discount">
                                        <div className="flex item-center gap-2">
                                            <div className="w-28">
                                                <Input
                                                    customInputStyles="w-24 py-0 px-1"
                                                    type="number"
                                                    value={extraDiscount || 0}
                                                    name="discount_amount"
                                                    onChange={e =>
                                                        handleExtraDiscountChanges(
                                                            parseFloat(e.target.value || '0'),
                                                        )
                                                    }
                                                />
                                            </div>
                                            <p className="font-bold text-lg">%</p>
                                        </div>
                                    </DescriptionsItem>
                                    <DescriptionsItem label="Extra Extra Support">
                                        <div className="flex items-center gap-2">
                                            <div>
                                                {extraExtraDiscountPercentageAmount.toFixed(2)}{' '}
                                            </div>
                                            <p className="font-bold text-md">
                                                {getCurrencySymbol(currencySymbol)}
                                            </p>
                                        </div>
                                    </DescriptionsItem>
                                </>
                            ) : (
                                <>
                                    <DescriptionsItem label="Extra Discount">
                                        <div className="flex items-center gap-2">
                                            <div>{extraDiscount.toFixed(2)}</div>
                                            <p className="font-bold text-lg">%</p>
                                        </div>
                                    </DescriptionsItem>
                                    <DescriptionsItem label="Extra Extra Support">
                                        <div className="flex items-center gap-2">
                                            <div>
                                                {extraExtraDiscountPercentageAmount.toFixed(2)}{' '}
                                            </div>
                                            <p className="font-bold text-md">
                                                {getCurrencySymbol(currencySymbol)}
                                            </p>
                                        </div>
                                    </DescriptionsItem>
                                </>
                            )
                        ) : null}
                        {!showDealerView ? (
                            isExtraDiscountVisible ? (
                                isExtraDiscountVisibleAndEditable ? (
                                    <DescriptionsItem label="Extra Discount">
                                        <div className="flex item-center gap-2">
                                            <div className="w-28">
                                                <Input
                                                    customInputStyles="w-24 py-0 px-1"
                                                    type="number"
                                                    value={dealerDiscountAmount || 0}
                                                    name="discount_amount"
                                                    onChange={e =>
                                                        handelDealerDiscountChange(
                                                            parseFloat(e.target.value || '0'),
                                                        )
                                                    }
                                                />
                                            </div>
                                            <p className="font-bold text-md">
                                                {getCurrencySymbol(currencySymbol)}
                                            </p>
                                        </div>
                                    </DescriptionsItem>
                                ) : (
                                    <DescriptionsItem label="Extra Discount">
                                        <div className="flex items-center gap-2">
                                            <div>{dealerDiscountAmount.toFixed(2)}</div>
                                            <p className="font-bold text-md">
                                                {getCurrencySymbol(currencySymbol)}
                                            </p>
                                        </div>
                                    </DescriptionsItem>
                                )
                            ) : null
                        ) : null}
                        {showDealerView ? (
                            <DescriptionsItem label="Net Net Net Price">
                                <div className="flex items-center gap-2">
                                    <div>{netNetNetPurchasePrice.toFixed(2)} </div>
                                    <p className="font-bold text-md">
                                        {getCurrencySymbol(currencySymbol)}
                                    </p>
                                </div>
                            </DescriptionsItem>
                        ) : null}
                        {isSalesMarginVisible ? (
                            <DescriptionsItem label="End Customer Purchase Price">
                                <div className="flex items-center gap-2">
                                    <div className="w-28">
                                        <Input
                                            customInputStyles="w-24 py-0 px-1"
                                            type="number"
                                            value={endCustomerPurchasePrice}
                                            name="end_customer_purchase_price"
                                            onChange={e =>
                                                handleEndCustomerPurchasePriceChanges(
                                                    parseFloat(e.target.value),
                                                )
                                            }
                                        />
                                    </div>
                                    <p className="font-bold text-md">
                                        {getCurrencySymbol(currencySymbol)}
                                    </p>
                                </div>
                            </DescriptionsItem>
                        ) : null}
                        {isSalesMarginVisible ? (
                            showDealerView ? (
                                <>
                                    <DescriptionsItem label="Dealer Margin Amount">
                                        <div className="flex items-center gap-2">
                                            <div>
                                                {salesMarginAmount > 0
                                                    ? salesMarginAmount.toFixed(2)
                                                    : 0.0}{' '}
                                            </div>
                                            <p className="font-bold text-md">
                                                {getCurrencySymbol(currencySymbol)}
                                            </p>
                                        </div>
                                    </DescriptionsItem>
                                    <DescriptionsItem label="Dealer Margin">
                                        {salesMarginPercentage > 0
                                            ? salesMarginPercentage.toFixed(2)
                                            : 0.0}{' '}
                                        {' %'}
                                    </DescriptionsItem>
                                </>
                            ) : null
                        ) : null}
                    </>
                ) : null}

                {/* {isCustomerDiscountVisible
                    ? !showDealerView && (
                          <DescriptionsItem label="Customer Discount">
                              {customerDiscountPercentage.toFixed(2)} {' %'}
                          </DescriptionsItem>
                      )
                    : null} */}
                {isSupport ? <Descriptions.Item label="For User">{user}</Descriptions.Item> : null}
                {showIsLibrary && (
                    <DescriptionsItem label="Library Configuration">
                        <div className="w-28">
                            <input
                                type="checkbox"
                                checked={isLibrary}
                                name="Library Configuration"
                                onChange={() => setIsLibrary('isLibrary', !isLibrary)}
                            />
                        </div>
                    </DescriptionsItem>
                )}
                <DescriptionsItem label="Configuration Quantity">
                    <Quantity
                        name="quantity"
                        quantity={configurationQuantity}
                        disabled={Boolean(userRole.toLowerCase().includes('customer'))}
                        min={1}
                        max={20}
                        setQuantity={(quantity = 1) => {
                            setConfigurationQuantity('quantity', quantity)
                        }}
                    />
                </DescriptionsItem>
                {isCostPriceVisible &&
                    (!showDealerView ? (
                        <DescriptionsItem label="Total Cost [all machines]">
                            {totalCostPriceAllMachine.toFixed(2)}{' '}
                            {getCurrencySymbol(currencySymbol)}
                        </DescriptionsItem>
                    ) : null)}
                {!showDealerView ? (
                    <DescriptionsItem label="Total Price [all machines]">
                        {totalPrice.toFixed(2)} {getCurrencySymbol(currencySymbol)}
                    </DescriptionsItem>
                ) : null}
                {isProfitMarginVisible && !showDealerView ? (
                    <DescriptionsItem label="Total Margin [all machines]">
                        {totalMargin.toFixed(2)} {' %'}
                    </DescriptionsItem>
                ) : null}
                {showRetailMargin ? (
                    landedCost ? (
                        <DescriptionsItem label="Retail Margin">
                            {retailMargin.toFixed(2)} {' %'}
                        </DescriptionsItem>
                    ) : null
                ) : null}
                {!showDealerView && (
                    <DescriptionsItem label="Parts exchange (Trade In)">
                        <div className="flex items-center gap-2">
                            <div className="w-1/3">
                                <Input
                                    value={tradeInValue}
                                    name="trade_in_value"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTradeInValue(parseInt(e.target.value, 10))
                                    }
                                />
                            </div>
                            <p>{getCurrencySymbol(currencySymbol)}</p>
                        </div>
                    </DescriptionsItem>
                )}
                {!showDealerView && <div />}
                {!showDealerView && (
                    <DescriptionsItem label="Price for the customer (salesprice - part exchange)">
                        {(totalPrice - tradeInValue).toFixed(2)} {getCurrencySymbol(currencySymbol)}
                    </DescriptionsItem>
                )}
            </Descriptions>
        </div>
    )
}

export default ConfigurationCard
