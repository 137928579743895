/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { filter } from 'lodash'

export type StepType = {
    id: string | number
    step: number
    label: string
    hidden?: boolean
    component: React.ReactElement
}

interface StepperProps {
    steppers: StepType[]
    activeStep: StepType
    changeActiveStep?: (step: number) => void
}

const Stepper = (props: StepperProps) => {
    const { steppers, activeStep, changeActiveStep } = props
    return (
        <div className="flex w-full flex-row flex-wrap items-center gap-12 rounded-xl py-2 px-4 bg-red-200 z-50">
            {filter(steppers, item => {
                return !item.hidden
            }).map((item: StepType) => {
                const activeStepperClass = `h-8 w-8 rounded-full grid place-content-center ${
                    activeStep.id === item.id
                        ? 'bg-secondary text-white'
                        : 'bg-white  border-placeholderColor border-2 text-placeholderColor'
                }`
                const textColor = activeStep.id === item.id ? 'text-black' : 'text-placeholderColor'
                return (
                    <div
                        className="flex items-center gap-3 cursor-pointer hover:text-primary"
                        onClick={() => (changeActiveStep ? changeActiveStep(item.step) : null)}
                    >
                        <div className={activeStepperClass}>
                            <span className="text-lg font-extrabold">{item.step + 1}</span>
                        </div>
                        <div className="flex-col justify-start gap-1 md:flex hidden">
                            <p className="text-placeholderColor text-xs">Step {item.step + 1}</p>
                            <h5 className={`leading-3 text-xs ${textColor} hover:text-primary`}>
                                {item.label}
                            </h5>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Stepper
