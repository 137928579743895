import DetailConfiguartion from 'modules/configuration/views/detailConfiguration'

const QuoteInfo = () => {
    return (
        <div className="flex flex-col gap-4">
            <div>
                <div>
                    <p className="text-black text-2xl">Configurations Detail</p>
                    <hr className="mt-4 text-black border-[#E0E0E0]" />
                </div>
            </div>
            <DetailConfiguartion hideImage />
        </div>
    )
}

export default QuoteInfo
