import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from '../../../components/button'

const ConfigurationSuccess = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const navigateToNewConfiguration = () => {
        navigate('/configuration/create')
    }

    const navigateToConfiguration = () => {
        navigate('/configuration')
    }

    const navigateToNewQuote = () => {
        navigate('/quotes')
    }

    const goBackQuote = () => {
        navigate(`/quotes/${searchParams.get('quoteId')}`)
    }

    return (
        <div className="mt-4 px-8 py-4 rounded-xl flex flex-col justify-between gap-4 bg-white w-full">
            <div className="w-full border-b-2 border-placeholderColor">
                <h5 className="heading-3 text-lg text-placeholderColor">Review & Confirm</h5>
            </div>
            <div className="w-full display flex flex-col justify-center">
                <div className="container1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-success"
                        viewBox="0 0 24 24"
                    >
                        <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                            <circle className="success-circle-outline" cx="12" cy="12" r="11.5" />
                            <circle className="success-circle-fill" cx="12" cy="12" r="11.5" />
                            <polyline className="success-tick" points="17,8.5 9.5,15.5 7,13" />
                        </g>
                    </svg>
                </div>
                <p className="text-lg text-center">Configuration Successful</p>
            </div>
            <div className="buttons flex w-full justify-center items-center gap-4">
                <div className="w-64">
                    <Button
                        label="Create New Configuration"
                        onClick={navigateToNewConfiguration}
                        variant="primary"
                    />
                </div>
                <div className="w-64">
                    <Button
                        label="Go to Configuration"
                        onClick={navigateToConfiguration}
                        variant="primary"
                    />
                </div>
                <div className="w-64">
                    <Button
                        label="Create New Quote"
                        onClick={navigateToNewQuote}
                        variant="primary"
                    />
                </div>
                {searchParams.get('quoteId') && searchParams.get('quoteId') !== null && (
                    <div className="w-64">
                        <Button label="Go To Quote" onClick={goBackQuote} variant="primary" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ConfigurationSuccess
