/* eslint-disable */
import { useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import { Collapse, Tooltip } from 'antd'
import { useQuery } from 'react-query'
import { CreateConfigurationType, ManualAdjustmentType } from '../../views/createConfiguration'
import ConfigurationCard from '../details/configurationCard'
import Table from '../../../../components/table'
import PanelHeader from '../panelHeader'
import ManualAdjustment from './manualAdjustment'
import { ConfigurationQueries } from '../../queries/configuration'
import { useAuth } from '../../../auth/context/useAuth'
import { toast } from 'react-toastify'
import { errorToast } from '../../../../utils/toasterUtil'
import { getCurrencySymbol } from '../../../../utils/currency/formatCurrency'
import { includes, some } from 'lodash'
import { TbDiscount, TbDiscountOff } from 'react-icons/tb'

interface ProductionSelectionProps {
    formik: FormikProps<CreateConfigurationType>
    showDealerFields: boolean
}

const calculateRetailPrice = (
    isOnlyPart: boolean,
    componentPrice: number,
    manualAdjustment: ManualAdjustmentType[],
    compatibleMachineParts: any,
): number => {
    let totalPrice = 0
    manualAdjustment.forEach((manualAdjustmentItem: ManualAdjustmentType) => {
        totalPrice +=
            manualAdjustmentItem.manual_adjustment_quantity *
            manualAdjustmentItem.manual_adjustment_price
    })
    compatibleMachineParts.forEach((compatibleMachine: any) => {
        if (Number(compatibleMachine.discount_applicable) === 1) {
            totalPrice += compatibleMachine.price * compatibleMachine.quantity
        }
    })
    if (!isOnlyPart) {
        totalPrice += componentPrice
    }
    return totalPrice
}

const calculateNetRetailPrice = (
    isOnlyPart: boolean,
    componentPrice: number,
    manualAdjustment: ManualAdjustmentType[],
    compatibleMachineParts: any,
): number => {
    let totalPrice = 0
    manualAdjustment.forEach((manualAdjustmentItem: ManualAdjustmentType) => {
        totalPrice +=
            manualAdjustmentItem.manual_adjustment_quantity *
            manualAdjustmentItem.manual_adjustment_price
    })
    compatibleMachineParts.forEach((compatibleMachine: any) => {
        totalPrice += compatibleMachine.price * compatibleMachine.quantity
    })
    if (!isOnlyPart) {
        totalPrice += componentPrice
    }
    return totalPrice
}
const calculateDataAccessoriesPrice = (compatibleMachineParts: any): number => {
    let totalPrice = 0
    compatibleMachineParts.forEach((compatibleMachine: any) => {
        if (Number(compatibleMachine.discount_applicable) === 0) {
            totalPrice += compatibleMachine.price * compatibleMachine.quantity
        }
    })
    return totalPrice
}

const calculateTotalCostPrice = (
    companyRatesData: any,
    isOnlyPart: boolean,
    componentCostPrice: number,
    manualAdjustment: ManualAdjustmentType[],
    compatibleMachineParts: any,
): number => {
    let totalCostPrice = 0
    let handlingCost = 0
    manualAdjustment.forEach((manualAdjustmentItem: ManualAdjustmentType) => {
        totalCostPrice +=
            manualAdjustmentItem.manual_adjustment_quantity *
            manualAdjustmentItem.manual_adjustment_price
    })
    compatibleMachineParts.forEach((compatibleMachine: any) => {
        totalCostPrice += compatibleMachine.cost_price * compatibleMachine.quantity
    })
    if (!isOnlyPart) {
        totalCostPrice += componentCostPrice
    }
    handlingCost =
        companyRatesData?.data.find((rate: any) => rate.rate_name === 'Handling Cost')
            ?.rate_value || 0
    totalCostPrice += (handlingCost * totalCostPrice) / 100
    return totalCostPrice || 0
}

const calculateLandedCost = (companyDataRates: any): number => {
    return (
        companyDataRates?.data.find((rate: any) => rate.rate_name === 'Landed Cost').rate_value || 0
    )
}

const calculateMaxDiscountAvailable = (
    userMaxDiscount: number,
    maxDiscountCategory: number,
    totalPrice: number,
) => {
    let maxDiscount = 0
    if (maxDiscountCategory) {
        if (userMaxDiscount < maxDiscountCategory) {
            maxDiscount = (userMaxDiscount * totalPrice) / 100
        } else {
            maxDiscount = (maxDiscountCategory * totalPrice) / 100
        }
    }
    return maxDiscount
}

const ReviewConfiguration = (props: ProductionSelectionProps) => {
    const { formik, showDealerFields } = props
    const { Panel } = Collapse

    const { user, userExtra } = useAuth()
    const [panelIndex, setPanelIndex] = useState<number[]>([])

    const isCostPriceVisible = !!userExtra.cost_price_visible
    const isProfitMarginVisible = !!userExtra.profit_margin_visible
    const isOemEditable = !!userExtra.oem_editable
    const isExtraDiscountVisible = !!userExtra.extra_discount_visible
    const isExtraDiscountVisibleAndEditable = !!userExtra.extra_discount_visible_and_editable
    const isExtraExtraDiscountEditable = !!userExtra.extra_extra_discount_editable
    const isSalesMarginVisible = !!userExtra.sales_margin_visible
    const isCustomerDiscountVisible = !!userExtra.customer_discount_visible
    const isSupport = !!userExtra.is_support
    const [totalCostPrice, setTotalCostPrice] = useState<number>(0)
    const [totalCostPriceAllMachine, setTotalCostPriceAllMachine] = useState<number>(0)
    const [maximumAvailableDiscount, setMaximumAvailableDiscount] = useState<number>(0)
    const [netTotal, setNetTotal] = useState<number>(0)
    const [netNetPurchasePrice, setNetNetPurchasePrice] = useState<number>(0)
    const [maxDiscountCategory, setMaxDiscountCategory] = useState<number>(0)
    const [discountPercentageAmount, setDiscountPercentageAmount] = useState<number>(0)
    const [customerDiscountPercentage, setCustomerDiscountPercentage] = useState<number>(0)
    const [extraDiscountPercentageAmount, setExtraDiscountPercentageAmount] = useState<number>(0)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [totalMargin, setTotalMargin] = useState<number>(0)
    const [retailMargin, setRetailMargin] = useState<number>(0)
    const [activePanelIndex, setActivePanelIndex] = useState<number[]>([])

    useEffect(() => {
        setActivePanelIndex([1, ...panelIndex])
    }, [panelIndex])

    const handleChangeActiveIndex = (index: number[]) => {
        setActivePanelIndex(index)
    }
    const handeSetPanelIndex = () => {
        const tempIndex = []
        for (let i = 0; i < formik.values.totalTypes.length; i++) {
            tempIndex.push(i + 2)
        }
        setPanelIndex(tempIndex)
    }

    useEffect(() => {
        handeSetPanelIndex()
    }, [formik.values.totalTypes])

    const handleSetDiscountPercentage = (amount: number) => {
        if (isNaN(amount)) {
            amount = 0
        }
        if (amount === 0) {
            formik.setFieldValue('discountPercentage', 0)
        }
        if (amount <= userExtra.max_discount && amount <= maxDiscountCategory) {
            if (
                (amount * formik.values.retailPrice) / 100 + formik.values.discountAmount <=
                maximumAvailableDiscount
            ) {
                formik.setFieldValue('discountPercentage', amount)
            } else {
                toast(`Discount can't be more than ${maximumAvailableDiscount}`, errorToast)
            }
        } else {
            toast(
                `Discount can't be more than ${
                    userExtra.max_discount < maxDiscountCategory
                        ? userExtra.max_discount
                        : maxDiscountCategory
                }`,
                errorToast,
            )
        }
    }

    useEffect(() => {
        formik.setFieldValue(
            'retailPrice',
            calculateRetailPrice(
                formik.values.onlyParts,
                formik.values.machineConfigurationDetailData[0].price,
                formik.values.manualAdjustments,
                formik.values.selectedParts,
            ),
        )
        formik.setFieldValue(
            'netRetailPrice',
            calculateNetRetailPrice(
                formik.values.onlyParts,
                formik.values.machineConfigurationDetailData[0].price,
                formik.values.manualAdjustments,
                formik.values.selectedParts,
            ),
        )
        formik.setFieldValue(
            'dataAccessoriesPrice',
            calculateDataAccessoriesPrice(formik.values.selectedParts),
        )
    }, [])

    useEffect(() => {
        if (formik.values.retailPrice && formik.values.discountPercentage) {
            setDiscountPercentageAmount(
                (formik.values.retailPrice * formik.values.discountPercentage) / 100,
            )
        }
        if (formik.values.discountPercentage === 0) {
            setDiscountPercentageAmount(0)
        }
    }, [formik.values.retailPrice, formik.values.discountPercentage])

    useEffect(() => {
        const tempNetTotal =
            formik.values.dataAccessoriesPrice +
            formik.values.retailPrice -
            discountPercentageAmount -
            formik.values.discountAmount -
            formik.values.dealerDiscountAmount
        setNetTotal(tempNetTotal)
    }, [
        formik.values.dataAccessoriesPrice,
        formik.values.retailPrice,
        discountPercentageAmount,
        formik.values.discountAmount,
        formik.values.dealerDiscountAmount,
    ])

    useEffect(() => {
        const tempNetNetPurchasePrice = netTotal - formik.values.oemDiscount
        setNetNetPurchasePrice(tempNetNetPurchasePrice)
    }, [netTotal, formik.values.oemDiscount])

    useEffect(() => {
        const tempExtraDiscountPercentageAmount =
            (formik.values.extraDiscountPercentage * netNetPurchasePrice) / 100
        setExtraDiscountPercentageAmount(tempExtraDiscountPercentageAmount)
    }, [formik.values.extraDiscountPercentage, netNetPurchasePrice])

    useEffect(() => {
        const tempNetNetNetPurchasePrice = netNetPurchasePrice - extraDiscountPercentageAmount
        formik.setFieldValue('netNetNetPurchasePrice', tempNetNetNetPurchasePrice)
    }, [netNetPurchasePrice, extraDiscountPercentageAmount])

    useEffect(() => {
        if (formik.values.endUserSalesPrice) {
            const tempSalesMarginAmount =
                formik.values.endUserSalesPrice - formik.values.netNetNetPurchasePrice
            formik.setFieldValue('salesMarginAmount', tempSalesMarginAmount)
        }
    }, [formik.values.netNetNetPurchasePrice, formik.values.endUserSalesPrice])

    useEffect(() => {
        if (formik.values.salesMarginAmount) {
            const tempSalesMarginPercentage =
                (formik.values.salesMarginAmount / formik.values.netNetNetPurchasePrice) * 100
            formik.setFieldValue('salesMarginPercentage', tempSalesMarginPercentage)
        }
    }, [formik.values.netNetNetPurchasePrice, formik.values.salesMarginAmount])

    useEffect(() => {
        const tempCustomerDiscountPercentage =
            (Math.abs(
                formik.values.retailPrice -
                    (formik.values.netNetNetPurchasePrice + formik.values.salesMarginAmount),
            ) /
                formik.values.retailPrice) *
            100
        setCustomerDiscountPercentage(tempCustomerDiscountPercentage)
    }, [
        formik.values.netNetNetPurchasePrice,
        extraDiscountPercentageAmount,
        formik.values.salesMarginAmount,
        formik.values.retailPrice,
    ])

    useEffect(() => {
        setMaximumAvailableDiscount(
            calculateMaxDiscountAvailable(
                userExtra.max_discount,
                maxDiscountCategory,
                formik.values.retailPrice,
            ),
        )
    }, [maxDiscountCategory])

    useEffect(() => {
        const tempTotalCostPriceAllMachine = formik.values.quantity * totalCostPrice
        setTotalCostPriceAllMachine(tempTotalCostPriceAllMachine)
    }, [formik.values.netNetNetPurchasePrice, formik.values.quantity, totalCostPrice])

    const handelDealerDiscountChange = (amount: number) => {
        formik.setFieldValue('dealerDiscountAmount', amount || 0)
    }

    const handleDiscountAmountChange = (amount: number) => {
        if (Number.isNaN(amount)) {
            formik.setFieldValue('discountAmount', 0)
        } else {
            if (
                (formik.values.discountPercentage * formik.values.retailPrice) / 100 + amount <=
                maximumAvailableDiscount
            ) {
                formik.setFieldValue('discountAmount', amount)
            } else {
                toast(
                    'Discount can not amount to more than ' + maximumAvailableDiscount,
                    errorToast,
                )
            }
        }
    }

    const handleOemDiscountAmountChange = (amount: number) => {
        if (Number.isNaN(amount)) {
            formik.setFieldValue('oemDiscount', 0)
        } else {
            formik.setFieldValue('oemDiscount', amount)
        }
    }

    const handleExtraDiscountChanges = (amount: number) => {
        if (Number.isNaN(amount)) {
            formik.setFieldValue('extraDiscountPercentage', 0)
        } else {
            formik.setFieldValue('extraDiscountPercentage', amount || 0)
        }
    }

    const handleEndCustomerPurchasePriceChanges = (amount: number) => {
        if (Number.isNaN(amount)) {
            formik.setFieldValue('endUserSalesPrice', 0)
        } else {
            formik.setFieldValue('endUserSalesPrice', amount)
        }
    }

    const { isLoading: companyRatesLoading, data: companyRatesData } = useQuery(
        ['getCompanyRates'],
        () => ConfigurationQueries.getCompanyRates(),
        {
            refetchOnMount: false,
            refetchIntervalInBackground: false,
            refetchInterval: false,
            retryOnMount: false,
            refetchOnWindowFocus: false,
        },
    )

    useEffect(() => {
        setTotalCostPrice(
            calculateTotalCostPrice(
                companyRatesData,
                formik.values.onlyParts,
                formik.values.machineConfigurationDetailData[0].cost_price,
                formik.values.manualAdjustments,
                formik.values.selectedParts,
            ),
        )
        formik.setFieldValue('landedCost', calculateLandedCost(companyRatesData))
    }, [
        companyRatesData,
        formik.values.onlyParts,
        formik.values.machineConfigurationDetailData[0].cost_price,
        formik.values.manualAdjustments,
        formik.values.selectedParts,
    ])

    useEffect(() => {
        const tempTotalPrice = formik.values.quantity * formik.values.netNetNetPurchasePrice
        setTotalPrice(tempTotalPrice)
    }, [
        formik.values.retailPrice,
        formik.values.discountAmount,
        discountPercentageAmount,
        formik.values.quantity,
        formik.values.netNetNetPurchasePrice,
    ])

    useEffect(() => {
        const tempTotalMargin = ((totalPrice - totalCostPriceAllMachine) / totalPrice) * 100
        setTotalMargin(tempTotalMargin || 0)
    }, [
        formik.values.retailPrice,
        formik.values.discountAmount,
        discountPercentageAmount,
        formik.values.quantity,
        totalCostPrice,
        totalCostPriceAllMachine,
        totalPrice,
        totalCostPriceAllMachine,
    ])

    useEffect(() => {
        let tempRetailMargin =
            ((formik.values.netNetNetPurchasePrice -
                totalCostPrice -
                (totalCostPrice * formik.values.landedCost) / 100) /
                formik.values.netNetNetPurchasePrice) *
            100
        setRetailMargin(tempRetailMargin || 0)
    }, [
        formik.values.retailPrice,
        formik.values.discountAmount,
        discountPercentageAmount,
        formik.values.quantity,
        totalCostPrice,
        formik.values.netNetNetPurchasePrice,
        formik.values.landedCost,
    ])

    const { isLoading: maxDiscountByRoleLoading, data: maxDiscountByRoleData } = useQuery(
        ['getMaxDiscountByRole', formik.values.productId, userExtra.role_id],
        () =>
            ConfigurationQueries.getMaxDiscountByRoleId({
                roleId: userExtra.role_id,
                machineCategoryNumber: formik.values.productId || 0,
            }),
        {
            enabled: !!formik.values.productId && !!userExtra.role_id,
            onSuccess: data => {
                setMaxDiscountCategory(data.data[0].max_discount_category)
                if (userExtra.is_dealer) {
                    formik.setFieldValue('discountPercentage', data.data[0].max_discount_category)
                }
            },
        },
    )

    const columns: any = ({ hideDiscount }: { hideDiscount: boolean }) => [
        {
            id: 'machine_part_model_number',
            name: 'Machine Part Model Number',
            center: true,
            selector: (row: any) => {
                return (
                    <div className="flex align-center items-center gap-2">
                        <h1 className="text-semibold mb-2 text-primary text-sm text-center">
                            {row.machine_part_model_number
                                ? row.machine_part_model_number
                                : row.machine_category_model_number}
                        </h1>
                    </div>
                )
            },
        },
        {
            id: 'machine_part_name',
            name: 'Part Name',
            left: true,
            width: '200px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return (
                    <p className="text-md text-left">
                        {row.machine_part_name ? row.machine_part_name : row.machine_category}
                    </p>
                )
            },
        },
        {
            id: 'discount_applicable',
            name: 'Discount Applicable',
            left: true,
            width: '200px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                if (hideDiscount) {
                    return null
                }
                if (row.discount_applicable) {
                    return null
                }
                return (
                    <Tooltip
                        title={
                            row.discount_applicable
                                ? 'Discount is applied in this part'
                                : 'Discount is not applied in this part (Fixed Price)'
                        }
                    >
                        <div className="flex items-center gap-2 cursor-pointer">
                            <p>
                                {row.discount_applicable ? (
                                    <TbDiscount size={22} className="text-green-300" />
                                ) : (
                                    <TbDiscountOff size={22} className="text-red-300" />
                                )}
                            </p>
                            <p className="text-md capitalize">
                                {row.discount_applicable ? 'yes' : 'no'}
                            </p>
                        </div>
                    </Tooltip>
                )
            },
        },
        {
            id: 'machine_part_remarks',
            name: 'Machine Part Remarks',
            selector: (row: any) => {
                return <p className="text-sm">{row.machine_part_remarks}</p>
            },
        },
        {
            id: 'machine_part_note',
            name: 'Machine Part Note',
            selector: (row: any) => {
                return <p className="text-sm">{row.machine_part_note}</p>
            },
        },
        {
            id: 'cost_price',
            name: 'Cost Price',
            omit: Boolean(userExtra?.cost_price_visible === 0),
            selector: (row: any) => {
                return <p className="text-sm">{parseFloat(row.cost_price).toFixed(2)}</p>
            },
        },
        {
            id: 'price',
            name: 'Price',
            selector: (row: any) => {
                return (
                    <p className="text-sm">
                        {getCurrencySymbol(formik.values.currency)}{' '}
                        {parseFloat(row.price).toFixed(2)} {row.is_included ? '(included)' : null}
                    </p>
                )
            },
        },
        {
            id: 'quantity',
            name: 'Quantity',
            center: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: any) => {
                return (
                    <div className="text-sm">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {row.quantity !== undefined
                            ? row.quantity
                            : formik.values.onlyParts
                            ? 0
                            : 1}
                    </div>
                )
            },
        },
        {
            id: 'total_price',
            name: 'Total Price',
            cell: (row: any) => {
                return (
                    <p className="text-sm">
                        {getCurrencySymbol(formik.values.currency)}{' '}
                        {row.total_price
                            ? parseFloat(row.total_price).toFixed(2)
                            : formik.values.onlyParts
                            ? (0).toFixed(2)
                            : (row.price * (row.quantity ? row.quantity : 1)).toFixed(2)}{' '}
                        {row.is_included ? '(included)' : null}
                    </p>
                )
            },
        },
    ]

    return (
        <div className="flex flex-col justify-between">
            <ConfigurationCard
                hasDataAccessories={some(
                    formik.values.compatibleMachinePartsData,
                    item => item.discount_applicable === 0 && item?.quantity > 0,
                )}
                userRole={userExtra.role}
                showDealerView={showDealerFields}
                dealerDiscountAmount={formik.values.dealerDiscountAmount}
                handelDealerDiscountChange={handelDealerDiscountChange}
                currencySymbol={formik.values.currency}
                discountPercentage={formik.values.discountPercentage}
                discountPercentageAmount={discountPercentageAmount}
                netTotal={netTotal || 0}
                netNetPurchasePrice={netNetPurchasePrice}
                netNetNetPurchasePrice={formik.values.netNetNetPurchasePrice}
                configurationName={formik.values.productConfigurationName}
                productCategory={formik.values.productCategory}
                retailPrice={formik.values.netRetailPrice}
                netRetailPrice={formik.values.retailPrice}
                dataAccessoriesPrice={formik.values.dataAccessoriesPrice}
                discountAmount={formik.values.discountAmount}
                handleDiscountAmountChange={handleDiscountAmountChange}
                oemDiscountAmount={formik.values.oemDiscount}
                maxDiscountApplicable={
                    maxDiscountCategory === 0 ? userExtra.max_discount : maxDiscountCategory
                }
                setDiscountPercentage={handleSetDiscountPercentage}
                handleOemDiscountAmountChange={handleOemDiscountAmountChange}
                extraDiscount={formik.values.extraDiscountPercentage}
                extraExtraDiscountPercentageAmount={extraDiscountPercentageAmount}
                handleExtraDiscountChanges={handleExtraDiscountChanges}
                customerDiscountPercentage={customerDiscountPercentage}
                endCustomerPurchasePrice={formik.values.endUserSalesPrice}
                handleEndCustomerPurchasePriceChanges={handleEndCustomerPurchasePriceChanges}
                salesMarginAmount={formik.values.salesMarginAmount}
                salesMarginPercentage={formik.values.salesMarginPercentage}
                configurationQuantity={formik.values.quantity}
                setConfigurationQuantity={formik.setFieldValue}
                totalCostPriceAllMachine={totalCostPriceAllMachine}
                totalPrice={totalPrice}
                totalMargin={totalMargin}
                retailMargin={retailMargin}
                showRetailMargin={userExtra?.retail_margin}
                // isExtraDiscountVisible={isExtraDiscountVisible}
                // isExtraExtraDiscountVisible={isExtraExtraDiscountEditable}
                // isExtraDiscountVisibleAndEditable={isExtraExtraDiscountEditable}
                isExtraExtraDiscountEditable={isExtraExtraDiscountEditable}
                isExtraDiscountVisibleAndEditable={isExtraDiscountVisibleAndEditable}
                isCostPriceVisible={isCostPriceVisible}
                isOemEditable={isOemEditable}
                isProfitMarginVisible={isProfitMarginVisible}
                isSalesMarginVisible={isSalesMarginVisible}
                isSupport={isSupport}
                isCustomerDiscountVisible={isCustomerDiscountVisible}
                isExtraDiscountVisible={isExtraDiscountVisible}
                isLibrary={formik.values.isLibrary}
                setIsLibrary={formik.setFieldValue}
                user={formik.values.user}
                showIsLibrary={!window.location.href.includes('edit')}
                landedCost={formik.values.landedCost}
                tradeInValue={formik?.values?.trade_in_value || 0}
                setTradeInValue={(value: number) => formik.setFieldValue('trade_in_value', value)}
            />
            <div className="w-full">
                <Collapse
                    activeKey={activePanelIndex}
                    onChange={(keys: any) => handleChangeActiveIndex(keys)}
                >
                    <Panel header={PanelHeader({ title: 'Component' })} key={1}>
                        <Table
                            columns={columns({
                                hideDiscount: true,
                            })}
                            data={formik.values.machineConfigurationDetailData}
                            pagination={false}
                            striped={false}
                        />
                    </Panel>
                    {formik.values.totalTypes.length &&
                        formik.values.totalTypes.map(
                            (sequence: string, index: number) =>
                                formik.values.selectedParts &&
                                formik.values.selectedParts.filter(
                                    (machine: any) =>
                                        machine.machine_part_type.toUpperCase() ===
                                        sequence.toUpperCase(),
                                ).length && (
                                    <Panel
                                        header={PanelHeader({
                                            title: sequence,
                                            stat: `(${
                                                formik.values.selectedParts?.filter(
                                                    (machine: any) =>
                                                        machine.machine_part_type.toUpperCase() ===
                                                        sequence.toUpperCase(),
                                                ).length
                                            } total parts)`,
                                        })}
                                        key={index + 2}
                                    >
                                        <Table
                                            columns={columns({
                                                hideDiscount: false,
                                            })}
                                            data={formik.values.selectedParts?.filter(
                                                (machine: any) =>
                                                    machine.machine_part_type.toUpperCase() ===
                                                    sequence.toUpperCase(),
                                            )}
                                            tableHeight="fit"
                                            noHeader
                                            pagination={false}
                                            striped={false}
                                        />
                                    </Panel>
                                ),
                        )}
                </Collapse>
            </div>
            {formik.values.manualAdjustments.length > 0 && (
                <div className="w-full">
                    <ManualAdjustment editable={false} formik={formik} />
                </div>
            )}
        </div>
    )
}

export default ReviewConfiguration
