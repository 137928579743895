import { ChangeEvent, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { useQuery } from 'react-query'
import { Link, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import Fuse from 'fuse.js'

import Button from 'components/button'
import Input from 'components/input'
import Table from 'components/table'
import Tabs, { TabType } from 'components/tabs'

import { truncateHour } from 'utils/formatDate'
import { getPillsColor } from 'utils/pills'

import { Select } from 'antd'
import { getAllOrder, getAllTeamOrder } from './queries/order'
import { useAuth } from '../auth/context/useAuth'

const cardClass = 'w-full bg-white px-4 py-4 rounded-md flex flex-col'

const tabs: TabType[] = [
    {
        name: 'My Order',
        value: 'my-order',
    },
    {
        name: 'Team Order',
        value: 'team-order',
    },
]

const options = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Submitted',
        value: 'submitted order',
    },
    {
        label: 'Pending',
        value: 'pending order',
    },
    {
        label: 'Cancelled',
        value: 'cancelled',
    },
]
const teamRole: string[] = ['Management', 'Sales management']

const Order = () => {
    const [searchParam, setSearchParams] = useSearchParams()
    const { user, userExtra } = useAuth()

    const [activeTab, setActiveTab] = useState(
        teamRole.includes(userExtra.role) ? tabs[1] : tabs[0],
    )

    const columns = [
        {
            name: 'S.No(#)',
            left: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: any, index: number) => (
                <h1 className="text-black text-semibold text-base">{index + 1 || 'unknown'}</h1>
            ),
            width: '130px',
        },
        {
            name: 'Order Name',
            left: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: { order_name: string; id: string | number; order_id: number }) => {
                const url =
                    activeTab?.value === tabs[1]?.value
                        ? `/order/${row.order_id}`
                        : `/order/${row.id}`
                return (
                    <Link to={url} className="text-semibold text-base text-primaryLight">
                        {row?.order_name || 'unknown'}
                    </Link>
                )
            },
            selector: (row: any) => row.order_name,
            height: 'fit-content',
            wrap: true,
            width: '250px',
            sortable: true,
        },
        {
            name: 'Customer Name',
            left: true,
            height: 'fit-content',
            wrap: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: { quotation_for: string }) => {
                return (
                    <h1 className="text-black text-semibold text-base">
                        {row?.quotation_for || 'unknown'}
                    </h1>
                )
            },
            selector: (row: any) => row.quotation_for,
            sortable: true,
        },
        {
            name: 'Created By',
            left: true,
            height: 'fit-content',
            wrap: true,
            omit: activeTab?.value === tabs[0]?.value && userExtra?.is_support !== 1,
            // eslint-disable-next-line react/no-unstable-nested-components
            selector: (row: any) => row.quote_created_by,
            cell: (row: {
                user_name: string
                quote_created_by: string
                created_by: string
                order_by_user_id: number
                user_id: number
            }) => {
                return (
                    <h1 className="text-black text-semibold text-base">
                        {row?.quote_created_by || '-'}
                    </h1>
                )
            },
            sortable: true,
        },
        {
            name: 'Order Status',
            center: true,
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => row.status,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: { status: string }) => {
                const color = getPillsColor(row.status)
                const baseType = String(row.status).split(' ')[0]
                return (
                    <div
                        className={classNames(
                            'capitalize font-bold',
                            color.bg,
                            color.text,
                            'rounded-full py-2  w-full px-2 text-center',
                        )}
                    >
                        {baseType}
                    </div>
                )
            },
            sortable: true,
        },
        {
            name: 'Order Date',
            center: true,
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => row.order_date,

            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: { order_date: string }) => {
                return (
                    <h1 className="text-black text-semibold text-base">
                        {truncateHour(String(row.order_date))}
                    </h1>
                )
            },
            sortable: true,
        },
        {
            name: 'Action',
            center: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: { id: string; order_id: number }) => {
                const url =
                    activeTab?.value === tabs[1]?.value
                        ? `/order/${row.order_id}`
                        : `/order/${row.id}`
                return (
                    <Link to={url}>
                        <Button label="View Details" type="button" variant="ghost" />
                    </Link>
                )
            },
        },
    ]

    const onChangeStatus = (value: string) => {
        const params = new URLSearchParams(searchParam)
        if (value === '' || value === undefined || value === 'all') {
            params.delete('st')
        } else {
            params.set('st', value)
        }
        setSearchParams(params)
    }

    const { data, isLoading, refetch } = useQuery(
        ['orderList', activeTab],
        () =>
            activeTab.value === 'team-order'
                ? getAllTeamOrder(user.id)
                : getAllOrder(
                      user.id,
                      userExtra.is_support,
                      userExtra.is_dealer,
                      userExtra.is_manager,
                      userExtra.role_id,
                  ),
        {
            enabled: !!user.id,
        },
    )

    const getFilterData = (finalList: any) => {
        const query = searchParam?.get('query') || ''
        const status = searchParam?.get('st') || 'all'

        let tempDataList: any[] = finalList || []

        if (query === '' && status === 'all') return finalList || []

        if (query !== '') {
            const fuse = new Fuse(tempDataList || finalList, {
                shouldSort: true,
                threshold: 0.1,
                keys: ['order_name', 'user_name', 'quotation_for', 'quote_created_by'],
            })
            const result = fuse.search(query)
            tempDataList = result.map(item => item.item)
        } else {
            tempDataList = []
        }

        if (status !== 'all') {
            const filteredList = finalList.filter(
                (item: { status: string }) => item.status === status,
            )
            if (filteredList.length > 0) {
                tempDataList = filteredList
            } else {
                tempDataList = []
            }
        }
        return tempDataList
    }

    return (
        <div className={cardClass}>
            <Tabs
                initialTab={activeTab}
                tabs={tabs || []}
                getActiveTab={(tab: TabType) => {
                    setActiveTab(tab)
                    refetch()
                }}
                leftContent={
                    <div className="hidden md:flex flex-wrap items-center gap-8 z-50 ">
                        <div className="w-full md:w-auto">
                            <Select
                                size="large"
                                className="w-full md:w-64 rounded-md uppercase"
                                dropdownStyle={{ textTransform: 'uppercase' }}
                                placeholder="Status"
                                value={searchParam.get('st') || 'all'}
                                onChange={onChangeStatus}
                            >
                                {options.map((item: any) => (
                                    <Select.Option value={item.value}>{item.label}</Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className="w-full md:w-auto">
                            <Input
                                type="text"
                                name="search"
                                leftContent={<BiSearch />}
                                value={searchParam.get('query') || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    const params = new URLSearchParams(searchParam)
                                    if (e.target.value === '' || e.target.value === undefined) {
                                        params.delete('query')
                                    } else {
                                        params.set('query', e.target.value)
                                    }
                                    setSearchParams(params)
                                }}
                            />
                        </div>
                    </div>
                }
            />
            <Table
                columns={columns}
                data={getFilterData(data?.data || []) || []}
                loading={isLoading}
                striped
                pagination
                totalRows={25}
            />
        </div>
    )
}

export default Order
