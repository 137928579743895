import { callAxios } from 'plugins/api'

const configurationLog = 'configuration'

export const getLogsConfiguration = (configurationId: string) => {
    return callAxios({
        url: `${configurationLog}/getLogsForConfiguration/${configurationId}`,
        method: 'GET',
    })
}

export const getLogsChanges = (configurationId: string) => {
    return callAxios({
        url: `${configurationLog}/getChangedLogForConfiguration/${configurationId}`,
        method: 'GET',
    })
}
