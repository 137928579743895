const HOME_ROUTE = '/'
const PROFILE_ROUTE = '/profile'
const DISCOUNT_REQUEST_ROUTE = '/notifications'
const DISCOUNT_HISTORY_ROUTE = '/notification-logs'
const CUSTOMER_FEEDBACK_ROUTE = '/customer-feedback'
const BULLETIN_ROUTE = '/bulletin'
const PRIVACY_ROUTE = '/privacy'
const POLICY_ROUTE = '/policy'
const DISCLAIMER_ROUTE = '/disclaimer'

// auth
const LOGIN_ROUTE = '/auth/login'
const FORGET_PASSWORD_ROUTE = '/auth/forget-password'
// const RESET_PASSWORD_ROUTE = '/auth/password-reset/:resetToken'

// dashboard
export {
    HOME_ROUTE,
    PROFILE_ROUTE,
    DISCOUNT_REQUEST_ROUTE,
    DISCOUNT_HISTORY_ROUTE,
    CUSTOMER_FEEDBACK_ROUTE,
    BULLETIN_ROUTE,
    LOGIN_ROUTE,
    FORGET_PASSWORD_ROUTE,
    DISCLAIMER_ROUTE,
    POLICY_ROUTE,
    PRIVACY_ROUTE,
}
