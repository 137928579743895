import Tabs, { TabType } from 'components/tabs'
import DetailConfiguartion from 'modules/configuration/views/detailConfiguration'
import { useState } from 'react'
import Logs from './Logs'
import Summary from './Summary'

const tabs: TabType[] = [
    {
        name: 'Change Summary',
        value: 'summary',
    },
    {
        name: 'Current Machine',
        value: 'machine',
    },
    {
        name: 'Logs',
        value: 'logs',
    },
]

const HistoryTab = () => {
    const getScreen = (screen: string) => {
        switch (screen) {
            case 'summary':
                return <Summary />
            case 'machine':
                return <DetailConfiguartion />
            case 'logs':
                return <Logs />
            default:
                return <p>404 Not Found</p>
        }
    }

    const [activeTab, setActiveTab] = useState<TabType>(tabs[0])
    return (
        <div>
            <Tabs tabs={tabs} getActiveTab={setActiveTab} />
            <hr className="my-4 border-1 border-gray-400" />
            <div>{getScreen(activeTab.value)}</div>
        </div>
    )
}

export default HistoryTab
