import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form, Input } from 'antd'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { successToast } from 'utils/toasterUtil'
import { useAuth } from 'modules/auth/context/useAuth'
import { ConfigurationQueries } from '../queries/configuration'
import Button from '../../../components/button'

/* eslint-disable react/no-unescaped-entities */
interface DiscountRequestModalProps {
    modal: boolean
    configurationId: string
    closeModal: () => void
}

const size = 'large'
const roundedClass = 'rounded-lg flex-1'
const { TextArea } = Input

const DiscountRequestModal = (props: DiscountRequestModalProps) => {
    const { modal, closeModal, configurationId } = props
    const { user } = useAuth()

    const higherDiscountRequest = useMutation(
        ['higeherDiscountrequest'],
        (higherDiscountRequestData: any) =>
            ConfigurationQueries.requestHigherDiscount(higherDiscountRequestData),
        {
            onSuccess: data => {
                toast(data?.data?.message || 'Discount Requested Successfully', successToast)
                closeModal()
            },
            onError: (error: any) => {
                toast(error?.message || 'Discount Requested Failed', successToast)
            },
        },
    )

    const formik = useFormik({
        initialValues: {
            user_id: user?.id,
            requested_configuration_id: configurationId,
            requested_discount_percentage: '',
            requested_comment: '',
        },
        validationSchema: Yup.object().shape({
            requested_configuration_id: Yup.string().required('Category is required'),
            requested_comment: Yup.string().required('Comment is required'),
            requested_discount_percentage: Yup.number()
                .required('Discount is required')
                .notOneOf([0], 'Discount is required'),
        }),
        onSubmit: (values, { resetForm }) => {
            higherDiscountRequest.mutate(values)
            resetForm()
        },
    })

    const handleCloseModal = () => {
        formik.resetForm()
        closeModal()
    }
    return (
        <Modal
            open={modal}
            onClose={closeModal}
            center
            showCloseIcon={false}
            classNames={{
                root: 'rounded-lg',
                modal: 'rounded-lg w-1/3',
            }}
        >
            <div className="flex justify-center items-center py-4 px-8">
                <div className="flex flex-col gap-4 flex-1">
                    <div>
                        <Form.Item
                            name="quotation_name"
                            className="flex flex-col"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <div>
                                <h5 className="capitalize">Discount</h5>
                            </div>
                            <Input
                                name="requested_discount_percentage"
                                type="number"
                                value={formik.values.requested_discount_percentage}
                                onChange={formik.handleChange}
                                placeholder="Enter discount"
                                size={size}
                                className={`${roundedClass}, mt-2`}
                            />
                            {formik.errors &&
                                formik.errors.requested_discount_percentage &&
                                formik.touched.requested_discount_percentage && (
                                    <p className="text-red-600 mt-1">
                                        {formik.errors.requested_discount_percentage}
                                    </p>
                                )}
                        </Form.Item>
                        <Form.Item
                            name="requested_comment"
                            className="flex flex-col"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <div>
                                <h5 className="capitalize">Commnet</h5>
                            </div>
                            <TextArea
                                name="requested_comment"
                                rows={4}
                                value={formik.values.requested_comment}
                                onChange={formik.handleChange}
                                placeholder="Comments"
                                className={`${roundedClass}, mt-2`}
                            />
                            {formik.errors &&
                                formik.errors.requested_comment &&
                                formik.touched.requested_comment && (
                                    <p className="text-red-600 mt-1">
                                        {formik.errors.requested_comment}
                                    </p>
                                )}
                        </Form.Item>
                    </div>
                    <div className="flex justify-center gap-4">
                        <Button
                            label="Request discount"
                            onClick={() => formik.handleSubmit()}
                            disabled={formik.isSubmitting || higherDiscountRequest.isLoading}
                            variant="primary"
                        />
                        <Button label="Cancel" onClick={() => handleCloseModal()} variant="ghost" />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DiscountRequestModal
