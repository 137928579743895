/* eslint-disable react/button-has-type */
import React from 'react'
import ButtonLoader from './buttonLoader'

interface ButtonProps {
    id?: string
    label?: string
    content?: React.ReactElement
    variant?: 'primary' | 'outline' | 'ghost'
    type?: 'button' | 'submit' | 'reset'
    dataDropdownToggle?: string
    isLoading?: boolean
    leftIcon?: React.ReactElement
    rightIcon?: React.ReactElement
    disabled?: boolean
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Button = (props: ButtonProps) => {
    const {
        id,
        type = 'button',
        label,
        onClick,
        content,
        leftIcon,
        rightIcon,
        variant,
        isLoading,
        dataDropdownToggle,
        disabled,
        ...rest
    } = props

    const primaryButton = `bg-primary hover:border-black border-2 border-primary text-white `
    const outlineButton = `hover:bg-primary hover:border-primary hover:text-white border-2 border-primaryLight`
    const ghostButton = `text-primaryLight bg-none hover:text-primary`

    const btnClass = `block font-bold py-3 px-3 w-full rounded-lg flex items-center justify-center gap-3 whitespace-nowrap`

    const getButtonClass = () => {
        switch (variant) {
            case 'primary':
                return primaryButton
            case 'outline':
                return outlineButton
            case 'ghost':
                return ghostButton
            default:
                return primaryButton
        }
    }

    if (isLoading) {
        return (
            <button
                id={id}
                data-dropdown-toggle={dataDropdownToggle}
                type={type || 'button'}
                disabled={disabled}
                className={`${btnClass} ${getButtonClass()}`}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                    onClick ? onClick(e) : null
                }
                {...rest}
            >
                <ButtonLoader />
            </button>
        )
    }
    return (
        <button
            id={id}
            data-dropdown-toggle={dataDropdownToggle}
            type={type || 'button'}
            className={`${btnClass} ${getButtonClass()}`}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                onClick ? onClick(e) : null
            }
            disabled={disabled}
            {...rest}
        >
            {leftIcon && leftIcon}
            {!content && label}
            {content && content}
            {rightIcon && rightIcon}
        </button>
    )
}

export default Button
