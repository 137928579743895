import { callAxios } from 'plugins/api'
import { toast } from 'react-toastify'

const wordDocApi = 'quote/downloadDocx'
const excelDocApi = 'quote/download-doc'
const zipApi = 'quote/download-zip'
const zip2 = 'quote/download-zip2'

const getUrl = (docx: string) => {
    return `documents/${docx}`
}

export const downloadWordDoc = async (quoteId: string) => {
    await callAxios({
        url: `${wordDocApi}/${quoteId}`,
        method: 'POST',
        responseType: 'blob',
    })
        .then(res => {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data)

            // create "a" HTML element with href to file & click
            const link = document.createElement('a')
            link.href = href

            const contentDisposition = res.headers['content-disposition']
            const filename = contentDisposition.match(/filename=(.+)/)[1]
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
        })
        .catch(err => {
            toast.error(err.message)
        })

    return ''
}

export const downloadZip = async (quoteId: number) => {
    await callAxios({
        url: `${zipApi}/${quoteId}`,
        method: 'get',
        responseType: 'blob',
    })
        .then(res => {
            const href = URL.createObjectURL(res.data)
            const link = document.createElement('a')
            link.href = href

            const contentDisposition = res.headers['content-disposition']
            const filename = contentDisposition.match(/filename=(.+)/)[1]
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
        })
        .catch(err => {
            toast.error(err.message)
        })
}

export const downloadZip2 = async (quoteId: number) => {
    await callAxios({
        url: `${zip2}/${quoteId}`,
        method: 'get',
        responseType: 'blob',
    })
        .then(res => {
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data)

            // create "a" HTML element with href to file & click
            const link = document.createElement('a')
            link.href = href

            const contentDisposition = res.headers['content-disposition']
            const filename = contentDisposition.match(/filename=(.+)/)[1]
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
        })
        .catch(err => {
            toast.error(err.message)
        })
}

export const downloadExcelDoc = async (xlsx: string) => {
    const res = await callAxios({
        url: excelDocApi,
        method: 'POST',
        data: {
            url: getUrl(xlsx),
        },
    })

    const href = res.data
    const link = document.createElement('a')
    link.href = href
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    return ''
}
