import { Outlet } from 'react-router-dom'

const HomeDashboardLayout = () => {
    return (
        <div className="w-full bg-white px-4 py-4 rounded-md flex flex-col">
            <Outlet />
        </div>
    )
}

export default HomeDashboardLayout
