import AppRoutes from 'route'

const App = () => {
    // const { fetchTranslations } = useTranslationData()

    // useEffect(() => {
    //     fetchTranslations()
    // }, [])

    return <AppRoutes />
}

export default App
