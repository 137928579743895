/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unused-prop-types */
import { DatePicker, Form, Input, Select } from 'antd'
import { map } from 'lodash'
import { callAxios } from 'plugins/api'
import { useState } from 'react'
import { useQuery } from 'react-query'

/* eslint-disable @typescript-eslint/no-unused-vars */
type OrderQuoteFormProps = {
    formik: any
}

const roundedClass = 'rounded-lg flex-1'
const size = 'large'

const OrderQuoteForm = (props: OrderQuoteFormProps) => {
    const { formik } = props
    const [financeCompany, setFinanceCompany] = useState<any>([])

    const financeCompanyFetching = useQuery(
        'financeCompany',
        () =>
            callAxios({
                method: 'get',
                url: 'getAllFinanceCompanies',
            }),
        {
            onSuccess: data => {
                const financeComapnyList = data.data.map(
                    (item: { finance_name: string; finance_id: string | number }) => {
                        return {
                            label: item.finance_name,
                            value: item.finance_id,
                        }
                    },
                )
                setFinanceCompany(financeComapnyList)
            },
        },
    )

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 mt-4">
            <Form.Item
                name="name"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.name
                            ? String(formik.errors.name)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">Quote Made By</h5>
                </div>
                <Input
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    placeholder="Name of dealer"
                    size={size}
                    disabled
                    className={roundedClass}
                />
                {formik.errors && formik.errors.name && formik.touched.name && (
                    <p className="text-red-600 mt-1">{formik.errors.name}</p>
                )}
            </Form.Item>

            <Form.Item
                name="email"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.email
                            ? String(formik.errors.email)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">Contact Email</h5>
                </div>
                <Input
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    placeholder="Name of dealer"
                    type="email"
                    disabled
                    size={size}
                    className={roundedClass}
                />
                {formik.errors && formik.errors.email && formik.touched.email && (
                    <p className="text-red-600 mt-1">{formik.errors.email}</p>
                )}
            </Form.Item>

            <Form.Item
                name="orderBy"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors?.orderBy
                            ? String(formik.errors.orderBy)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">Order By</h5>
                </div>
                <Input
                    name="user.name"
                    value={formik.values.orderBy}
                    onChange={formik.handleChange}
                    placeholder="Name of dealer"
                    size={size}
                    disabled
                    className={roundedClass}
                />
                {formik.errors && formik.errors?.user?.name && formik.touched.orderBy && (
                    <p className="text-red-600 mt-1">{formik.errors.orderBy}</p>
                )}
            </Form.Item>
            <Form.Item
                name="orderByEmail"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors?.orderByEmail
                            ? String(formik.errors.orderByEmail)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">Order by email</h5>
                </div>
                <Input
                    name="orderByEmail"
                    type="email"
                    value={formik.values.orderByEmail}
                    onChange={formik.handleChange}
                    placeholder="Name of dealer"
                    size={size}
                    disabled
                    className={roundedClass}
                />
                {formik.errors && formik.errors?.user?.email && formik.touched.orderByEmail && (
                    <p className="text-red-600 mt-1">{formik.errors.orderByEmail}</p>
                )}
            </Form.Item>

            <div>
                <Form.Item name="exisiting_finance_company" className="flex flex-col">
                    <div className="mb-1">
                        <h5 className="capitalize">Choose Payment Options</h5>
                    </div>
                    <Select
                        allowClear
                        onChange={(value: string) => {
                            if (value === 'finance') {
                                formik.setFieldValue('finance_company_exists', false)
                                formik.setFieldValue('type', true)
                                formik.setFieldValue('exisiting_finance_company', '')
                                formik.setFieldValue('finance_account_number', '')
                            }
                            if (value === 'customer_account') {
                                formik.setFieldValue('type', false)
                                formik.setFieldValue('finance_company_exists', true)
                                formik.setFieldValue('exisiting_finance_company', '')
                                formik.setFieldValue('finance_account_number', '')
                            }
                        }}
                        value={
                            formik.values.finance_company_exists ? 'customer_account' : 'finance'
                        }
                        className="rounded-lg"
                        size={size}
                        placeholder="Choose your option"
                    >
                        {map(
                            [
                                {
                                    label: 'Finance (Dealer)',
                                    value: 'finance',
                                },
                                {
                                    label: 'Customer Account',
                                    value: 'customer_account',
                                },
                            ],
                            option => (
                                <Select.Option value={option.value}>{option.label}</Select.Option>
                            ),
                        )}
                    </Select>
                </Form.Item>
            </div>

            <div className="flex flex-col">
                <Form.Item
                    name="exisiting_finance_company"
                    className="flex flex-col"
                    rules={[
                        {
                            required: true,
                            message: formik.errors.exisiting_finance_company
                                ? String(formik.errors.exisiting_finance_company)
                                : 'Required Order Data',
                        },
                    ]}
                >
                    <div className="mb-1">
                        <h5 className="capitalize">
                            Choose Finance Company For {formik.values.type ? 'Dealer' : 'Customer'}
                        </h5>
                    </div>
                    <Select
                        defaultValue={
                            formik.values.exisiting_finance_company || 'Choose your option'
                        }
                        onChange={(value: string) =>
                            formik.setFieldValue('exisiting_finance_company', value)
                        }
                        loading={financeCompanyFetching.isLoading}
                        className="rounded-lg"
                        size={size}
                    >
                        <Select.Option value="">Choose your finance</Select.Option>

                        {map(
                            [
                                ...financeCompany,
                                {
                                    label: 'Not Specified',
                                    value: 'null',
                                },
                            ] || [],
                            option => (
                                <Select.Option value={option.value}>{option.label}</Select.Option>
                            ),
                        )}
                    </Select>
                </Form.Item>
            </div>
            {/* finance_name | Finance Account Name */}
            {/* <Form.Item
                name="finance_name"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.finance_name
                            ? String(formik.errors.finance_name)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">Finance Account Name</h5>
                </div>
                <Input
                    name="finance_name"
                    value={formik.values.finance_name}
                    onChange={formik.handleChange}
                    placeholder="account name"
                    size={size}
                    className={roundedClass}
                />
                {formik.errors && formik.errors.finance_name && formik.touched.finance_name && (
                    <p className="text-red-600 mt-1">{formik.errors.finance_name}</p>
                )}
            </Form.Item> */}

            <Form.Item
                name="finance_account_number"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.finance_account_number
                            ? String(formik.errors.finance_account_number)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">Finance Details (eg: account / terms)</h5>
                </div>
                <Input
                    name="finance_account_number"
                    value={formik.values.finance_account_number}
                    onChange={formik.handleChange}
                    placeholder="Account / Terms"
                    size={size}
                    className={roundedClass}
                />
                {formik.errors &&
                    formik.errors.finance_account_number &&
                    formik.touched.finance_account_number && (
                        <p className="text-red-600 mt-1">{formik.errors.finance_account_number}</p>
                    )}
            </Form.Item>
            <Form.Item
                name={formik.values.type ? 'dealer_request_date' : 'expected_delivery_date'}
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message:
                            formik.errors && formik.values.type
                                ? String(formik.errors.dealer_request_date)
                                : formik.errors && !formik.values.type
                                ? String(formik.errors.expected_delivery_date)
                                : 'Date is required',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">
                        {formik.values.type ? 'Dealer Request Date' : 'Customer Request Date'}
                    </h5>
                </div>
                <DatePicker
                    className={roundedClass}
                    size={size}
                    style={{
                        width: '100%',
                    }}
                    onChange={date => {
                        formik.setFieldValue(
                            formik.values.type ? 'dealer_request_date' : 'expected_delivery_date',
                            date,
                            true,
                        )
                    }}
                    value={
                        formik.values.type
                            ? formik?.values?.dealer_request_date
                            : formik?.values?.expected_delivery_date
                    }
                />
                {formik.errors &&
                    formik.values.type &&
                    formik.errors.dealer_request_date &&
                    formik.touched.dealer_request_date && (
                        <p className="text-red-600 mt-1">{formik.errors.dealer_request_date}</p>
                    )}
                {formik.errors &&
                    !formik.values.type &&
                    formik.errors.expected_delivery_date &&
                    formik.touched.expected_delivery_date && (
                        <p className="text-red-600 mt-1">{formik.errors.expected_delivery_date}</p>
                    )}
            </Form.Item>
        </div>
    )
}

export default OrderQuoteForm
