/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa'

import { useAuth } from '../../auth/context/useAuth'
import Table from '../../../components/table'

interface ProfileVal {
    key: string
    value: string
}

const columns = [
    {
        name: '',
        width: '300px',
        cell: (row: { key: string }) => {
            return (
                <div>
                    <p className="text-black text-bold">{row.key}</p>
                </div>
            )
        },
    },
    {
        name: '',
        left: true,
        width: '300px',
        cell: (row: { value: string }) => {
            return (
                <div>
                    <p className="">{row.value}</p>
                </div>
            )
        },
    },
]

const Profile = () => {
    const { user, userExtra } = useAuth()
    const [userProfile, setUserProfile] = useState<ProfileVal[]>([])

    useEffect(() => {
        setUserProfile([
            {
                key: 'Name',
                value: user.name,
            },
            {
                key: 'Email',
                value: user.email,
            },
            {
                key: 'Role',
                value: userExtra.role,
            },
            {
                key: 'Email Subscribed',
                value: user.is_subscribed ? 'Yes' : 'No',
            },
        ])
    }, [user])

    return (
        <div className="px-8 py-4 rounded-xl flex flex-col items-center gap-4">
            <div className="mt-20 m-1 mr-2 md:w-48 md:h-48 relative flex justify-center items-center rounded-full bg-primary text-xl text-white uppercase">
                <FaUser size={72} />
            </div>
            <div className="border-2 border-slate-100 rounded-lg">
                <Table
                    columns={columns}
                    striped={false}
                    data={userProfile || []}
                    pagination={false}
                    totalRows={5}
                    noHeader
                />
            </div>
        </div>
    )
}

export default Profile
