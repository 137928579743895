import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import Select from 'react-select'
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5'
import Button from '../../../components/button'
import { DiscountQueries } from '../queries/discount'
import { useAuth } from '../../auth/context/useAuth'
import { IDiscounts } from '../models/Discounts'
import NoDocuments from '../components/NoDocuments'
import DiscountRequest from '../components/DiscountRequest'
import DiscountHistory from '../components/DiscountHistory'
import Loading from '../../../components/loading'
import Table from '../../../components/table'

const initialUserSelectList = [
    {
        label: 'Requested By: All',
        value: 'All',
    },
]

const repliedRequestColumn = [
    {
        name: 'Requested By',
        left: true,
        selector: (row: any) => row.name,
    },
    {
        name: 'Requested Discount %',
        selector: (row: any) => row.requested_discount_percentage,
    },
    {
        name: 'Approved Discount %',
        selector: (row: any) => row.provided_discount,
    },
    {
        name: 'Configuration Name',
        height: 'fit-content',
        wrap: true,
        selector: (row: { configuration_name: string }) => row.configuration_name,
        left: true,
    },
    {
        name: 'Reply Comment',
        height: 'fit-content',
        wrap: true,
        selector: (row: { comment: string }) => row.comment,
        left: true,
    },
    {
        name: 'Requested At',
        selector: (row: any) => row.requested_at,
    },
    {
        name: 'Replied By',

        selector: (row: any) => row.replied_by_name,
    },
    {
        name: 'Replied At',
        selector: (row: any) => row.published_at,
    },
    {
        name: 'Configuration Link',
        cell: (row: any) => (
            <Link className="red-link" to={`/configuration/${row.requested_configuration_id}`}>
                See Configuration
            </Link>
        ),
    },
]
const RequestedNotificationColumn = [
    {
        name: 'Replied By',
        left: true,
        selector: (row: any) => row.name,
    },
    {
        name: 'Reply Status',
        center: true,
        cell: (row: any) =>
            row.is_approved ? (
                <IoCheckmarkCircle className="text-green-500" size={24} />
            ) : (
                <IoCloseCircle className="text-red-500" size={24} />
            ),
    },
    {
        name: 'Requested Discount %',
        center: true,
        selector: (row: any) => row.requested_discount_percentage,
    },
    {
        name: 'Approved Discount %',
        center: true,
        selector: (row: any) => row.provided_discount,
    },
    {
        name: 'Configuration Name',
        height: 'fit-content',
        wrap: true,
        selector: (row: { configuration_name: string }) => row.configuration_name,
        left: true,
    },
    {
        name: 'Reply Comment',
        hide: 'sm',
        height: 'fit-content',
        wrap: true,
        selector: (row: { comment: string }) => row.comment,
        left: true,
    },
    {
        name: 'Requested At',
        hide: 'sm',
        selector: (row: any) => row.requested_at,
    },
    {
        name: 'Replied At',
        hide: 'sm',
        selector: (row: any) => row.published_at,
    },
    {
        name: 'Configuration Link',
        cell: (row: any) => (
            <Link className="red-link" to={`/configuration/${row.requested_configuration_id}`}>
                See Configuration
            </Link>
        ),
    },
]

const Discounts = () => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { user, userExtra } = useAuth()
    const [userList, setUserList] = useState<any[]>(initialUserSelectList)
    const [discountData, setDiscountData] = useState<IDiscounts>()
    const [repliedNotificationsLogs, setRepliedNotificationsLogs] = useState<IDiscounts[]>()
    const [requestedNotificationsLogs, setRequestedNotificationsLogs] = useState<IDiscounts[]>()
    const [isHistory, setIsHistory] = useState<boolean>(
        pathname.substring(1) === 'notification-logs',
    )
    const [selectedUser, setSelectedUser] = useState<any>()

    const { isLoading: oldDiscountHistoryLogsLoading } = useQuery(
        ['getOldDiscountHistoryLogs'],
        () => DiscountQueries.getOldDiscountRequestLogs({ userId: user.id }),
        {
            enabled: Boolean(isHistory && !userExtra.is_manager),
            onSuccess: data => {
                setRepliedNotificationsLogs(data.data.replied_notification_log)
                setRequestedNotificationsLogs(data.data.requested_notification_log)
            },
        },
    )

    const {
        data: discounts,
        isLoading,
        refetch,
    } = useQuery(
        ['getDiscounts'],
        () => {
            if (userExtra.is_manager) {
                return DiscountQueries.getDiscountsByUserId({ userId: user.id })
            }
            return DiscountQueries.getAllDiscountByUserId({ userId: user.id })
        },
        {
            refetchInterval: false,
            refetchIntervalInBackground: false,
            onSuccess: data => {
                let users = []
                if (data && data.data) {
                    users = data.data.list_of_child_users?.map((x: any) => ({
                        label: x.name,
                        value: x.name,
                    }))
                } else {
                    users = []
                }
                if (users && users.length > 0) {
                    setUserList([...initialUserSelectList, ...users])
                }
            },
        },
    )

    useEffect(() => {
        if (discounts) {
            setDiscountData(discounts.data)
        }
    }, [discounts])

    useEffect(() => {
        if (selectedUser) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const data = {
                all_replied_notification_user_name:
                    discounts?.data.all_replied_notification_user_name,
                all_unreplied_requested_notification:
                    discounts?.data.all_unreplied_requested_notification,
                list_of_child_users: discounts?.data.list_of_child_users,
            }
            if (selectedUser.value === 'All') {
                setDiscountData(data)
            } else {
                data.all_replied_notification_user_name =
                    discounts?.data.all_replied_notification_user_name.filter(
                        (request: any) => request.name === selectedUser.value,
                    )
                setDiscountData(data)
            }
        }
    }, [selectedUser])

    if (isLoading || oldDiscountHistoryLogsLoading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-col md:flex-row gap-2">
                <h1 className="text-primary border-primary capitalize text-left md:text-center">
                    {!isHistory ? 'Discount Requests' : 'Discount History'}
                </h1>
                <div className=" flex flex-1 flex-col md:flex-row items-center gap-4 z-10 justify-end ">
                    {Boolean(userExtra.is_manager) && (
                        <Select
                            className="w-full md:w-1/4 rounded-md"
                            options={userList}
                            value={selectedUser}
                            onChange={(value: any) => setSelectedUser(value)}
                            placeholder="Select Requested User"
                        />
                    )}
                    <div className="w-full md:w-1/6 rounded-md">
                        <Button
                            label={!isHistory ? 'View History' : 'View Requests'}
                            variant="primary"
                            onClick={async () => {
                                await setIsHistory(!isHistory)
                                if (isHistory) {
                                    navigate('/notifications')
                                } else {
                                    navigate('/notification-logs')
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="overflow-x-hidden overflow-y-scroll">
                {((!isHistory && !discountData?.all_unreplied_requested_notification) ||
                    (isHistory &&
                        !(
                            discountData?.all_replied_notification_user_name ||
                            (repliedNotificationsLogs && repliedNotificationsLogs.length > 0) ||
                            (requestedNotificationsLogs && requestedNotificationsLogs.length > 0)
                        ))) && <NoDocuments />}
                {!isHistory && discountData?.all_unreplied_requested_notification && (
                    <DiscountRequest
                        data={discountData?.all_unreplied_requested_notification}
                        refetch={refetch}
                    />
                )}
                {isHistory && repliedNotificationsLogs && repliedNotificationsLogs.length > 0 && (
                    <div className="w-full flex flex-col justify-start">
                        <p className="w-full text-left text-xl font-bold">Received Requests</p>
                        <div className="w-[90vw] md:w-full">
                            <Table
                                columns={repliedRequestColumn}
                                data={repliedNotificationsLogs || []}
                                striped
                                pagination
                            />
                        </div>
                    </div>
                )}
                {isHistory &&
                    requestedNotificationsLogs &&
                    requestedNotificationsLogs.length > 0 && (
                        <>
                            <p className="text-center text-xl font-bold">My Requests</p>
                            <div className="w-[90vw] md:w-full">
                                <Table
                                    columns={RequestedNotificationColumn}
                                    data={requestedNotificationsLogs || []}
                                    striped
                                    pagination
                                    totalRows={10}
                                />
                            </div>
                        </>
                    )}
                {isHistory && discountData?.all_replied_notification_user_name && (
                    <DiscountHistory data={discountData?.all_replied_notification_user_name} />
                )}
            </div>
        </div>
    )
}

export default Discounts
