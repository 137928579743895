/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getLogsChanges } from '../queries/log'
import Loading from '../../../components/loading'

const Summary = () => {
    const { configurationId } = useParams()
    const [summary, setSummary] = useState<any>([])

    const { isLoading, isFetching } = useQuery(
        ['logResult'],
        () => getLogsChanges(configurationId || ''),
        {
            refetchOnMount: true,
            onSuccess: (data: any) => {
                if (data?.data) {
                    setSummary(data.data)
                } else {
                    setSummary([])
                }
            },
        },
    )

    const getName = (type: string) => {
        switch (type) {
            case 'added':
                return 'Add Machine Part'
            case 'removed':
                return 'Remove Machine Part'
            default:
                return 'Machine Quantity Change'
        }
    }

    if (isLoading || isFetching) {
        return (
            <div className="flex justify-center items-center bg-white px-8 py-4 rounded-xl gap-4 max-h-[84vh] min-h-[84vh]">
                <Loading />
            </div>
        )
    }
    return (
        <div className="flex flex-col gap-4">
            {summary.map((log: any, index: number) => (
                <div>
                    <table className="table-auto w-full">
                        {!isEmpty(log) && (
                            <thead>
                                <tr>
                                    <th className="p-2 whitespace-nowrap text-left text-md">
                                        Changes Field
                                    </th>
                                    <th className="p-2 whitespace-nowrap text-center text-md">
                                        Version
                                    </th>
                                    <th className="p-2 whitespace-nowrap text-left text-md">
                                        Machine Part Number
                                    </th>
                                    <th className="p-2 whitespace-nowrap text-left text-md">
                                        Machine Part Description
                                    </th>
                                    <th className="p-2 whitespace-nowrap text-left text-md">
                                        Modifications
                                    </th>
                                    <th className="p-2 whitespace-nowrap text-left text-md">
                                        Modified By
                                    </th>
                                    <th className="p-2 whitespace-nowrap text-left text-md">
                                        Date Modified
                                    </th>
                                </tr>
                            </thead>
                        )}
                        <tbody className="text-sm divide-y divide-gray-100">
                            {log.current_approve_request !== log.old_approve_request ? (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-md text-black">
                                        Approve Request
                                    </td>
                                    <td className="p-2 whitespace-nowrap">
                                        {parseInt(String(index), 10) === 0
                                            ? 'Latest'
                                            : summary.length - parseInt(String(index), 10) <= 0
                                            ? 1
                                            : summary.length - parseInt(String(index), 10)}
                                    </td>
                                    <td className="p-2 whitespace-nowrap" />
                                    <td className="p-2 whitespace-nowrap" />
                                    <td className="p-2 whitespace-nowrap">
                                        {log.current_approve_request ? 'Approved' : 'Not Approved'}
                                    </td>
                                    <td className="p-2 whitespace-nowrap">{log.changed_by}</td>
                                    <td className="p-2 whitespace-nowrap">{log.modified_date}</td>
                                </tr>
                            ) : null}

                            {log.current_approve_with_change !== log.old_approve_with_change ? (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        Approve Request with change
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                        {index === 0
                                            ? 'Latest'
                                            : summary.length - index <= 0
                                            ? 1
                                            : summary.length - index}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.current_approve_with_change
                                            ? 'Approved with change'
                                            : 'Not Approved'}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.changed_by}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.modified_date}
                                    </td>
                                </tr>
                            ) : null}

                            {log.quantity_difference !== 0 ? (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        Quantity
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                        {index === 0
                                            ? 'Latest'
                                            : summary.length - index <= 0
                                            ? 1
                                            : summary.length - index}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {`From [${
                                            log.current_quantity - log.quantity_difference
                                        }] to [${log.current_quantity}]`}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.changed_by}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.modified_date}
                                    </td>
                                </tr>
                            ) : null}

                            {log.discount_percentage_difference !== 0 ? (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        Discount Percentage
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                        {index === 0
                                            ? 'Latest'
                                            : summary.length - index <= 0
                                            ? 1
                                            : summary.length - index}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {`From [${(
                                            log.current_discount_percentage -
                                            log.discount_percentage_difference
                                        ).toFixed(
                                            2,
                                        )}%] to [${log.current_discount_percentage.toFixed(2)}%]`}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.changed_by}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.modified_date}
                                    </td>
                                </tr>
                            ) : null}

                            {log.disount_amount_difference !== 0 && (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        Discount Amount
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                        {index === 0
                                            ? 'Latest'
                                            : summary.length - index <= 0
                                            ? 1
                                            : summary.length - index}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {`From [${(
                                            log.current_discount_amount -
                                            log.disount_amount_difference
                                        ).toFixed(2)}] to [${log.current_discount_amount.toFixed(
                                            2,
                                        )}]`}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.changed_by}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.modified_date}
                                    </td>
                                </tr>
                            )}

                            {log?.dealer_discount_amount_difference !== 0 && (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        Dealer Discount Percentage
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                        {index === 0
                                            ? 'Latest'
                                            : summary.length - index <= 0
                                            ? 1
                                            : summary.length - index}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {`From [${(
                                            log.current_dealer_discount_amount -
                                            log.dealer_discount_amount_difference
                                        ).toFixed(
                                            2,
                                        )}] to [${log.current_dealer_discount_amount.toFixed(2)}]`}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.changed_by}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.modified_date}
                                    </td>
                                </tr>
                            )}

                            {log?.oem_discount_difference !== 0 && (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        OEM Discount Percentage
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                        {index === 0
                                            ? 'Latest'
                                            : summary.length - index <= 0
                                            ? 1
                                            : summary.length - index}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {`From ${(
                                            log.current_oem_discount - log.oem_discount_difference
                                        ).toFixed(2)} to ${log.current_oem_discount.toFixed(2)}`}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.changed_by}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.modified_date}
                                    </td>
                                </tr>
                            )}

                            {/* extra_discount */}
                            {log?.extra_discount_difference !== 0 && (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        Extra Discount Percentage
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                        {index === 0
                                            ? 'Latest'
                                            : summary.length - index <= 0
                                            ? 1
                                            : summary.length - index}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {`From ${(
                                            log.current_extra_discount -
                                            log.extra_discount_difference
                                        ).toFixed(2)} to ${log.current_extra_discount.toFixed(2)}`}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.changed_by}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.modified_date}
                                    </td>
                                </tr>
                            )}

                            {/* sales margin */}
                            {log?.sales_margin_difference !== 0 && (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        Sales Margin
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                        {index === 0
                                            ? 'Latest'
                                            : summary.length - index <= 0
                                            ? 1
                                            : summary.length - index}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {`From [${(
                                            log.current_sales_margin - log.sales_margin_difference
                                        ).toFixed(2)}%] to [${log.current_sales_margin.toFixed(
                                            2,
                                        )}%]`}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.changed_by}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.modified_date}
                                    </td>
                                </tr>
                            )}

                            {/* part changed */}
                            {log?.parts_changed?.length !== 0 &&
                                log?.parts_changed?.map((part: any, partIndex: number) => (
                                    <tr>
                                        <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                            {getName(
                                                part.removed
                                                    ? 'removed'
                                                    : part.added
                                                    ? 'added'
                                                    : '',
                                            )}
                                        </td>
                                        <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                            {parseInt(String(partIndex), 10) === 0
                                                ? 'Latest'
                                                : summary.length -
                                                      parseInt(String(partIndex), 10) <=
                                                  0
                                                ? 1
                                                : summary.length - parseInt(String(partIndex), 10)}
                                        </td>
                                        <td>{part.machine_part_model_number}</td>
                                        <td>{part.machine_part_name}</td>
                                        <td className="flex items-center gap-2 h-full">
                                            <span>
                                                {part.quantity_change &&
                                                    `From ${Math.max(
                                                        0,
                                                        part.current_quantity - part.quantity,
                                                    )} to ${part.current_quantity}`}
                                            </span>
                                            <span>{part.removed ? 'Delete' : null}</span>
                                            <span>{part.added ? 'Add' : null}</span>
                                        </td>
                                        <td>{log.changed_by}</td>
                                        <td>{log.modified_date}</td>
                                    </tr>
                                ))}

                            {/* manual adjustment */}
                            {log?.manual_adjustments_changed?.length !== 0 &&
                                log?.manual_adjustments_changed?.map((manual: any) => (
                                    <tr>
                                        <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                            {getName(
                                                manual.removed
                                                    ? 'removed'
                                                    : manual.added
                                                    ? 'added'
                                                    : '',
                                            )}
                                        </td>
                                        <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                            {parseInt(String(index), 10) === 0
                                                ? 'Latest'
                                                : Math.max(
                                                      1,
                                                      summary.length - parseInt(String(index), 10),
                                                  )}
                                        </td>
                                        <td>{manual.manual_adjustment_model_number}</td>
                                        <td>{manual.manual_adjustment_name}</td>
                                        <td>
                                            {manual.quantity_change &&
                                                `From ${
                                                    manual.current_quantity - manual.quantity
                                                } to ${manual.current_quantity}`}

                                            {manual.removed ? 'Delete' : null}
                                            {manual.added ? 'Add' : null}
                                        </td>
                                        <td>{log.changed_by}</td>
                                        <td>{log.modified_date}</td>
                                    </tr>
                                ))}

                            {/* only machine */}
                            {log.machine_total_price_difference !== 0 ? (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        Total Machine Price
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                        {parseInt(String(index), 10) === 0
                                            ? 'Latest'
                                            : summary.length - parseInt(String(index), 10) <= 0
                                            ? 1
                                            : summary.length - parseInt(String(index), 10)}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {`From ${(
                                            log.current_machine_total_price -
                                            log.machine_total_price_difference
                                        ).toFixed(2)} to ${log.current_machine_total_price.toFixed(
                                            2,
                                        )}`}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.changed_by}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.modified_date}
                                    </td>
                                </tr>
                            ) : null}

                            {log.machine_total_price_difference !== 0 ? (
                                <tr>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        Current Total Machine Price
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-center text-md text-black">
                                        {parseInt(String(index), 10) === 0
                                            ? 'Latest'
                                            : summary.length - parseInt(String(index), 10) <= 0
                                            ? 1
                                            : summary.length - parseInt(String(index), 10)}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black" />
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.current_machine_total_price.toFixed(2)}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.changed_by}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-left text-md text-black">
                                        {log.modified_date}
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                    <hr className="mt-5 border-2" />
                </div>
            ))}
        </div>
    )
}

export default Summary
