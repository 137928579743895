/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select } from 'antd'
import { map } from 'lodash'
import Configure from 'modules/configuration/components/details/configure'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getLogsConfiguration } from '../queries/log'
import Loading from '../../../components/loading'

const { Option } = Select

const Logs = () => {
    const { configurationId } = useParams()
    const [log, setLog] = useState<any>({} as any)
    const [logList, setLogList] = useState<any>([])

    const logResult = useQuery('logs', () => getLogsConfiguration(configurationId || ''), {
        onSuccess: data => {
            const logItems = map(data?.data || [], item => {
                return {
                    value: item.log_configuration.log_configuration_id,
                    label: `${item.log_configuration.machine_name}-${item.log_configuration.log_configuration_id}`,
                    machine: item.log_configuration,
                    parts: item.parts,
                    adjustment: item.manual_adjustments,
                }
            })
            setLogList(logItems)
            setLog(logItems[0])
        },
        keepPreviousData: true,
        refetchOnMount: true,
    })
    const handleChange = (value: any) => {
        const choosenLog = logList.find((item: { value: any }) => item.value === value)
        setLog(choosenLog)
    }
    if (logList.length === 0 && !logResult.isLoading) {
        return <p>No Logs</p>
    }
    if (logResult.isLoading) {
        return <p>Loading</p>
    }
    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col items-start gap-2">
                <Select
                    defaultValue={log.value}
                    className="border border-3 border-gray-400 rounded-lg w-1/3"
                    onChange={handleChange}
                    bordered={false}
                    size="large"
                    placeholder="Choose Logs Date"
                    loading={logResult.isLoading}
                >
                    {map(logList || [], (item: any) => (
                        <Option value={item.value}>{item.label}</Option>
                    ))}
                </Select>
            </div>

            {logResult.isLoading || logResult.isFetching ? (
                <div className="flex w-full justify-center items-center">
                    <Loading />
                </div>
            ) : (
                <Configure
                    configuration={{
                        machine: log.machine || [],
                        parts: log.parts || [],
                        adjustment: log.adjustment || [],
                    }}
                    isOrder
                />
            )}
        </div>
    )
}
export default Logs
