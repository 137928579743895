import React, { forwardRef } from 'react'

interface InputProps {
    type?: 'text' | 'number' | 'password' | 'file' | 'checkbox'
    placeholder?: string
    max?: number
    value: string | number | null
    label?: string
    name: string
    error?: string | null
    disabled?: boolean
    center?: boolean
    checked?: boolean
    leftContent?: React.ReactElement
    rightContent?: React.ReactElement
    customInputStyles?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
    const {
        type,
        placeholder,
        value,
        label,
        name,
        disabled,
        center,
        checked,
        error,
        max,
        leftContent,
        rightContent,
        customInputStyles,
        onChange,
    } = props
    const outlineInput = 'border-2 border-inputBorder placeholder-placeholderColor'
    const inputClass = 'rounded-lg flex items-center gap-2'

    return (
        <div className="flex flex-col justify-start gap-1 w-full">
            {label && (
                <label htmlFor={name} className="text-md px-1">
                    {label}
                </label>
            )}
            <div className={`${inputClass} ${outlineInput}`}>
                {leftContent && <span className="pl-2 text-placeholderColor ">{leftContent}</span>}
                <input
                    ref={ref}
                    type={type || 'text'}
                    placeholder={placeholder}
                    value={value || ''}
                    onChange={onChange}
                    name={name}
                    checked={checked}
                    disabled={Boolean(disabled)}
                    max={max}
                    min={0}
                    className={`py-2 px-2 w-full focus:outline-none rounded-lg ${
                        center && 'text-center'
                    } ${customInputStyles}`}
                />
                {rightContent && <span className="pr-2">{rightContent}</span>}
            </div>
            {error && <span className="text-red-200 text-sm">{error}</span>}
        </div>
    )
})

export default Input
