import './index.css'

const ButtonLoader = () => {
    return (
        <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
        </div>
    )
}

export default ButtonLoader
