import Header from 'components/header'
import Footer from 'modules/shared/components/Footer'

interface BlankLayoutProps {
    children: React.ReactElement
}

const BlankLayout = (props: BlankLayoutProps) => {
    const { children } = props
    return (
        <div className="bg-inputBorder flex flex-col justify-between gap-4 relative h-screen">
            <div className="w-full z-50">
                <Header />
            </div>
            <div className="container mx-auto flex flex-col gap-4 flex-1 h-screen overflow-auto">
                {children}
            </div>
            <div className="flex items-center justify-center w-full">
                <Footer />
            </div>
        </div>
    )
}

export default BlankLayout
