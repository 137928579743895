/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import settingImage from 'assets/svg/setting.png'
import classNames from 'classnames'
import Button from 'components/button'
import DeleteModal from 'components/modal/deleteModal'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { errorToast, successToast } from '../../../../utils/toasterUtil'
import { useAuth } from '../../../auth/context/useAuth'
import { ConfigurationQueries } from '../../queries/configuration'
import DiscountRequestModal from '../discountRequestModal'
import DuplicateModal from '../duplicateModal'

const imageUrl = process.env.REACT_APP_IMAGE_URL

interface ConfigurDeetailProp {
    machine: {
        configuration_id: string
        machine_category_image: string
        machine_total_price: number
        quantity: number
        name: string
        machine_category: string
        machine_name: string
        log_configuration_id: string
        discount_percentage: number
        discount_amount: number
        extra_discount: number
        dealer_discount_amount: number
        currency: string
        country_code: string
        oem_discount: number
        sales_margin: number
        end_user_sales_price: number
        sales_margin_amount: number
        quote_id: number
        trade_in_value?: number
        discount_not_applicable: number
        discount_applicable: number
        only_parts: boolean
    }
    orderId?: boolean
    hideImage: boolean
    machineQuoteId?: string
}

const ConfigureDetail = (props: ConfigurDeetailProp) => {
    const { machine, orderId, hideImage, machineQuoteId } = props

    const { from } = useParams()
    const { user } = useAuth()
    const { userExtra } = useAuth()
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [configuationOrder, setConfiguationOrder] = useState<boolean>(false)
    const [duplicateModal, setDuplicateModal] = useState<boolean>(false)
    const [higherDiscountModal, setHigherDiscountModal] = useState<boolean>(false)

    const navigate = useNavigate()

    useQuery(
        ['checkIfConfigurationIsorder', machine.configuration_id],
        () =>
            ConfigurationQueries.checkIfConfigurationIsOrderd({
                configurationId: machine.configuration_id,
            }),
        {
            enabled:
                !!window.location.href &&
                !window.location.href.includes('library') &&
                !!machine.configuration_id,
            onSuccess: (checkConfiguration: any) => {
                setConfiguationOrder(
                    Boolean(checkConfiguration?.data?.message.toLowerCase().includes('not')),
                )
            },
        },
    )

    const { data: hasChildren } = useQuery(
        ['checkUserHasChildren', user.id],
        () =>
            ConfigurationQueries.getUserHasChildren({
                userId: parseInt(user.id, 10),
            }),
        {
            enabled: !!user.id,
        },
    )

    const { data: getStatusOfLibraryConfiguration, refetch } = useQuery(
        ['getLibraryConfigurationStatus', machine.configuration_id],
        () =>
            ConfigurationQueries.getStatusOfLibraryConfiguration({
                configurationId: parseInt(machine.configuration_id, 10),
            }),
        {
            enabled: !!machine.configuration_id && !!from,
        },
    )

    const changeConfigurationStatusMutation = useMutation(
        ['changeConfigurationConfiguration'],
        (params: { configurationId: number; configurationStatus: number }) => {
            return ConfigurationQueries.changeLibraryConfigurationStatus({
                configurationId: params.configurationId,
                configurationStatus: params.configurationStatus,
            })
        },
        {
            onSuccess: libraryStatus => {
                refetch()
                toast(libraryStatus?.data?.message || 'Status Changed Successfully', successToast)
            },
            onError: (error: any) => {
                toast(error?.message || 'Something went wrong!!', successToast)
            },
        },
    )

    const deleteMutation = useMutation(
        ['deleteConfiguration'],
        (configurationId: number) => ConfigurationQueries.deleteConfiguration({ configurationId }),
        {
            onSuccess: data => {
                navigate('/configuration')
                toast(data?.data?.message || 'Deleted Successfully', successToast)
            },
            onError: (error: any) => {
                toast(error?.message || 'Something went wrong!!', successToast)
            },
        },
    )

    const handleDelete = (configurationId: number, quoteId?: number) => {
        if (!quoteId) {
            deleteMutation.mutate(configurationId)
        } else {
            toast(
                'This configuration is already used in a quote so you cannot delete it.',
                errorToast,
            )
        }
    }

    const handleEditConfiguration = () => {
        if (window.location.href.includes('configuration/library')) {
            navigate(`/configuration/edit/${machine.configuration_id}/library`)
        } else {
            navigate(`/configuration/edit/${machine.configuration_id}`)
        }
    }

    const handleDuplicateModal = () => {
        setDuplicateModal(!duplicateModal)
    }

    const handleDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const handleDiscountModal = () => {
        setHigherDiscountModal(true)
    }

    const retailPrice = machine.only_parts ? 0 : machine.machine_total_price / machine.quantity
    const discountPercentageAmount =
        (machine.discount_percentage * machine.discount_applicable) / 100

    const dealerNetTotal =
        machine.discount_not_applicable +
        machine.discount_applicable -
        discountPercentageAmount -
        machine.discount_amount -
        machine.dealer_discount_amount

    const netTotal =
        machine.discount_not_applicable +
        machine.discount_applicable -
        discountPercentageAmount -
        machine.discount_amount

    const netNetPurchasePrice =
        machine.machine_total_price / machine.quantity -
        (machine.discount_percentage * (machine.discount_applicable / machine.quantity)) / 100 -
        machine.discount_amount -
        machine.dealer_discount_amount -
        machine.oem_discount

    const grandTotal = machine.only_parts ? netTotal : netTotal * machine.quantity

    return (
        <div
            className={classNames(
                'md:flex md:justify-between items-center',
                orderId || machine.quote_id ? 'md:gap-6' : 'md:gap-20',
            )}
        >
            {(!orderId || !hideImage) && (
                <div className="flex flex-col w-1/3 h-full md:block">
                    <div className={classNames('rounded-lg bg-white drop-shadow-md p-8')}>
                        <img
                            src={`${
                                machine.machine_category_image
                                    ? `${imageUrl}${machine.machine_category_image}`
                                    : settingImage
                            }`}
                            alt=""
                            className="object-contain"
                        />
                    </div>
                </div>
            )}

            <div className="flex-1 min-w-[20rem] md:min-w-[24rem] flex flex-col gap-4 md:gap-8">
                <div className="flex flex-col gap-2">
                    <p className="text-2xl">{machine.name || ''}</p>
                    {machine?.log_configuration_id && (
                        <p className="text-lg text-placeholderColor font-bold">
                            {machine?.machine_name || ''}-{machine?.log_configuration_id || ''}
                        </p>
                    )}
                    <p className="text-lg text-placeholderColor font-bold">
                        {machine.machine_category || ''}
                    </p>
                </div>
                <div className="p-2 md:p-8 drop-shadow-md bg-white rounded-lg">
                    <table className="table-auto w-[100%]">
                        <tbody>
                            <tr>
                                <th className="px-4 py-2 font-medium text-left text-gray-900">
                                    Retail Price
                                </th>
                                <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                    {formatCurrency(
                                        retailPrice.toFixed(2),
                                        machine.currency || machine.country_code || '',
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="px-4 py-2 font-medium text-left text-gray-900">
                                    Discount Applicable Price
                                </th>
                                <td className="px-4 py-2 font-bold text-gray-900 text-right   flex justify-end">
                                    {formatCurrency(
                                        String(machine?.discount_applicable || 0),
                                        machine.currency || machine.country_code || '',
                                    )}
                                </td>
                            </tr>
                            {machine.discount_percentage !== 0 && machine.discount_percentage && (
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900">
                                        {userExtra.is_dealer
                                            ? 'Standard Dealer Terms %'
                                            : 'Discount Percentage'}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                        {machine.discount_percentage.toFixed(0)}
                                        {' %'}
                                    </td>
                                </tr>
                            )}
                            {machine.discount_amount !== 0 && (
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900">
                                        {userExtra.is_dealer
                                            ? 'Dealer Discount Amount'
                                            : 'Discount Amount'}
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                        {formatCurrency(
                                            machine.discount_amount,
                                            machine.currency || machine.country_code || '',
                                        )}
                                    </td>
                                </tr>
                            )}
                            {machine.dealer_discount_amount !== 0 &&
                                userExtra.extra_discount_visible !== 0 && (
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900">
                                            Extra Discount
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                            {formatCurrency(
                                                machine.dealer_discount_amount,
                                                machine.currency || machine.country_code || '',
                                            )}
                                        </td>
                                    </tr>
                                )}
                            <tr>
                                <th className="px-4 py-2 font-medium text-left text-gray-900">
                                    Discount Not Applicable Price
                                </th>
                                <td className="px-4 py-2 font-bold text-gray-900 text-right   flex justify-end">
                                    {formatCurrency(
                                        String(machine?.discount_not_applicable || 0),
                                        machine.currency || machine.country_code || '',
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="px-4 py-2 font-medium text-left text-gray-900">
                                    {userExtra.is_dealer ? 'Dealer Net Total' : 'Net Total'}
                                </th>
                                <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                    {userExtra.is_dealer
                                        ? formatCurrency(
                                              dealerNetTotal.toFixed(2),
                                              machine.currency || machine.country_code || '',
                                          )
                                        : formatCurrency(
                                              netTotal.toFixed(2),
                                              machine.currency || machine.country_code || '',
                                          )}
                                </td>
                            </tr>
                            {userExtra.is_dealer !== 0 && (
                                <>
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900">
                                            OEM Programme
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                            {formatCurrency(
                                                machine.oem_discount.toFixed(2),
                                                machine.currency || machine.country_code || '',
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900">
                                            Net Net Purchase Price
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                            {formatCurrency(
                                                netNetPurchasePrice.toFixed(2),
                                                machine.currency || machine.country_code || '',
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900">
                                            Extra Discount
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                            {formatCurrency(
                                                machine.extra_discount,
                                                machine.currency || machine.country_code || '',
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900">
                                            Extra Extra Support
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                            {formatCurrency(
                                                (
                                                    (machine.extra_discount * netNetPurchasePrice) /
                                                    100
                                                ).toFixed(2),
                                                machine.currency || machine.country_code || '',
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="px-4 py-2 font-medium text-left text-gray-900">
                                            Net Net Net Price
                                        </th>
                                        <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                            {formatCurrency(
                                                (
                                                    machine.machine_total_price / machine.quantity -
                                                    (machine.discount_percentage *
                                                        (machine.machine_total_price /
                                                            machine.quantity)) /
                                                        100 -
                                                    machine.discount_amount -
                                                    machine.dealer_discount_amount -
                                                    machine.oem_discount -
                                                    (machine.extra_discount *
                                                        (machine.machine_total_price /
                                                            machine.quantity -
                                                            (machine.discount_percentage *
                                                                (machine.machine_total_price /
                                                                    machine.quantity)) /
                                                                100 -
                                                            machine.discount_amount -
                                                            machine.dealer_discount_amount -
                                                            machine.oem_discount)) /
                                                        100
                                                ).toFixed(2),
                                                machine.currency || machine.country_code || '',
                                            )}
                                        </td>
                                    </tr>
                                </>
                            )}
                            {machine && machine.sales_margin !== 0 && machine.sales_margin ? (
                                userExtra.sales_margin_visible ? (
                                    userExtra.is_dealer ? (
                                        <>
                                            <tr>
                                                <th className="px-4 py-2 font-medium text-left text-gray-900">
                                                    Ene Customer Purchase Price
                                                </th>
                                                <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                                    {formatCurrency(
                                                        machine.end_user_sales_price.toFixed(2),
                                                        machine.currency ||
                                                            machine.country_code ||
                                                            '',
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="px-4 py-2 font-medium text-left text-gray-900">
                                                    Dealer Margin Amount
                                                </th>
                                                <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                                    {formatCurrency(
                                                        machine.sales_margin_amount.toFixed(2),
                                                        machine.currency ||
                                                            machine.country_code ||
                                                            '',
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="px-4 py-2 font-medium text-left text-gray-900">
                                                    Dealer Margin
                                                </th>
                                                <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                                    {machine.sales_margin.toFixed(2)}
                                                    {' %'}
                                                </td>
                                            </tr>
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <th className="px-4 py-2 font-medium text-left text-gray-900">
                                                    Sales Margin
                                                </th>
                                                <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                                    {machine.sales_margin.toFixed(2)}
                                                    {' %'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="px-4 py-2 font-medium text-left text-gray-900">
                                                    Total Invoice for customer
                                                </th>
                                                <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                                    {formatCurrency(
                                                        machine.end_user_sales_price.toFixed(2),
                                                        machine.currency ||
                                                            machine.country_code ||
                                                            '',
                                                    )}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                ) : null
                            ) : null}
                            {userExtra.customer_discount_visible && !userExtra.is_dealer ? (
                                <tr>
                                    <th className="px-4 py-2 font-medium text-left text-gray-900">
                                        Customer Discount
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                        {(
                                            (Math.abs(
                                                machine.machine_total_price -
                                                    (machine.machine_total_price -
                                                        (machine.machine_total_price *
                                                            machine.discount_percentage) /
                                                            100 -
                                                        machine.discount_amount -
                                                        machine.dealer_discount_amount -
                                                        machine.oem_discount -
                                                        (machine.extra_discount *
                                                            (machine.machine_total_price /
                                                                (machine.only_parts
                                                                    ? 1
                                                                    : machine.quantity) -
                                                                (machine.discount_percentage *
                                                                    (machine.machine_total_price /
                                                                        (machine.only_parts
                                                                            ? 1
                                                                            : machine.quantity))) /
                                                                    100 -
                                                                machine.discount_amount -
                                                                machine.dealer_discount_amount -
                                                                machine.oem_discount)) /
                                                            100 +
                                                        (machine.sales_margin *
                                                            (machine.machine_total_price -
                                                                (machine.machine_total_price *
                                                                    machine.discount_percentage) /
                                                                    100 -
                                                                machine.discount_amount -
                                                                machine.dealer_discount_amount -
                                                                machine.oem_discount -
                                                                (machine.extra_discount *
                                                                    (machine.machine_total_price /
                                                                        (machine.only_parts
                                                                            ? 1
                                                                            : machine.quantity) -
                                                                        (machine.discount_percentage *
                                                                            (machine.machine_total_price /
                                                                                (machine.only_parts
                                                                                    ? 1
                                                                                    : machine.quantity))) /
                                                                            100 -
                                                                        machine.discount_amount -
                                                                        machine.dealer_discount_amount -
                                                                        machine.oem_discount)) /
                                                                    100)) /
                                                            100),
                                            ) /
                                                machine.machine_total_price) *
                                            100
                                        ).toFixed(2)}
                                        {' %'}
                                    </td>
                                </tr>
                            ) : null}
                            <tr>
                                <th className="px-4 py-2 font-medium text-left text-gray-900">
                                    Configuration Quantity
                                </th>
                                <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                    {machine.quantity}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {machine && (
                        <table className="table-auto w-[100%]">
                            <tbody>
                                {Boolean(userExtra.is_dealer) && (
                                    <tr>
                                        <td colSpan={1000}>
                                            <hr />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th
                                        className={`px-4 py-2 font-medium text-left text-gray-900 ${
                                            userExtra.is_dealer && 'text-semibold'
                                        }`}
                                    >
                                        Final Net total
                                    </th>
                                    <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                        {formatCurrency(
                                            grandTotal.toFixed(2),
                                            machine.currency || machine.country_code || '',
                                        )}
                                    </td>
                                </tr>
                                {!userExtra.is_dealer && (
                                    <>
                                        <tr>
                                            <th className="px-4 py-2 font-medium text-left text-gray-900">
                                                Parts Exchange (Trade In)
                                            </th>
                                            <td className="px-4 py-2 font-bold text-gray-900 text-right   flex justify-end">
                                                {formatCurrency(
                                                    String(machine?.trade_in_value || 0),
                                                    machine.currency || machine.country_code || '',
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={1000}>
                                                <hr />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="px-4 py-2 text-left text-gray-900">
                                                Price for the customer (salesprice- part exchange)
                                            </th>
                                            <td className="px-4 py-2 font-bold text-gray-900 text-right">
                                                {formatCurrency(
                                                    (
                                                        grandTotal - (machine?.trade_in_value || 0)
                                                    ).toFixed(2),
                                                    machine.currency || machine.country_code || '',
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="flex justify-start  md:justify-end flex-wrap w-full gap-4">
                    {window.location.href.includes('configuration/library') &&
                        hasChildren &&
                        hasChildren.data &&
                        hasChildren.data.has_children &&
                        (getStatusOfLibraryConfiguration &&
                        getStatusOfLibraryConfiguration.data &&
                        getStatusOfLibraryConfiguration.data.is_private ? (
                            <div className="w-54">
                                <Button
                                    label="Share with team"
                                    variant="primary"
                                    isLoading={changeConfigurationStatusMutation.isLoading}
                                    onClick={() =>
                                        changeConfigurationStatusMutation.mutate({
                                            configurationId: parseInt(machine.configuration_id, 10),
                                            configurationStatus: 0,
                                        })
                                    }
                                />
                            </div>
                        ) : (
                            <div className="w-54">
                                <Button
                                    label="Stop sharing"
                                    variant="primary"
                                    isLoading={changeConfigurationStatusMutation.isLoading}
                                    onClick={() =>
                                        changeConfigurationStatusMutation.mutate({
                                            configurationId: parseInt(machine.configuration_id, 10),
                                            configurationStatus: 1,
                                        })
                                    }
                                />
                            </div>
                        ))}
                    {!orderId && (
                        <>
                            {configuationOrder ? (
                                <div className="w-54">
                                    <Button
                                        label="Edit Configuration"
                                        variant="primary"
                                        onClick={handleEditConfiguration}
                                    />
                                </div>
                            ) : null}
                            <div className="w-54">
                                <Button
                                    label="Duplicate and Edit"
                                    onClick={() => handleDuplicateModal()}
                                    variant="primary"
                                />
                            </div>
                            <div className="w-54">
                                <Button
                                    label="Delete Configuration"
                                    onClick={() => handleDeleteModal()}
                                    variant="primary"
                                />
                            </div>
                            {userExtra.higher_discount_request ? (
                                <div className="w-48">
                                    <Button
                                        label="Higher Discount Request"
                                        onClick={() => handleDiscountModal()}
                                        variant="primary"
                                    />
                                </div>
                            ) : null}
                            {userExtra.lease_process_visible ? (
                                <a
                                    className="w-54"
                                    href={`/lease-calculation/${machine.configuration_id}`}
                                >
                                    <Button label="Lease Calculator" variant="primary" />
                                </a>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
            <DeleteModal
                modal={deleteModal}
                closeModal={() => handleDeleteModal()}
                onConfirm={() =>
                    handleDelete(parseInt(machine.configuration_id, 10), machine.quote_id)
                }
            />
            <DuplicateModal
                modal={duplicateModal}
                closeModal={() => handleDuplicateModal()}
                onConfirm={() => {
                    if (window.location.href.includes('configuration/library')) {
                        navigate(`/configuration/create/${machine.configuration_id}/library`)
                    } else {
                        navigate(`/configuration/create/${machine.configuration_id}`)
                    }
                }}
            />
            <DiscountRequestModal
                modal={higherDiscountModal}
                configurationId={machine.configuration_id}
                closeModal={() => setHigherDiscountModal(false)}
            />
        </div>
    )
}

export default ConfigureDetail
