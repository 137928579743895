import { callAxios } from 'plugins/api'

export const getCompanyRates = () => {
    return callAxios({
        url: `company-rates`,
        method: 'GET',
    })
}

export const getResudialPercentages = () => {
    return callAxios({
        url: `residual-percentage/showAllResudualPercentage`,
        method: 'GET',
    })
}

export const getLeaseForConfiguration = (configurationId: string) => {
    return callAxios({
        url: `lease/getAllLeaseForConfiguration/${configurationId}`,
        method: 'GET',
    })
}

export const updateAddLeaseForConfiguration = (data: any, configurationId: string) => {
    return callAxios({
        url: `lease/addOrUpdateLeaseforConfiguration/${configurationId}`,
        method: 'POST',
        data,
    })
}
