/* eslint-disable react/no-array-index-key */
import classNames from 'classnames'
import appConfig from '../../../app.json'

interface Props {
    hasOverlay?: boolean
}

const Footer = ({ hasOverlay = false }: Props) => {
    const { companyName } = appConfig

    const someQuickMenu = [
        { name: 'Disclaimer', link: '/disclaimer' },
        { name: 'Privacy Statement', link: '/privacy' },
    ]

    const currentTime = new Date()

    return (
        <footer
            className={classNames(
                hasOverlay ? 'bg-blackWithTransparency' : '',
                'bg-white w-full h-[4vh] flex items-center justify-center',
            )}
        >
            <div
                className={
                    hasOverlay
                        ? 'text-[0.5rem] md:text-base flex flex-row justify-center items-center text-white'
                        : ' text-[0.5rem] md:text-base flex flex-row justify-center items-center'
                }
            >
                <div>
                    <p className="whitespace-nowrap text-xs">
                        &copy; {`${currentTime.getFullYear()} Copyright ${companyName}`}
                    </p>
                </div>
                <div className="separator mx-2">|</div>
                {someQuickMenu.map(({ name, link }, index) => (
                    <div className="flex whitespace-nowrap items-center" key={name + index}>
                        <a href={link} className="hover:text-primary text-xs">
                            {name}
                        </a>
                        {index < someQuickMenu.length - 1 && (
                            <div className="separator mx-2">|</div>
                        )}
                    </div>
                ))}
            </div>
        </footer>
    )
}

export default Footer
