import HistoryTab from './components/HistoryTab'

const ConfigurationHistory = () => {
    return (
        <div className="flex flex-col gap-4">
            <div>
                <p className="text-black text-2xl">Configurations History</p>
                <hr className="mt-4 text-black border-[#E0E0E0]" />
            </div>
            <HistoryTab />
        </div>
    )
}

export default ConfigurationHistory
