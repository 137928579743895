/* eslint-disable react/no-unused-prop-types */
/* eslint-disable camelcase */
import { useState } from 'react'

import { FiDownloadCloud } from 'react-icons/fi'
import { BsDownload, BsTrashFill } from 'react-icons/bs'
import { GrMultiple, GrOverview } from 'react-icons/gr'
import { FcAcceptDatabase } from 'react-icons/fc'
import { AiOutlineEdit, AiOutlineShoppingCart, AiOutlineUnorderedList } from 'react-icons/ai'
import { Select } from 'antd'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import ButtonLoader from 'components/buttonLoader'
import DeleteModal from 'components/modal/deleteModal'
import {
    downloadExcelDoc,
    downloadWordDoc,
    downloadZip2,
    // downloadZip,
    // downloadZip2,
} from 'utils/downloadDocuments'
import { errorToast, successToast } from 'utils/toasterUtil'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'modules/auth/context/useAuth'
import classNames from 'classnames'

import SmallLoading from 'components/loading/loading'
import {
    approveQuote,
    deleteQuote,
    // editQuote,
    orderQuote,
    orderQuoteData,
    requestQuoteApproval,
    updateQuoteTemplate,
} from '../queries/quotes'
import QuoteGenericQuestion from './QuoteGenericQuestion'

const size = 'large'

const QuoteDetailActions = (props: {
    quoteDetail: any
    templates: any
    refetch: () => void
    configurationList?: any
}) => {
    const { quoteDetail, templates, refetch } = props

    const {
        user,
        userExtra: {
            need_approval,
            is_dealer,
            project_overview_visible,
            order_form_visibility,
            higher_discount_request,
            role,
        },
    } = useAuth()
    const navigate = useNavigate()

    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [orderModal, setOrderModal] = useState<boolean>(false)

    const [approveRequest, setApproveRequest] = useState<boolean>(false)
    const [requestApproval, setRequestApproval] = useState<boolean>(false)
    const [orderQuoteModal, setOrderQuote] = useState<boolean>(false)
    const [quoteStatusModal, setQuoteStatusModal] = useState<boolean>(false)
    const [, setQuoteOldStatus] = useState<string>(quoteDetail?.status || '')

    // const [, setInputTouched] = useState<boolean>(false)

    const [quoteStatus, setQuoteStatus] = useState<any>({})

    const downloadWordDocAction = useMutation(['quote.download.word'], (payload: string) =>
        downloadWordDoc(payload),
    )

    const downloadExcelDocAction = useMutation(['quote.download.word'], (payload: string) =>
        downloadExcelDoc(payload),
    )

    const downloadZipAction = useMutation(['quote.download.word'], (payload: number) =>
        downloadZip2(payload),
    )

    // const editQuoteAction = useMutation(
    //     ['quoteEdit'],
    //     (quotationName: string) =>
    //         editQuote(quoteDetail.id, {
    //             configurations: configurationList.map((configuration: any) => ({
    //                 label: configuration.name,
    //                 value: configuration.id,
    //             })),
    //             quotation_name: quotationName,
    //             template: quoteDetail.template_id,
    //             user_id: quoteDetail.user_id,
    //             quotation_for: quoteDetail.quotation_for,
    //             status: quoteDetail.status,
    //         }),
    //     {
    //         onSuccess: (data: any) => {
    //             toast.success(
    //                 data?.message || `${quoteDetail.quotation_name} updated successfully`,
    //                 successToast,
    //             )
    //             setInputTouched(false)
    //         },
    //         onError: (error: any) => {
    //             toast(
    //                 error?.message || `Failed to update quote ${quoteDetail.quotation_name}`,
    //                 errorToast,
    //             )
    //         },
    //     },
    // )

    const deleteOrderAction = useMutation(['deleteOrdr'], () => deleteQuote(quoteDetail.id), {
        onSuccess: (data: any) => {
            toast.success(
                data?.message || `${quoteDetail.quotation_name} deleted successfully`,
                successToast,
            )
            navigate('/quotes')
        },
        onError: (error: any) => {
            toast(
                error?.message || `Failed to delete quote ${quoteDetail.quotation_name}`,
                errorToast,
            )
        },
    })

    const approveQuoteRequest = useMutation(
        ['approveQuoteRequest'],
        () => approveQuote(quoteDetail.id, user.id),
        {
            onSuccess: (data: any) => {
                toast(
                    data?.message || ` Quote Approved  quote ${quoteDetail.quotation_name}`,
                    successToast,
                )
                refetch()
            },
            onError: (error: any) => {
                toast(
                    error?.message || `Failed to approved quote ${quoteDetail.quotation_name}`,
                    errorToast,
                )
            },
        },
    )

    const requestQuoteApprove = useMutation(
        ['requestQuoteApprove'],
        () => requestQuoteApproval(quoteDetail.id, user.id),
        {
            onSuccess: (data: any) => {
                toast(
                    data?.message ||
                        `Approval request sent for quote ${quoteDetail.quotation_name}`,
                    successToast,
                )
                refetch()
                setRequestApproval(false)
            },
            onError: (error: any) => {
                toast(error?.message || `Failed to send approval request`, errorToast)
            },
        },
    )

    const orderQuoteRequest = useMutation(['orderQuote'], () => orderQuote(quoteDetail.id), {
        onSuccess: (data: any) => {
            toast(
                data?.message || ` Quote ${quoteDetail.quotation_name} has successfully ordered`,
                successToast,
            )
            navigate('/order')
        },
        onError: (error: any) => {
            toast(
                error?.message || `Failed order the quote ${quoteDetail.quotation_name}`,
                errorToast,
            )
        },
    })

    // const orderOpportunity = useMutation(
    //     ['orderOppurnity'],
    //     (item: number | string) => updateOrderOpportunity(quoteDetail.id, item),
    //     {
    //         onSuccess: (data: any) => {
    //             toast(
    //                 data?.message ||
    //                     ` Order oppurnity for ${quoteDetail.quotation_name} is updated`,
    //             )
    //             refetch()
    //         },
    //         onError: (error: any) => {
    //             toast(
    //                 error?.message ||
    //                     `Failed update order oppurnity of ${quoteDetail.quotation_name}`,
    //                 errorToast,
    //             )
    //         },
    //     },
    // )

    const updateTemplate = useMutation(
        ['template'],
        (value: string) => updateQuoteTemplate(quoteDetail.id, value),
        {
            onSuccess: (data: any) => {
                toast(
                    data?.message || ` Template for ${quoteDetail.quotation_name} has been updated`,
                    successToast,
                )
                refetch()
            },
            onError: (error: any) => {
                toast(
                    error?.message || `Failed to update template of ${quoteDetail.quotation_name}`,
                    errorToast,
                )
            },
        },
    )

    const orderThisQuote = useMutation(
        ['orderThisQuote'],
        () =>
            orderQuoteData(
                {
                    configuration_id: quoteDetail.configuration_id,
                    quote_id: quoteDetail.id,
                    quotation_name: quoteDetail.quotation_name,
                },
                user.id,
            ),
        {
            onSuccess: (data: any) => {
                toast(
                    data?.message || ` Quote ${quoteDetail.quotation_name} is ordered successfully`,
                    successToast,
                )
                navigate('/order')
            },
            onError: (error: any) => {
                toast(
                    error?.message ||
                        `Failed update order oppurnity of ${quoteDetail.quotation_name}`,
                    errorToast,
                )
            },
        },
    )

    // const updateExpectedDeliveryDate = useMutation(
    //     ['expectedDeliveryDate'],
    //     (value: string) => updateExpectedDiscount(quoteDetail.id, value),
    //     {
    //         onSuccess: (data: any) => {
    //             toast(
    //                 data?.message ||
    //                     ` Expected devliery date has been updated for ${quoteDetail.quotation_name}`,
    //             )
    //             refetch()
    //         },
    //         onError: (error: any) => {
    //             toast(
    //                 error?.message ||
    //                     `Failed to update exprected delivery date ${quoteDetail.quotation_name}`,
    //                 errorToast,
    //             )
    //         },
    //     },
    // )

    const handleOrder = () => {
        orderThisQuote.mutate()
    }

    const handleDelete = () => {
        deleteOrderAction.mutate()
        setDeleteModal(false)
        refetch()
    }

    const handleApproveRequest = () => {
        approveQuoteRequest.mutate()
        setApproveRequest(false)
        refetch()
    }

    const handleRequestApproval = async () => {
        requestQuoteApprove.mutate()
    }

    const handleQuoteOrder = () => {
        orderQuoteRequest.mutate()
        setOrderQuote(false)
        refetch()
    }

    // const handleOrderOppurnityChanged = (item: any) => {
    //     orderOpportunity.mutate(item)
    //     refetch()
    // }

    // const handleQuoteStatusChangedOpenModal = (value: any) => {
    //     setQuoteStatusModal(value !== 'ordered')
    //     setQuoteStatus({
    //         quoteId: quoteDetail.id,
    //         oldStatus: quoteDetail.status,
    //         newStatus: value,
    //     })
    // }

    const handleQuoteStatusChangedCloseModal = () => {
        setQuoteStatusModal(false)
        setQuoteStatus({
            quoteId: '',
            oldStatus: '',
            newStatus: '',
        })
        setQuoteOldStatus(quoteDetail.status)
    }

    return (
        <>
            <div className="flex flex-col gap-4 overflow-y-scroll">
                <hr />
                {/* template */}
                <div className="flex flex-col gap-4">
                    <span>Quote Template</span>
                    <Select
                        defaultValue={quoteDetail.template_id}
                        value={quoteDetail.template_id}
                        onChange={(value: string) => updateTemplate.mutate(value)}
                        className="rounded-lg"
                        size={size}
                    >
                        {templates.map((option: any) => (
                            <Select.Option value={option.template_id} key={option.value}>
                                {option.template_name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <hr />

                {/* quote name */}
                {role !== 'Sales management' ? (
                    <>
                        <a
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            href={`/quotes/${quoteDetail.id}/edit`}
                        >
                            <AiOutlineEdit size={25} />
                            <span> Edit Quote </span>
                        </a>
                        <hr />
                    </>
                ) : null}

                {/* <div className="flex flex-col gap-4">
                    <span>Order Opportunity</span>
                    <Select
                        defaultValue={quoteDetail?.order_opportunity || 0}
                        onChange={(option: any) => handleOrderOppurnityChanged(option)}
                        className="rounded-lg"
                        size={size}
                    >
                        {[
                            {
                                label: 0,
                                value: 0,
                            },
                            {
                                label: 10,
                                value: 10,
                            },
                            {
                                label: 30,
                                value: 30,
                            },
                            {
                                label: 70,
                                value: 70,
                            },
                            {
                                label: 90,
                                value: 90,
                            },
                        ].map((option: any) => (
                            <Select.Option value={option.value} key={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <hr />
                <div className="flex flex-col gap-4">
                    <span>Expected Delivery Data</span>
                    {!quoteDetail.expected_delivery_date ? (
                        <DatePicker
                            onChange={(date: any) =>
                                updateExpectedDeliveryDate.mutate(moment(date).format('YYYY-MM-DD'))
                            }
                            size={size}
                            className={roundedClass}
                            style={{
                                width: '100%',
                            }}
                        />
                    ) : (
                        <DatePicker
                            defaultValue={moment(quoteDetail.expected_delivery_date, dateFormat)}
                            className={roundedClass}
                            onChange={(date: any) =>
                                updateExpectedDeliveryDate.mutate(moment(date).format('YYYY-MM-DD'))
                            }
                            size={size}
                            style={{
                                width: '100%',
                            }}
                        />
                    )}
                </div>
                <hr />
                <div className="flex flex-col gap-4">
                    <span>Quote Status</span>
                    <Select
                        value={quoteOldStatus}
                        defaultValue={quoteOldStatus}
                        onChange={(value: string) => handleQuoteStatusChangedOpenModal(value)}
                        className="rounded-lg"
                        size={size}
                    >
                        {[
                            {
                                label: 'pending',
                                value: 'pending',
                            },
                            {
                                label: 'lost',
                                value: 'lost',
                            },
                            {
                                label: 'postponed',
                                value: 'postponed',
                            },
                            {
                                label: 'ordered',
                                value: 'ordered',
                            },
                            {
                                label: 'cancelled',
                                value: 'cancelled',
                            },
                        ].map((option: any) => (
                            <Select.Option value={option.value} key={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <hr /> */}
                <Link
                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                    to={`/quotes/${quoteDetail.id}`}
                >
                    <AiOutlineUnorderedList size={25} />
                    <span>Configuration List</span>
                </Link>
                <hr />
                {higher_discount_request ? (
                    <>
                        <Link
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            to={`/quotes/${quoteDetail.id}/multiple-notifications`}
                        >
                            <GrMultiple size={25} />
                            <span>Multiple Discount Request</span>
                        </Link>
                        <hr />
                    </>
                ) : null}
                {project_overview_visible ? (
                    <>
                        <Link
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            to={`/quotes/${quoteDetail.id}/project-overview`}
                        >
                            <GrOverview size={25} />
                            <span>Project Overview</span>
                        </Link>
                        <hr />
                    </>
                ) : null}
                {/* {is_dealer || !need_approval ? (
                    <>
                        <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => downloadWordDoc(quoteDetail.quotation_file_doc)}
                        >
                            <FiDownloadCloud size={25} />
                            <span>Download Documents</span>
                        </div>
                        <hr />
                        <div
                            className="flex items-center gap-4 cursor-pointer hovr />
                    </>er:text-primary"
                            onClick={() => downloadExcelDoc(quoteDetail.quotation_file_xls)}
                        >
                            <BsDownload size={25} />
                            <span>Download Excel Sheet</span>
                        </div>
                        <hr />
                    </>
                ) : null} */}

                {/* eslint-disable-next-line no-nested-ternary */}
                {quoteDetail.approve_request === 0 ? (
                    // eslint-disable-next-line no-nested-ternary
                    quoteDetail.user_id === user.id ? (
                        !is_dealer && need_approval ? (
                            <>
                                <div
                                    className={classNames(
                                        'flex items-center gap-4 cursor-pointer hover:text-primary',
                                        quoteDetail.approve_pending === 0
                                            ? 'pointer-events-auto'
                                            : 'pointer-events-none',
                                    )}
                                    onClick={() => setRequestApproval(!approveRequest)}
                                >
                                    <FcAcceptDatabase size={25} />
                                    {quoteDetail.approve_pending === 0 ? (
                                        <span>Request Approval</span>
                                    ) : (
                                        <span>Approval Pending</span>
                                    )}
                                </div>
                                <hr />
                            </>
                        ) : null
                    ) : (
                        <>
                            <div
                                className={classNames(
                                    'flex items-center gap-4 cursor-pointer hover:text-primary',
                                )}
                                onClick={() => setApproveRequest(!approveRequest)}
                            >
                                <FcAcceptDatabase size={25} />
                                <span>Approve Request</span>
                            </div>
                            <hr />
                        </>
                    )
                ) : null}

                <div
                    className="flex items-center justify-start gap-4 cursor-pointer hover:text-primary	"
                    onClick={() => setDeleteModal(!deleteModal)}
                >
                    {deleteOrderAction.isLoading ? (
                        <ButtonLoader />
                    ) : (
                        <>
                            <BsTrashFill size={25} />
                            <span>Delete Quote</span>
                        </>
                    )}
                </div>
                <hr />
                {is_dealer || !need_approval ? (
                    <>
                        <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => downloadWordDocAction.mutate(quoteDetail.id)}
                        >
                            {downloadWordDocAction.isLoading && <SmallLoading />}
                            <FiDownloadCloud size={24} />
                            <span>Download Documents</span>
                        </div>
                        <hr />
                        {/* <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => downloadZipAction.mutate(quoteDetail.id)}
                        >
                            {downloadZipAction.isLoading && <SmallLoading />}
                            <FiDownloadCloud size={24} />
                            <span>Download Documents Zip</span>
                        </div>
                        <hr />  */}
                        {!is_dealer && (
                            <>
                                <div
                                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                                    onClick={() => downloadZipAction.mutate(quoteDetail.id)}
                                >
                                    {downloadZipAction.isLoading ? (
                                        <SmallLoading />
                                    ) : (
                                        <FiDownloadCloud size={24} />
                                    )}

                                    <span>Download Quote</span>
                                </div>
                                <hr />
                            </>
                        )}
                        <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() =>
                                downloadExcelDocAction.mutate(quoteDetail.quotation_file_xls)
                            }
                        >
                            {downloadExcelDocAction.isLoading && <SmallLoading />}
                            <BsDownload size={24} />
                            <span>Download Excel Sheet</span>
                        </div>
                        <hr />
                    </>
                ) : quoteDetail.approve_request ? (
                    <>
                        {/* <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() =>
                                downloadWordDocAction.mutate(quoteDetail.quotation_file_doc)
                            }
                        >
                            {downloadWordDocAction.isLoading && <SmallLoading />}
                            <FiDownloadCloud size={24} />
                            <span>Download Documents</span>
                        </div>
                        <hr /> */}
                        {/* <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => downloadZipAction.mutate(quoteDetail.id)}
                        >
                            {downloadZipAction.isLoading && <SmallLoading />}
                            <FiDownloadCloud size={24} />
                            <span>Download Documents Zip</span>
                        </div>
                        <hr /> 
                         <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => downloadZip2(quoteDetail.id)}
                        >
                            <FiDownloadCloud size={24} />
                            <span>Download Combined</span>
                        </div>
                        <hr />  */}
                        <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() =>
                                downloadExcelDocAction.mutate(quoteDetail.quotation_file_xls)
                            }
                        >
                            {downloadExcelDocAction.isLoading && <SmallLoading />}
                            <BsDownload size={24} />
                            <span>Download Excel Sheet</span>
                        </div>
                        <hr />
                    </>
                ) : null}
                {order_form_visibility ? (
                    <div
                        className={classNames(
                            'flex items-center gap-4 cursor-pointer hover:text-primary',
                        )}
                        onClick={() => setOrderModal(!orderModal)}
                    >
                        <AiOutlineShoppingCart size={25} />
                        <span>Order this Quote</span>
                    </div>
                ) : null}
            </div>
            <DeleteModal
                modal={orderModal}
                closeModal={() => setOrderModal(!orderModal)}
                label="Are you sure you want to order this quote?"
                title={`Order ${quoteDetail.quotation_name}`}
                onConfirm={handleOrder}
            />

            <DeleteModal
                modal={deleteModal}
                closeModal={() => setDeleteModal(!deleteModal)}
                label="Are you sure you want to delete this quote?"
                title={`Delete ${quoteDetail.quotation_name}`}
                onConfirm={handleDelete}
            />

            <DeleteModal
                modal={approveRequest}
                closeModal={() => setApproveRequest(!approveRequest)}
                label="Are you sure you want to approve this quote request?"
                title={`Approve ${quoteDetail.quotation_name}`}
                onConfirm={handleApproveRequest}
            />

            <DeleteModal
                modal={requestApproval}
                closeModal={() => setRequestApproval(!requestApproval)}
                label="Are you sure you want to request approval?"
                title={`Request Approval for ${quoteDetail.quotation_name}`}
                onConfirm={handleRequestApproval}
            />

            <DeleteModal
                modal={orderQuoteModal}
                closeModal={() => setOrderQuote(!orderQuote)}
                label="Are you sure you want to order this quote?"
                title={`Order ${quoteDetail.quotation_name}`}
                onConfirm={handleQuoteOrder}
            />
            <QuoteGenericQuestion
                openModal={quoteStatusModal}
                quoteId={quoteDetail.id}
                oldStatus={quoteDetail.status}
                newStatus={quoteStatus.newStatus}
                refetch={() => refetch()}
                callback={() => handleQuoteStatusChangedCloseModal()}
            />
        </>
    )
}

export default QuoteDetailActions
