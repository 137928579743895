/* eslint-disable @typescript-eslint/no-explicit-any */
import { callAxios } from 'plugins/api'
import { useContext, createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LOGIN_ROUTE } from 'route/appRoutes'
import { errorToast, successToast } from '../../../utils/toasterUtil'

export type LoginDataProps = {
    email: string
    password: string
}

export type ResetPasswordProps = {
    email: string
    password: string
    password_confirmation: string
    token: string
}

export type ForgotPasswordProps = {
    email: string
}

const authReducer: any = {
    isUserLoggedIn: false,
    country: {},
    loading: false,
    user: {
        id: '',
        name: '',
        email: '',
    },
    // func
}

const authContext = createContext<any>(authReducer)

const { Provider } = authContext

const loginApi = 'auth/login'
const forgotPasswordApi = 'password/email'
const resetPasswordApi = 'password/reset'

const useAuthProvider = () => {
    const [user, setUser] = useState<any>({} as any)
    const [country, setCountry] = useState<any>({} as any)
    const [userExtra, setUserExtra] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)
    const navigate = useNavigate()

    const logoutUser = () => {
        localStorage.removeItem('CPQ_LOGGED_IN_USER')
        localStorage.removeItem('CPQ_ACCESS_TOKEN')
        localStorage.clear()
        setUser({} as any)
        setCountry({} as any)
        setIsUserLoggedIn(false)
    }

    const loginUser = async (loginData: LoginDataProps) => {
        setLoading(true)
        const res = await callAxios({
            url: loginApi,
            method: 'POST',
            data: loginData,
            isAuth: true,
        })
        if (res && res.status === 200) {
            if (res.data.role_id === 1) {
                window.location.replace(
                    `https://admin.reesink.cpq-nlv.com/?token=${res.data.access_token}`,
                )

                return
            }
            localStorage.setItem('CPQ_LOGGED_IN_USER', JSON.stringify(res?.data?.user))
            localStorage.setItem('CPQ_LOGGED_IN_USER_COUNTRY', JSON.stringify(res?.data.country[0]))
            localStorage.setItem('CPQ_ACCESS_TOKEN', JSON.stringify(res?.data?.access_token))
            localStorage.setItem('CPQ_LOGGED_IN_USER_EXTRA_INFO', JSON.stringify(res?.data))
            setCountry(res?.data.country[0])
            setUser(res?.data?.user)
            setUserExtra(res?.data)
            setIsUserLoggedIn(true)
            toast(res?.data?.message || 'Login Success', successToast)
        } else {
            toast(res?.response?.data?.message || 'Login Failed', errorToast)
            logoutUser()
        }
        setLoading(false)
    }

    const forgotPassword = async (forgotPasswordData: ForgotPasswordProps) => {
        setLoading(true)
        const res = await callAxios({
            url: forgotPasswordApi,
            method: 'POST',
            data: forgotPasswordData,
            isAuth: true,
        })
        if (res && res.status === 200) {
            toast(res?.data?.message || 'Forgot Message Success', successToast)
        } else {
            toast(
                res?.response?.data?.message || "The system doesn't recognize the email",
                errorToast,
            )
        }
        setLoading(false)
    }

    const resetPassword = async (resetPasswordData: ResetPasswordProps) => {
        setLoading(true)
        const res = await callAxios({
            url: resetPasswordApi,
            method: 'POST',
            data: resetPasswordData,
            isAuth: true,
        })
        if (res && res.status === 200) {
            toast(res?.data?.message || 'Reset Success', successToast)
            navigate(LOGIN_ROUTE)
        } else {
            toast('The link is not valid anymore, Please use forgot password again.', errorToast)
        }
        setLoading(false)
    }

    const checkAuthentication = async () => {
        const loginUserInSession = localStorage.getItem('CPQ_LOGGED_IN_USER') || ''
        const loginUserExtraInfo = localStorage.getItem('CPQ_LOGGED_IN_USER_EXTRA_INFO') || ''
        const loginUserInCountry = localStorage.getItem('CPQ_LOGGED_IN_USER_COUNTRY') || ''
        const accessToken = localStorage.getItem('CPQ_ACCESS_TOKEN') || ''
        if (loginUserInSession && accessToken) {
            setUser(JSON.parse(loginUserInSession))
            setUserExtra(JSON.parse(loginUserExtraInfo))
            setCountry(JSON.parse(loginUserInCountry))
            setIsUserLoggedIn(true)
        } else {
            logoutUser()
        }
    }

    useEffect(() => {
        checkAuthentication()
    }, [])

    return {
        user,
        userExtra,
        loading,
        isUserLoggedIn,
        country,

        // functions
        loginUser,
        logoutUser,
        resetPassword,
        forgotPassword,
    }
}

// provider hoc
export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
    const auth = useAuthProvider()
    return <Provider value={auth}>{children}</Provider>
}

export const useAuth = () => useContext(authContext)
