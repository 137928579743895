/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Navigate, RouteObject, useLocation } from 'react-router-dom'

import { useAuth } from 'modules/auth/context/useAuth'
import { AuthLayout, ForgetPassword, Login } from 'modules/auth'
import Splash from 'components/splash'
import DashboardLayout from 'layout/DashboardLayout'
import {
    Bulletins,
    CustomerFeedback,
    Dashboard,
    Discounts,
    Policies,
    Profile,
} from 'modules/dashboard'
import ResetPassword from 'modules/auth/views/resetPassword'
import HomeDashboardLayout from 'modules/dashboard/HomeDashboardLayout'
import Configuration from 'modules/configuration'
import CreateConfiguration from 'modules/configuration/views/createConfiguration'
import Order from 'modules/order'
import OrderLayout from 'modules/order/OrderLayout'
import OrderDetail from 'modules/order/views/OrderDetail'
import OrderForm from 'modules/order/views/OrderForm'
import OrderInfo from 'modules/order/views/OrderInfo'
import ConfigurationHistory from 'modules/history'
import ConfigurationSuccess from 'modules/configuration/views/configurationSuccess'
import DetailConfiguartion from 'modules/configuration/views/detailConfiguration'
import Quotes from 'modules/quotes'
import QuoteLayout from 'modules/quotes/QuoteLayout'
import CreateQuote from 'modules/quotes/views/CreateQuote'
import MultipleDiscountRequest from 'modules/quotes/views/MultipleDiscountRequest'
import ProjectOverview from 'modules/quotes/views/ProjectOverview'
import QuoteDetail from 'modules/quotes/views/QuoteDetail'
import QuoteInfo from 'modules/quotes/views/QuoteInfo'

import { Privacy, Disclaimer } from 'modules/shared'
import LeaseCalculation from 'modules/lease-calculation/LeaseCalculation'

import NotFound from 'modules/errors/NotFound'
import BlankLayout from 'layout/BlankLayout'
import {
    DISCOUNT_HISTORY_ROUTE,
    DISCOUNT_REQUEST_ROUTE,
    PROFILE_ROUTE,
    BULLETIN_ROUTE,
    DISCLAIMER_ROUTE,
    POLICY_ROUTE,
    PRIVACY_ROUTE,
    CUSTOMER_FEEDBACK_ROUTE,
} from './appRoutes'

interface RequireAuthProps {
    children: React.ReactElement
    isUserLoggedIn: boolean
    loading: boolean
    isPublic?: boolean
    isAuth?: boolean
    location: any
}

const RequireAuth = ({
    children,
    isUserLoggedIn,
    isPublic,
    isAuth,
    location,
    loading,
}: RequireAuthProps) => {
    // for route to redirect after checkAuthentication
    const privatePathNavigation: string = location.state ? location.state.from : '/'

    const publicPathNavigation: string = location.state ? location.state.from : '/auth/login'

    if (loading) {
        return <Splash />
    }

    if (isUserLoggedIn && isPublic) {
        return <Navigate to={privatePathNavigation} state={{ from: location }} />
    }

    if (!isUserLoggedIn && isAuth) {
        return <Navigate to={publicPathNavigation} state={{ from: location }} />
    }

    return children
}

const useRouteData = () => {
    const { isUserLoggedIn } = useAuth()
    const location = useLocation()

    const routes: RouteObject[] = [
        {
            path: '',
            element: (
                <RequireAuth
                    isUserLoggedIn={isUserLoggedIn}
                    loading={false}
                    location={location}
                    isPublic={false}
                    isAuth
                >
                    <DashboardLayout />
                </RequireAuth>
            ),
            children: [
                {
                    path: '',
                    element: <HomeDashboardLayout />,
                    children: [
                        {
                            index: true,
                            element: <Dashboard />,
                        },
                        {
                            path: PROFILE_ROUTE,
                            element: <Profile />,
                        },
                        {
                            path: DISCOUNT_REQUEST_ROUTE,
                            element: <Discounts />,
                        },
                        {
                            path: DISCOUNT_HISTORY_ROUTE,
                            element: <Discounts />,
                        },
                        {
                            path: CUSTOMER_FEEDBACK_ROUTE,
                            element: <CustomerFeedback />,
                        },
                        {
                            path: BULLETIN_ROUTE,
                            element: <Bulletins />,
                        },
                        {
                            path: POLICY_ROUTE,
                            element: <Policies />,
                        },
                    ],
                },

                // configuration
                {
                    path: 'configuration',
                    children: [
                        {
                            index: true,
                            element: <Configuration />,
                        },
                        {
                            path: 'create',
                            element: <CreateConfiguration />,
                        },
                        {
                            path: 'create/:configurationId',
                            element: <CreateConfiguration />,
                        },
                        {
                            path: 'create/:configurationId/:from',
                            element: <CreateConfiguration />,
                        },
                        {
                            path: 'edit/:configurationId/:from',
                            element: <CreateConfiguration />,
                        },
                        {
                            path: 'edit/:configurationId',
                            element: <CreateConfiguration />,
                        },
                        {
                            path: 'success',
                            element: <ConfigurationSuccess />,
                        },
                        {
                            path: ':from/:configurationId',
                            element: <DetailConfiguartion />,
                        },
                        {
                            path: ':configurationId',
                            element: <DetailConfiguartion />,
                        },
                    ],
                },

                // orders
                {
                    path: '/order',
                    children: [
                        {
                            index: true,
                            element: <Order />,
                        },
                        {
                            path: ':orderId',
                            element: <OrderLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <OrderDetail />,
                                },
                                {
                                    path: 'order-form',
                                    element: <OrderForm />,
                                },
                                {
                                    path: ':configurationId',
                                    children: [
                                        {
                                            index: true,
                                            element: <OrderInfo />,
                                        },
                                        {
                                            path: 'history',
                                            element: <ConfigurationHistory />,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },

                // quotes
                {
                    path: '/quotes',
                    children: [
                        {
                            index: true,
                            element: <Quotes />,
                        },
                        {
                            path: 'create',
                            element: <CreateQuote />,
                        },
                        {
                            path: ':quoteId',
                            element: <QuoteLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <QuoteDetail />,
                                },
                                {
                                    path: 'edit',
                                    element: <CreateQuote />,
                                },
                                {
                                    path: 'multiple-notifications',
                                    element: <MultipleDiscountRequest />,
                                },
                                {
                                    path: 'project-overview',
                                    element: <ProjectOverview />,
                                },
                                {
                                    path: ':configurationId',
                                    children: [
                                        {
                                            index: true,
                                            element: <QuoteInfo />,
                                        },
                                        {
                                            path: 'history',
                                            element: <ConfigurationHistory />,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },

                // lease calculation
                {
                    path: '/lease-calculation',
                    children: [
                        {
                            path: ':configurationId',
                            element: <LeaseCalculation />,
                        },
                    ],
                },
            ],
        },
        {
            path: 'auth',
            element: (
                <RequireAuth
                    isUserLoggedIn={isUserLoggedIn}
                    loading={false}
                    location={location}
                    isPublic
                    isAuth={false}
                >
                    <AuthLayout />
                </RequireAuth>
            ),
            children: [
                {
                    path: 'login',
                    element: <Login />,
                },
                {
                    path: 'forget-password',
                    element: <ForgetPassword />,
                },
                {
                    path: 'password-reset/:resetToken',
                    element: <ResetPassword />,
                },
            ],
        },
        {
            path: 'password-reset/:resetToken',
            element: (
                <RequireAuth
                    isUserLoggedIn={isUserLoggedIn}
                    loading={false}
                    location={location}
                    isPublic
                    isAuth={false}
                >
                    <AuthLayout />
                </RequireAuth>
            ),
            children: [
                {
                    index: true,
                    element: <ResetPassword />,
                },
            ],
        },
        {
            path: PRIVACY_ROUTE,
            element: <Privacy />,
        },
        {
            path: DISCLAIMER_ROUTE,
            element: <Disclaimer />,
        },
        {
            path: '*',
            element: (
                <BlankLayout>
                    <NotFound />
                </BlankLayout>
            ),
        },
    ]

    return {
        routes,
    }
}

export default useRouteData
