import { callAxios } from 'plugins/api'

const policiesByRoleApi = 'policy-documents/getAllPoliciesAccoringtoRole/'

const getPoliciesByRole = (params: { roleId: number }) => {
    return callAxios({
        url: `${policiesByRoleApi}${params.roleId}`,
        method: 'GET',
    })
}

export const PoliciesQueries = {
    getPoliciesByRole,
}
