interface ImageProps {
    src: string
    altText: string
    objectFit?:
        | 'object-cover'
        | 'object-contain'
        | 'object-fill'
        | 'object-none'
        | 'object-scale-down'
    height?: string
    width?: string
    circle?: boolean
}

const Image = (props: ImageProps) => {
    const { src, altText, objectFit, height, width, circle } = props
    const imageClass = `${height} ${width} ${objectFit || 'object-fit'} h-full w-full`
    const circularImage = `${height} ${width}`
    return <img src={src} alt={altText} className={circle ? circularImage : imageClass} />
}

export default Image
