/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Configure from '../components/details/configure'

import Loading from '../../../components/loading'
import { useAuth } from '../../auth/context/useAuth'
import { ConfigurationQueries } from '../queries/configuration'

const DetailConfiguartion = (props: { configurationId?: string; hideImage?: boolean }) => {
    const { configurationId, orderId, quoteId, from } = useParams()
    const { user, userExtra } = useAuth()
    const [noAccessView, setNoAccessView] = useState<string>('')
    const [machine, setMachineDetail] = useState<any>([])
    const [parts, setParts] = useState<any>([])
    const [adjustment, setAdjustment] = useState<any>([])
    const id = props?.configurationId || configurationId
    const [isOrdered, setIsOrdered] = useState<boolean>(false)

    const { isLoading, isFetching } = useQuery(
        ['currentMachine', configurationId || machine.configuration_id],
        () => {
            if (from === 'library') {
                return ConfigurationQueries.configurationDetailLibrary({
                    configurationId: id || '',
                })
            }
            return ConfigurationQueries.configurationDetail({
                configurationId: id || '',
                userId: user.id,
                is_support: userExtra.is_support,
            })
        },
        {
            enabled: !!id,
            keepPreviousData: true,
            refetchOnMount: true,
            refetchInterval: false,
            onSuccess: (data: any) => {
                setMachineDetail(
                    {
                        ...data?.data.configuration[0],
                        quantity:
                            data?.data.configuration[0]?.only_parts === 1
                                ? 0
                                : data?.data.configuration[0]?.quantity,
                    } || [],
                )
                setAdjustment(data?.data.manual_adjustments || [])
                setParts(data?.data.parts || [])
            },
            onError: (error: any) => {
                setNoAccessView(String(error) || 'No Access')
            },
        },
    )

    useQuery(
        ['checkIfConfigurationIsOrdered', configurationId || machine.configuration_id],
        () =>
            ConfigurationQueries.checkIfConfigurationIsOrdered({
                configurationId: configurationId || machine.configuration_id,
            }),
        {
            refetchInterval: false,
            onSuccess: data => {
                if (
                    data?.data.message.toLowerCase() === 'Configuration not ordered'.toLowerCase()
                ) {
                    setIsOrdered(false)
                } else {
                    setIsOrdered(true)
                }
            },
        },
    )

    if (isLoading || isFetching) {
        return (
            <div className="h-[74vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    if (noAccessView) {
        return (
            <div className="h-[70vh] flex w-full justify-center items-center">
                <h2 className="text-xl font-bold text-gray-800 text-capitalized">{noAccessView}</h2>
            </div>
        )
    }
    return (
        <Configure
            configuration={{
                machine: machine || {},
                parts,
                adjustment,
            }}
            orderId={orderId}
            quoteId={quoteId}
            isOrder={isOrdered}
            hideImage={props?.hideImage || false}
        />
    )
}

export default DetailConfiguartion
