import React from 'react'
import { Modal } from 'react-responsive-modal'

interface AlertModalProps {
    show: boolean
    content: React.ReactElement
    isSubmitting?: boolean
    onConfirm: () => void
    onCancel: () => void
}
const AlertModal = (props: AlertModalProps) => {
    const { show, content, onConfirm, onCancel, isSubmitting } = props
    return (
        <Modal
            center
            open={show}
            onClose={onCancel}
            showCloseIcon={false}
            classNames={{
                root: 'rounded-lg',
                modal: 'rounded-lg',
            }}
        >
            <div className="relative px-4 w-full max-w-md h-full md:h-auto">
                <div className="p-6 pt-0 text-center">
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {content || 'Are you sure you want to delete this configuration?'}
                    </h3>
                    <div className="flex justify-center gap-4">
                        <button
                            onClick={() => onConfirm()}
                            type="button"
                            disabled={isSubmitting}
                            className="bg-primary hover:bg-secondary text-textLight font-bold py-3 px-4 rounded-lg h-12"
                        >
                            {isSubmitting ? 'Loading...' : 'Yes'}
                        </button>
                        <button
                            onClick={() => onCancel()}
                            type="button"
                            disabled={isSubmitting}
                            className="bg-gray-100 hover:bg-gray-300 text-gray-500 font-bold py-3 px-4 rounded-lg"
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AlertModal
