/* eslint-disable react/no-array-index-key */
import { useAuth } from 'modules/auth/context/useAuth'
import { useQuery } from 'react-query'
import {
    MdOutlineBookmarkAdded,
    MdOutlineDocumentScanner,
    MdOutlineFormatListBulleted,
    MdOutlinePayments,
    MdOutlineSettingsSuggest,
} from 'react-icons/md'
import { CiDiscount1 } from 'react-icons/ci'
import { DashboardQueries } from '../queries/dashboard'
import Loading from '../../../components/loading'

const dashboardCardMenu = [
    {
        label: 'Configurations',
        icons: <MdOutlineSettingsSuggest size={48} />,
        key: 'configuration',
        link: '/configuration',
    },
    {
        label: 'Quotes',
        icons: <MdOutlinePayments size={48} />,
        key: 'quote',
        link: '/quotes',
    },
    {
        label: 'Orders',
        icons: <MdOutlineBookmarkAdded size={48} />,
        key: 'order',
        link: '/order',
    },
    {
        label: 'Bulletins',
        icons: <MdOutlineFormatListBulleted size={48} />,
        key: 'bulletins',
        link: '/bulletin',
    },
    {
        label: 'Policy Documents',
        icons: <MdOutlineDocumentScanner size={48} />,
        key: 'policy',
        link: '/policy',
    },
    {
        label: 'Discount Request',
        icons: <CiDiscount1 size={48} />,
        key: 'discount_request',
        link: '/notifications',
    },
]

const Dashboard = () => {
    const {
        user: { id },
    } = useAuth()

    const {
        data: dashboardCounts,
        isLoading,
        isFetching,
    } = useQuery(['getDiscountCount'], () => DashboardQueries.getDashboardCount({ userId: id }), {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
    })

    const getCountValue = (type: string) => {
        switch (type) {
            case 'configuration':
                return dashboardCounts?.data?.configuration || 0
            case 'quote':
                return dashboardCounts?.data?.quote || 0
            case 'order':
                return dashboardCounts?.data?.order || 0
            case 'bulletins':
                return dashboardCounts?.data?.bulletin || 0
            case 'policy':
                return dashboardCounts?.data?.policy_document || 0
            case 'discount_request':
                return dashboardCounts?.data?.discount_request || 0
            default:
                return '0'
        }
    }

    if (isLoading || isFetching) {
        return (
            <div className="flex justify-center items-center h-full">
                <Loading />
            </div>
        )
    }

    return (
        <ul className="grid grid-cols-2 md:grid-cols-4 gap-2">
            {dashboardCardMenu.map(({ label, icons, key, link }) => (
                <a href={link} className="hover:shadow-md" key={key}>
                    <li
                        className="bg-primaryLight border border-white rounded-md p-8  w-full flex flex-col-reverse md:flex-row md:justify-between justify-center items-center  align-middle hover:bg-primary"
                        key={key}
                    >
                        <div className="flex flex-col justify-center gap-8 h-full">
                            <div className="h-8 text-white">{icons}</div>
                            <h3 className="font-medium text-xl text-white hidden md:block">
                                {label}
                            </h3>
                        </div>
                        <h3 className="text-5xl text-white">{getCountValue(key)}</h3>
                        <h3 className="font-medium text-xl text-white block md:hidden">{label}</h3>
                    </li>
                </a>
            ))}
        </ul>
    )
}

export default Dashboard
