/* eslint-disable react/no-array-index-key */

export type Options = {
    label: string
    value: string
}

interface SelectProps {
    options: Options[]
    showPlaceHolder?: boolean
    placeHolderOption?: string
    label?: string
    customStyle?: string
    OptionStyle?: string
    name: string
    value: string
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
    disabled?: boolean
    error?: string
}

const Select = (props: SelectProps) => {
    const {
        options,
        placeHolderOption,
        showPlaceHolder,
        label,
        customStyle,
        name,
        value,
        onChange,
        OptionStyle,
        disabled,
        error,
    } = props

    const outlineInput = 'border-2 border-inputBorder placeholder-placeholderColor'
    const inputClass =
        'block border-1 py-2 rounded-lg focus:outline-none focus:shadow-outline flex items-center w-full'

    return (
        <div className="flex flex-col justify-start gap-1 w-full">
            {label && (
                <label htmlFor={name} className="text-md px-1">
                    {label}
                </label>
            )}
            <select
                name={name}
                className={`${customStyle} ${inputClass} ${outlineInput}`}
                onChange={onChange}
                value={value}
                disabled={disabled}
            >
                {showPlaceHolder && (
                    <option className={OptionStyle}>
                        {placeHolderOption || 'Select Options '}
                    </option>
                )}
                {options.map((option: Options, index: number) => (
                    <option
                        className={OptionStyle}
                        key={`${option.label}-${index}`}
                        value={option.value}
                    >
                        {option.label}
                    </option>
                ))}
            </select>
            {error && <span className="text-red-200 text-sm">{error}</span>}
        </div>
    )
}

export default Select
