interface PanelHeaderProps {
    title: string
    stat?: string
}

const PanelHeader = (props: PanelHeaderProps) => {
    const { title, stat } = props
    return (
        <span className="flex w-full items-center capitalize">
            <span className="font-extrabold text-black">{title}</span>
            <span className="ml-4">{stat}</span>
        </span>
    )
}

export default PanelHeader
