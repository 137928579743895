import { callAxios } from 'plugins/api'

const bulletinsByRoleApi = 'bulletin/getAllBulletinAccordingToRole/'

const getBulletinsByRole = (params: { roleId: number }) => {
    return callAxios({
        url: `${bulletinsByRoleApi}${params.roleId}`,
        method: 'GET',
    })
}

export const BulletinsQueries = {
    getBulletinsByRole,
}
