/* eslint-disable @typescript-eslint/ban-types */
import classNames from 'classnames'
import { IoIosArrowBack } from 'react-icons/io'
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import { RiCloseFill } from 'react-icons/ri'

import { getPillsColor } from 'utils/pills'
import { useAuth } from 'modules/auth/context/useAuth'
import { getAllSelectedTemplate, getQuoteDetail } from './queries/quotes'
import QuoteDetailActions from './components/QuoteDetailActions'
import Loading from '../../components/loading'

const cardBox = 'bg-white drop-shadow-md rounded-md p-6 flex flex-col gap-4 md:gap-8'

type ContextType = {
    quoteConfigurations: [] | null
    quoteDetail: any
    isLoading: boolean
    refetch: () => void
}

const QuoteLayout = () => {
    const [quoteDetail, setQuoteDetail] = useState<any>({})
    const [quoteConfigurations, setquoteConfigurations] = useState([])
    const [color, setColor] = useState<any>({})
    const [openMenu, setOpenMenu] = useState<boolean>(false)

    const { quoteId } = useParams()
    const { user } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const hideSideBar = location.pathname.split('/').includes('project-overview')
    const { userExtra } = useAuth()

    const { isLoading, refetch } = useQuery(
        ['quoteDetail', quoteId],
        () =>
            getQuoteDetail(
                quoteId || '',
                user.id,
                userExtra.is_support,
                userExtra.is_dealer,
                userExtra.is_manager,
                userExtra.role_id,
            ),
        {
            onSuccess: (data: any) => {
                setquoteConfigurations(data?.data?.configurations || [])
                setQuoteDetail(data?.data?.quote[0] || {})
                setColor(getPillsColor(data?.data?.quote[0].status))
            },
        },
    )

    const templateList = useQuery(['templateList'], () => getAllSelectedTemplate(userExtra.role_id))

    if (isLoading) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className=" w-full flex gap-4">
            {!hideSideBar && (
                <div
                    className={classNames(
                        cardBox,
                        openMenu
                            ? 'block absolute z-50 top-[8%] left-5 drop-shadow-2xl border borer-base-400 h-[80vh]'
                            : 'w-1/4 max-w-[22rem] md:block md:relative top-0 left-0 md:drop-shadow-none md:border-none hidden h-auto',
                    )}
                >
                    <div
                        className="flex items-center gap-2 hover:text-primary cursor-pointer"
                        onClick={() => navigate('/quotes')}
                    >
                        <span>
                            <IoIosArrowBack size={24} color="#E30613" />
                        </span>
                        <span className="font-bold">Go Back</span>
                    </div>
                    <div className="flex flex-col justify-start gap-2">
                        <div>
                            <p className="font-bold text-placeholderColor text-left">Quote Name:</p>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <p className="text-extrabold text-lg text-blue-500">
                                {quoteDetail.quotation_name}
                            </p>
                        </div>
                        <div className="flex justify-start gap-4 items-center">
                            <p className="font-bold text-placeholderColor text-left">Quote For: </p>
                            <p className="text-extrabold text-lg text-blue-500">
                                {quoteDetail.quotation_for}
                            </p>
                        </div>
                        <div className="flex flex-col gap-3 mb-4 ">
                            <div className="flex items-center justify-end gap-3">
                                <p className="w-2/3 text-placeholderColor text-left">
                                    Quote Status:
                                </p>
                                <div
                                    className={classNames(
                                        'capitalize font-bold',
                                        color.bg,
                                        color.text,
                                        'rounded-full py-1 text-sm w-3/4 text-center',
                                    )}
                                >
                                    {quoteDetail.status}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-[60vh] overflow-y-scroll">
                        <QuoteDetailActions
                            quoteDetail={quoteDetail}
                            refetch={refetch}
                            configurationList={quoteConfigurations}
                            templates={templateList?.data?.data?.selected_templates || []}
                        />
                    </div>
                </div>
            )}
            <div className={classNames(cardBox, 'flex-1  h-full overflow-y-scroll')}>
                <div
                    className="md:hidden block w-fit"
                    title="open quote sidebar"
                    onClick={() => setOpenMenu(!openMenu)}
                >
                    {openMenu ? <RiCloseFill size={30} /> : <RxHamburgerMenu size={30} />}
                </div>
                <Outlet context={{ quoteConfigurations, quoteDetail, isLoading, refetch }} />
            </div>
        </div>
    )
}

export default QuoteLayout

export const useQuoteLayout = () => {
    return useOutletContext<ContextType>()
}
