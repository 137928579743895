/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'

import Button from '../../../components/button'
import Table from '../../../components/table'
import { IAllUnrepliedRequestedNotification } from '../models/Discounts'
import Input from '../../../components/input'
import { errorToast, successToast } from '../../../utils/toasterUtil'
import { DiscountQueries } from '../queries/discount'
import { useAuth } from '../../auth/context/useAuth'

const TabRowAction = (props: { row: any; refetch: any }) => {
    const { row, refetch } = props
    const { user } = useAuth()
    const [open, setOpen] = useState<boolean>(false)
    const [isAccept, setIsAccept] = useState<boolean>(true)
    const [comment, setComment] = useState<string>('')
    const [discount, setDiscount] = useState<number>(row.requested_discount_percentage)

    useEffect(() => {
        setDiscount(row.requested_discount_percentage)
    }, [row.requested_discount_percentage])

    const onOpenModal = (accept: boolean) => {
        setOpen(true)
        setIsAccept(accept)
    }
    const onCloseModal = () => {
        setComment('')
        setOpen(false)
    }

    const acceptMutation = useMutation(
        ['acceptDiscountRequest'],
        () =>
            DiscountQueries.approveDiscountRequest({
                userId: user.id,
                notificationId: row.notification_id,
                comment,
                discount: discount.toString(),
            }),
        {
            onSuccess: data => {
                toast(data?.message || `Successfully Accepted Discount`, successToast)
                refetch()
                onCloseModal()
            },
            onError: (error: any) => {
                toast(error?.message || `Failed to Accept Discount`, errorToast)
                onCloseModal()
            },
        },
    )

    const rejectMutation = useMutation(
        ['rejectDiscountRequest'],
        () =>
            DiscountQueries.rejectDiscountRequest({
                userId: user.id,
                notificationId: row.notification_id,
                comment,
            }),
        {
            onSuccess: data => {
                toast(data?.message || `Successfully Added feedback`, successToast)
                refetch()
                onCloseModal()
            },
            onError: (error: any) => {
                toast(error?.message || `Failed to Addd feedback`, errorToast)
                onCloseModal()
            },
        },
    )

    return (
        <>
            <div className="flex flex-col md:flex-row items-center w-full gap-2 md:gap-4 p-2">
                <Button label="Approve" variant="primary" onClick={() => onOpenModal(true)} />
                <Button label="Reject" variant="outline" onClick={() => onOpenModal(false)} />
            </div>
            <Modal
                open={open}
                onClose={onCloseModal}
                center
                showCloseIcon={false}
                classNames={{
                    root: 'rounded-lg',
                    modal: 'rounded-lg w-5/6 md:w-2/6',
                }}
            >
                <div className="w-full">
                    {isAccept && (
                        <>
                            <h2 className="font-bold">Approval Message</h2>
                            <div className="mt-5 w-full">
                                <Input
                                    label="Discount"
                                    type="number"
                                    name="discount"
                                    value={discount}
                                    customInputStyles="w-full bg-white cursor-not-allowed"
                                    onChange={(e: any) => setDiscount(e.target.value)}
                                />
                            </div>
                            <div className="mt-5 w-full">
                                <label className="block mb-2">Comment</label>
                                <textarea
                                    rows={8}
                                    name="comment"
                                    className="w-full border-2 border-inputBorder rounded p-2"
                                    onChange={(e: any) => setComment(e.target.value)}
                                />
                            </div>
                            <div className="py-5 flex justify-around items-center">
                                <div className="w-2/5">
                                    <Button
                                        label="OK"
                                        variant="primary"
                                        isLoading={acceptMutation.isLoading}
                                        disabled={acceptMutation.isLoading}
                                        onClick={() => {
                                            acceptMutation.mutate()
                                        }}
                                    />
                                </div>
                                <div className="w-2/5">
                                    <Button
                                        label="Cancel"
                                        variant="outline"
                                        onClick={onCloseModal}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {!isAccept && (
                        <>
                            <h2 className="font-bold">Reject Reason</h2>
                            <textarea
                                rows={8}
                                name="rejectComment"
                                className="w-full mt-5 border-2 border-inputBorder rounded p-2"
                                onChange={(e: any) => setComment(e.target.value)}
                            />
                            <div className="py-5 flex justify-around items-center gap-6">
                                <Button
                                    label="Reject"
                                    variant="primary"
                                    isLoading={rejectMutation.isLoading}
                                    disabled={rejectMutation.isLoading}
                                    onClick={() => {
                                        rejectMutation.mutate()
                                    }}
                                />
                                <Button label="Cancel" variant="outline" onClick={onCloseModal} />
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </>
    )
}

interface IProps {
    data: IAllUnrepliedRequestedNotification[]
    refetch: () => void
}

const DiscountRequest = ({ data, refetch }: IProps) => {
    const columns = [
        {
            name: '',
            height: 'auto',
            width: '300px',
            wrap: true,
            selector: (row: {
                name: string
                requested_discount_percentage: number
                configuration_name: string
            }) => {
                return (
                    <div>
                        <h2 className="font-bold">{row.name}</h2>
                        <span className="pt-5">
                            {row.name} has requested a discount percentage of{' '}
                            {row.requested_discount_percentage} for configuration{' '}
                            {row.configuration_name}
                        </span>
                    </div>
                )
            },
            maxWidth: '50%',
        },
        {
            name: 'Discount Requested',
            center: true,
            cell: (row: { requested_discount_percentage: number }) => {
                return <p>{row.requested_discount_percentage}%</p>
            },
            maxWidth: '25%',
        },

        {
            name: 'Reason for discount requested',
            center: true,
            cell: (row: { requested_comment: string }) => {
                return <p>{row.requested_comment}</p>
            },
            wrap: true,
        },
        {
            name: 'Role Max Discount',
            center: true,
            cell: (row: { max_discount: number }) => {
                return <p>{row.max_discount}%</p>
            },
            maxWidth: '25%',
        },
        {
            name: 'Category Max Discount',
            center: true,
            cell: (row: { max_discount_category: number }) => {
                return <p>{row.max_discount_category}%</p>
            },
            maxWidth: '25%',
        },
        {
            name: '',
            left: true,
            cell: (row: { requested_configuration_id: number }) => {
                return (
                    <a
                        href={`configuration/${row.requested_configuration_id}`}
                        className="flex items-center justify-start gap-3 w-full py-2 px-4 text-sm text-primary"
                    >
                        <span>See Configuration</span>
                    </a>
                )
            },
            maxWidth: '25%',
        },
        {
            name: '',
            center: true,
            cell: (row: any) => <TabRowAction row={row} refetch={refetch} />,
            maxWidth: '20%',
        },
    ]

    return <Table columns={columns} data={data || []} striped pagination totalRows={10} />
}

export default DiscountRequest
