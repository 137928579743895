/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { FeedbackRequest, FeedbackType, FormikFeedback } from '../models/Feedback'
import Select, { Options } from '../../../components/select'
import { FeedbackQueries } from '../queries/feedback'
import Button from '../../../components/button'
import { errorToast, successToast } from '../../../utils/toasterUtil'
import Input from '../../../components/input'
import { useAuth } from '../../auth/context/useAuth'
import Loading from '../../../components/loading'

const CustomerFeedback = () => {
    const { user } = useAuth()
    const [options, setOptions] = useState<Options[] | []>([])
    const { isLoading, isFetching } = useQuery(
        ['getFeedbackType'],
        () => FeedbackQueries.getFeedbackType(),
        {
            refetchInterval: false,
            refetchIntervalInBackground: false,
            onSuccess: (data: any) => {
                setOptions(
                    data.data.map((item: FeedbackType) => {
                        return {
                            label: item.type,
                            value: item.id,
                        }
                    }),
                )
            },
        },
    )
    const saveFeedbackMutation = useMutation(
        ['saveFeedback'],
        (feedbackRequest: FeedbackRequest) => FeedbackQueries.addFeedback(feedbackRequest),
        {
            onSuccess: data => {
                toast(data?.message || `Successfully Added feedback`, successToast)
            },
            onError: (error: any) => {
                toast(error?.message || `Failed to Addd feedback`, errorToast)
            },
        },
    )

    const formik: FormikProps<FormikFeedback> = useFormik<FormikFeedback>({
        initialValues: {
            username: user?.name || '',
            email: user?.email || '',
            feedback: '',
            feedback_type_id: '',
        },
        validationSchema: Yup.object().shape({
            feedback_type_id: Yup.string().required('Feedback Type is Required.'),
            feedback: Yup.string().required('Feedback is Required.'),
        }),
        onSubmit: async (values, { resetForm }: FormikHelpers<FormikFeedback>) => {
            await saveFeedbackMutation.mutateAsync({
                problem_description: values.feedback,
                type_id: values.feedback_type_id,
            })
            if (saveFeedbackMutation.isSuccess) {
                resetForm()
            }
        },
    })

    if (isLoading || isFetching) {
        return (
            <div className="flex justify-center items-center h-full">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-3">
            <h1 className="text-bold text-lg w-full md:w-auto font-bold">Feedback Form</h1>
            <div className="w-full">
                <label className="block mb-1">Name</label>
                <Input
                    value={formik.values.username || ''}
                    type="text"
                    name="username"
                    customInputStyles="w-full bg-white cursor-not-allowed"
                    disabled
                    onChange={formik.handleChange}
                />
            </div>
            <div className="w-full">
                <label className="block mb-1">Email</label>
                <Input
                    value={formik.values.email || ''}
                    type="text"
                    name="email"
                    customInputStyles="w-full bg-white cursor-not-allowed"
                    disabled
                    onChange={formik.handleChange}
                />
            </div>
            <div className="w-full">
                <label className="block mb-1">Feedback Type</label>
                <Select
                    options={options}
                    showPlaceHolder
                    placeHolderOption="Select Type"
                    customStyle="w-full"
                    OptionStyle="text-left"
                    name="feedback_type_id"
                    value={formik.values.feedback_type_id}
                    onChange={formik.handleChange}
                    disabled={
                        Boolean(formik.isSubmitting) || Boolean(saveFeedbackMutation.isLoading)
                    }
                />
                {formik.errors.feedback_type_id && (
                    <p className="text-validation-warning pt-1">{formik.errors.feedback_type_id}</p>
                )}
            </div>
            {/* <div className="w-full">
                <label className="block mb-1">File Attachements</label>
                <div className="flex flex-col justify-start gap-1 w-ful border-2 border-inputBorder placeholder-placeholderColor rounded-lg">
                    <input
                        type="file"
                        name="files"
                        className="w-96 text-center py-2 px-2 w-full focus:outline-none rounded-lg"
                        onChange={(event: any) => {
                            formik.setFieldValue('files', event.currentTarget.files[0])
                        }}
                        disabled={
                            Boolean(formik.isSubmitting) || Boolean(saveFeedbackMutation.isLoading)
                        }yarn 
                    />
                </div>
            </div> */}
            <div className="w-full">
                <label className="block mb-1">Problem Description</label>
                <textarea
                    rows={6}
                    name="feedback"
                    className="w-full border-2 border-inputBorder rounded-lg p-2"
                    value={formik.values.feedback}
                    onChange={formik.handleChange}
                    disabled={
                        Boolean(formik.isSubmitting) || Boolean(saveFeedbackMutation.isLoading)
                    }
                />
                {formik.errors.feedback && (
                    <p className="text-validation-warning pt-1">{formik.errors.feedback}</p>
                )}
            </div>
            <div className="w-full mt-4 flex justify-end">
                <div className="w-1/5">
                    <Button
                        label="Submit Feedback"
                        variant="primary"
                        disabled={
                            Boolean(formik.isSubmitting) || Boolean(saveFeedbackMutation.isLoading)
                        }
                        isLoading={
                            Boolean(formik.isSubmitting) || Boolean(saveFeedbackMutation.isLoading)
                        }
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomerFeedback
