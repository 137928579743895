import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

import { BreadCrumbProvider } from 'hooks/useBreadcrumb'
import i18n from 'plugins/i18n'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'react-toastify/dist/ReactToastify.css'

import { AuthProvider } from 'modules/auth/context/useAuth'
import { ToastContainer } from 'react-toastify'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            retryOnMount: false,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: 'always',
            cacheTime: 10 * 60 * 1000,
        },
        mutations: {
            retry: 1,
        },
    },
})

const app = (
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <AuthProvider>
                    <I18nextProvider i18n={i18n}>
                        <BreadCrumbProvider>
                            <App />
                            <ToastContainer hideProgressBar closeButton={false} autoClose={2000} />
                        </BreadCrumbProvider>
                    </I18nextProvider>
                </AuthProvider>
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
