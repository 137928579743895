/* eslint-disable camelcase,no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */
import { Checkbox, Input } from 'antd'
import Button from 'components/button'
import Loading from 'components/loading'
import Table from 'components/table'
import { extend } from 'lodash'
import { useState } from 'react'
import { AiOutlineCheck, AiTwotoneEdit } from 'react-icons/ai'
import { GrFormClose } from 'react-icons/gr'
import { IoIosArrowBack } from 'react-icons/io'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { errorToast, successToast } from 'utils/toasterUtil'
import {
    downloadProjectOverviewDoc,
    getProjectOverview,
    updateDiscountForConfiguration,
} from '../queries/quotes'
import { useAuth } from '../../auth/context/useAuth'

const roundedClass = 'rounded-lg flex-1'
const size = 'large'

const getTitle = ({
    heading,
    subHeading,
    value,
    onChange,
}: {
    heading: string
    subHeading?: string
    value: boolean
    onChange: any
}) => {
    return (
        <div className="flex flex-col flex-end gap-1 mb-4">
            <p className="text-black text-bold">{heading} </p>
            {subHeading && (
                <>
                    <hr /> <p>{subHeading} Annual Payments</p>
                </>
            )}
            <div
                className="flex justify-center items-center"
                title="Add on export document or not ?"
            >
                <Checkbox
                    checked={value}
                    onChange={onChange}
                    className="flex justify-center items-center text-center"
                />
            </div>
        </div>
    )
}

const EditForm = ({
    onCancel,
    onConfirm,
    onChange,
    value,
}: {
    onCancel: () => void
    onConfirm: () => void
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    value: string
}) => {
    return (
        <div className="flex flex-col justify-center items-center gap-2 py-2">
            <Input
                value={value || ''}
                className={roundedClass}
                size={size}
                onChange={e => onChange(e)}
            />
            <div className="flex items-center gap-2">
                <span
                    className="border border-green-500 p-2 rounded cursor-pointer"
                    onClick={() => onConfirm()}
                >
                    <AiOutlineCheck size={20} className="text-green-500" />
                </span>
                <span
                    className="border border-red-500 p-2 rounded text-red-500 cursor-pointer"
                    onClick={() => onCancel()}
                >
                    <GrFormClose size={20} className="text-red-500" />
                </span>
            </div>
        </div>
    )
}

const ShowTableData = ({
    value,
    isAmount,
    onClick,
}: {
    value: string | number
    isAmount?: boolean
    onClick: () => void
}) => {
    return (
        <div className="flex gap-2 items-center w-full text-center justify-center ">
            <p className="text-black text-bold w-1/3">
                {isAmount
                    ? formatCurrency(
                          (typeof value === 'string' ? parseInt(value, 10) : value).toFixed(2),
                          'GBP',
                      )
                    : value}
            </p>
            <span className="cursor-pointer hover:text-blue-700 w-1/3" onClick={onClick}>
                <AiTwotoneEdit size={25} />
            </span>
        </div>
    )
}

const ProjectOverview = () => {
    const [projectOverview, setProjectOverview] = useState<any>({})
    const { userExtra } = useAuth()
    // const [interest, setInterest] = useState<string>('')

    const [exportQuantity, setExportQuantity] = useState<boolean>(false)
    const [exportRPP, setExportRPP] = useState<boolean>(false)
    const [exportRPPTotal, setExportRPPTotal] = useState<boolean>(false)
    const [exportDiscountPercent, setExportDiscountPercent] = useState<boolean>(false)
    const [exportDiscountPMachine, setExportDiscountPMachine] = useState<boolean>(false)
    const [exportDiscountTotal, setExportDiscountTotal] = useState<boolean>(false)
    const [exportBalanceTotal, setExportBalanceTotal] = useState<boolean>(false)
    const [exportTradeInValue, setExportTradeInValue] = useState<boolean>(false)
    const [exportBalanceToFinance, setExportBalanceToFinance] = useState<boolean>(false)
    const [exportService, setExportService] = useState<boolean>(false)
    const [exportComments, setExportComments] = useState<boolean>(false)
    const [exportLeaseCalculation1, setExportLeaseCalculation1] = useState<boolean>(false)
    const [exportLeaseCalculation2, setExportLeaseCalculation2] = useState<boolean>(false)
    const [exportLeaseCalculation3, setExportLeaseCalculation3] = useState<boolean>(false)
    const [exportLeaseCalculation4, setExportLeaseCalculation4] = useState<boolean>(false)

    const [exportArray, setExportArray] = useState<string[]>(['machine_category'])
    const [interestRate, setInterestRate] = useState<number>(0)

    const [isQuantityEdit, setIsQuantityEdit] = useState<{
        isEdit: boolean
        configurationId: string
        value?: string
    }>({
        isEdit: false,
        configurationId: '',
        value: '',
    })
    const [isDiscountPercentageEdit, setIsDiscountPercentageEdit] = useState<{
        isEdit: boolean
        configurationId: string
        value?: string
    }>({
        isEdit: false,
        configurationId: '',
        value: '',
    })
    const [isDiscountPMachineEdit, setIsDiscountPMachineEdit] = useState<{
        isEdit: boolean
        configurationId: string
        value: string
    }>({
        isEdit: false,
        configurationId: '',
        value: '',
    })
    const [isTradeInValueEdit, setIsTradeInValueEdit] = useState<{
        isEdit: boolean
        configurationId: string
        value: string
    }>({
        isEdit: false,
        configurationId: '',
        value: '',
    })
    const [isServiceRateEdit, setIsServiceRateEdit] = useState<{
        isEdit: boolean
        configurationId: string
        value: string
    }>({
        isEdit: false,
        configurationId: '',
        value: '',
    })
    const [isCommentsEdit, setIsCommentsEdit] = useState<{
        isEdit: boolean
        configurationId: string
        value: string
    }>({
        isEdit: false,
        configurationId: '',
        value: '',
    })

    const { quoteId } = useParams()
    const navigate = useNavigate()

    const { isLoading, refetch } = useQuery(
        'projectOverview',
        () => getProjectOverview(quoteId || ''),
        {
            enabled: !!quoteId,
            onSuccess: data => {
                setProjectOverview(data.data)
            },
            onError: (error: any) => {
                toast(error?.message || 'Failed to fetch project overview', errorToast)
            },
        },
    )

    const leaseCalculationFormula = (
        net_total: number,
        residual_value: number,
        rate: number,
        time: number,
    ) => {
        if (rate === 0 || Number.isNaN(rate)) {
            return 0
        }
        const monthly_payment =
            ((-net_total * (1 + rate / 1200) ** time + residual_value) * rate) /
            1200 /
            (1 - (1 + rate / 1200) ** time)
        return monthly_payment.toFixed(2)
    }

    const exportProjectData = () => {
        projectOverview?.configurations.forEach((element: any) => {
            const totalAmountForHire: number =
                element.quantity *
                (element.machine_total_price -
                    element.discount_amount +
                    (element.discount_percentage * element.machine_total_price * element.quantity) /
                        100)
            const residualValueForHire =
                (element.quantity *
                    (element.machine_total_price -
                        element.discount_amount +
                        (element.discount_percentage * element.machine_total_price) / 100)) /
                10
            const totalAmountForLease: number =
                element.quantity *
                (element.machine_total_price -
                    element.discount_amount +
                    (element.discount_percentage * element.machine_total_price) / 100)
            const residualValueForLease =
                (element.quantity *
                    (element.machine_total_price -
                        element.discount_amount +
                        (element.discount_percentage * element.machine_total_price) / 100)) /
                10
            element.lease_calculation1 = leaseCalculationFormula(
                totalAmountForHire,
                residualValueForHire,
                interestRate,
                24,
            )
            element.lease_calculation2 = leaseCalculationFormula(
                totalAmountForLease,
                residualValueForLease,
                interestRate,
                24,
            )
            element.lease_calculation3 = leaseCalculationFormula(
                totalAmountForHire,
                residualValueForHire,
                interestRate,
                60,
            )
            element.lease_calculation4 = leaseCalculationFormula(
                totalAmountForLease,
                residualValueForLease,
                interestRate,
                60,
            )
            element.discount_total = parseFloat(
                String(
                    parseFloat(String(element.discount_amount * element.quantity)) +
                        parseFloat(
                            String(
                                (element.discount_percentage *
                                    (element.machine_total_price * element.quantity)) /
                                    100,
                            ),
                        ),
                ),
            ).toFixed(0)
            element.balance_total = parseFloat(
                String(
                    element.machine_total_price * element.quantity -
                        element.quantity * element.discount_amount -
                        (element.discount_percentage *
                            element.machine_total_price *
                            element.quantity) /
                            100,
                ),
            ).toFixed(0)
            element.balance_to_finance = element.balance_total - element.trade_in_value
            element.currency_symbol_html = userExtra.country[0].currency_symbol_html
        })
        const params = {
            projectOverviewData: projectOverview,
            export_array: exportArray,
        }
        downloadProjectOverviewDoc(quoteId || '', params)
    }

    const updateConfigurationDiscount = useMutation(
        ['higeherDiscountrequest'],
        (values: any) => updateDiscountForConfiguration(values),
        {
            onSuccess: (data: any) => {
                toast(data?.data?.message || 'Discount Requested Successfully', successToast)
                refetch()
            },
            onError: (error: any) => {
                toast(error?.message || 'Discount Requested Failed', errorToast)
            },
        },
    )

    const handleConfirm = (key: string, row: any, value: string) => {
        const object = {
            comments: row?.comments || null,
            discount_amount: row.discount_amount,
            discount_percentage: row.discount_percentage,
            machine_total_price: row.machine_grand_total_price,
            quantity: row.quantity,
            service_rate: row.service_rate,
            trade_in_value: row.trade_in_value,
            configuration_id: row.configuration_id,
        }
        switch (key) {
            case 'quantity':
                return updateConfigurationDiscount.mutate(
                    extend(object, {
                        machine_total_price:
                            (row.machine_grand_total_price / row.quantity) * parseFloat(value),
                        quantity: value,
                    }),
                )
            case 'discount_percentage':
                return updateConfigurationDiscount.mutate(
                    extend(object, {
                        discount_percentage: value,
                    }),
                )
            case 'discount_amount':
                return updateConfigurationDiscount.mutate(
                    extend(object, {
                        discount_amount: value,
                    }),
                )
            case 'trade_in_value':
                return updateConfigurationDiscount.mutate(
                    extend(object, {
                        trade_in_value: value,
                    }),
                )
            case 'service_rate':
                return updateConfigurationDiscount.mutate(
                    extend(object, {
                        service_rate: value,
                    }),
                )
            case 'comments':
                return updateConfigurationDiscount.mutate(
                    extend(object, {
                        comments: value,
                    }),
                )
            default:
                return null
        }
    }

    const columns = [
        {
            name: 'Machine Description',
            left: true,
            width: '300px',
            wrap: true,
            selector: (row: { parent_category_name: string; machine_category: string }) => {
                return (
                    <div>
                        <p className="text-black text-bold">
                            {row.parent_category_name} {row.machine_category}
                        </p>
                    </div>
                )
            },
        },
        {
            name: getTitle({
                heading: 'Quantity',
                value: exportQuantity,
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('quantity')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'quantity',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'quantity'])
                    }
                    setExportQuantity(e.target.checked)
                },
            }),
            center: true,
            width: '120px',

            cell: (row: { quantity: string; configuration_id: string }) => {
                if (
                    isQuantityEdit.configurationId &&
                    isQuantityEdit.configurationId === row.configuration_id
                ) {
                    return (
                        <EditForm
                            value={isQuantityEdit.value || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setIsQuantityEdit({ ...isQuantityEdit, value: e.target.value })
                            }
                            onConfirm={() => {
                                handleConfirm('quantity', row, isQuantityEdit?.value || '')
                                setIsQuantityEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }}
                            onCancel={() =>
                                setIsQuantityEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }
                        />
                    )
                }
                return (
                    <ShowTableData
                        value={row.quantity}
                        onClick={() =>
                            setIsQuantityEdit({
                                isEdit: true,
                                configurationId: row.configuration_id,
                                value: row.quantity,
                            })
                        }
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: 'RPP Per Machine',
                value: exportRPP,
                onChange: (e: any) => {
                    setExportRPP(e.target.checked)
                    if (e.target.checked && exportArray.includes('machine_total_price')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'machine_total_price',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'machine_total_price'])
                    }
                },
            }),
            left: true,
            width: '180px',
            cell: (row: { machine_total_price: number }) => {
                return (
                    <div>
                        <p className="text-black text-bold">
                            {formatCurrency(row.machine_total_price.toFixed(2), 'GBP')}
                        </p>
                    </div>
                )
            },
        },
        {
            name: getTitle({
                heading: 'RPP Total',
                value: exportRPPTotal,
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('machine_grand_total_price')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'machine_grand_total_price',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'machine_grand_total_price'])
                    }
                    setExportRPPTotal(e.target.checked)
                },
            }),
            left: true,
            width: '120px',
            cell: (row: { machine_total_price: number; quantity: number }) => {
                return (
                    <div>
                        <p className="text-black text-bold">
                            {formatCurrency(
                                (row.machine_total_price * row.quantity).toFixed(2),
                                'GBP',
                            )}
                        </p>
                    </div>
                )
            },
        },
        {
            name: getTitle({
                heading: 'Discount Percent',
                value: exportDiscountPercent,
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('discount_percentage')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'discount_percentage',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'discount_percentage'])
                    }
                    setExportDiscountPercent(e.target.checked)
                },
            }),
            left: true,
            width: '200px',
            cell: (row: { discount_percentage: number; configuration_id: string }) => {
                if (
                    isDiscountPercentageEdit.configurationId &&
                    isDiscountPercentageEdit.configurationId === row.configuration_id
                ) {
                    return (
                        <EditForm
                            value={isDiscountPercentageEdit.value || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setIsDiscountPercentageEdit({
                                    ...isDiscountPercentageEdit,
                                    value: e.target.value,
                                })
                            }
                            onConfirm={() => {
                                if (
                                    isDiscountPercentageEdit &&
                                    isDiscountPercentageEdit?.value &&
                                    isDiscountPercentageEdit?.value < userExtra.max_discount
                                ) {
                                    handleConfirm(
                                        'discount_percentage',
                                        row,
                                        isDiscountPercentageEdit?.value || '',
                                    )
                                    setIsDiscountPercentageEdit({
                                        isEdit: false,
                                        configurationId: '',
                                        value: '',
                                    })
                                } else {
                                    toast.error(
                                        "Discount can't be more than" +
                                            ` ${userExtra.max_discount}`,
                                    )
                                }
                            }}
                            onCancel={() =>
                                setIsDiscountPercentageEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }
                        />
                    )
                }
                return (
                    <ShowTableData
                        value={row.discount_percentage}
                        onClick={() =>
                            setIsDiscountPercentageEdit({
                                isEdit: true,
                                configurationId: row.configuration_id,
                            })
                        }
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: 'Discount Per Machine',
                value: exportDiscountPMachine,
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('discount_amount')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'discount_amount',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'discount_amount'])
                    }
                    setExportDiscountPMachine(e.target.checked)
                },
            }),
            left: true,
            width: '250px',
            cell: (row: { discount_amount: number; configuration_id: string }) => {
                if (
                    isDiscountPMachineEdit.configurationId &&
                    isDiscountPMachineEdit.configurationId === row.configuration_id
                ) {
                    return (
                        <EditForm
                            value={isDiscountPMachineEdit.value || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setIsDiscountPMachineEdit({
                                    ...isQuantityEdit,
                                    value: e.target.value,
                                })
                            }
                            onConfirm={() => {
                                handleConfirm(
                                    'discount_amount',
                                    row,
                                    isDiscountPMachineEdit?.value || '',
                                )
                                setIsDiscountPMachineEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }}
                            onCancel={() =>
                                setIsDiscountPMachineEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }
                        />
                    )
                }
                return (
                    <ShowTableData
                        value={row.discount_amount}
                        onClick={() =>
                            setIsDiscountPMachineEdit({
                                isEdit: true,
                                configurationId: row.configuration_id,
                                value: String(row.discount_amount),
                            })
                        }
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: 'Discount Total',
                value: exportDiscountTotal,
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('discount_total')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'discount_total',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'discount_total'])
                    }
                    setExportDiscountTotal(e.target.checked)
                },
            }),
            left: true,
            width: '250px',
            cell: (row: {
                discount_percentage: number
                machine_total_price: number
                quantity: number
                discount_amount: number
            }) => {
                const discounted: number = row.discount_amount * row.quantity
                const priceWithDiscount: number =
                    (row.discount_percentage * (row.machine_total_price * row.quantity)) / 100
                return (
                    <div>
                        <p className="text-black text-bold">
                            {formatCurrency((discounted + priceWithDiscount).toFixed(2), 'GBP')}
                        </p>
                    </div>
                )
            },
        },
        {
            name: getTitle({
                heading: 'Balance Total',
                value: exportBalanceTotal,
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('balance_total')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'balance_total',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'balance_total'])
                    }
                    setExportBalanceTotal(e.target.checked)
                },
            }),
            left: true,
            width: '250px',
            cell: (row: {
                discount_percentage: number
                machine_total_price: number
                quantity: number
                discount_amount: number
            }) => {
                const totalAmount: number =
                    row.machine_total_price * row.quantity -
                    row.quantity * row.discount_amount -
                    (row.discount_percentage * row.machine_total_price * row.quantity) / 100
                return (
                    <div>
                        <p className="text-black text-bold">
                            {formatCurrency(totalAmount.toFixed(2), 'GBP')}
                        </p>
                    </div>
                )
            },
        },

        {
            name: getTitle({
                heading: 'Trade In Value',
                value: exportTradeInValue,
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('trade_in_value')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'trade_in_value',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'trade_in_value'])
                    }
                    setExportTradeInValue(e.target.checked)
                },
            }),
            left: true,
            width: '250px',
            cell: (row: { trade_in_value: number; configuration_id: string }) => {
                if (
                    isTradeInValueEdit.configurationId &&
                    isTradeInValueEdit.configurationId === row.configuration_id
                ) {
                    return (
                        <EditForm
                            value={isTradeInValueEdit.value || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setIsTradeInValueEdit({
                                    ...isTradeInValueEdit,
                                    value: e.target.value,
                                })
                            }
                            onConfirm={() => {
                                handleConfirm(
                                    'trade_in_value',
                                    row,
                                    isTradeInValueEdit?.value || '',
                                )
                                setIsTradeInValueEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }}
                            onCancel={() =>
                                setIsTradeInValueEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }
                        />
                    )
                }
                return (
                    <ShowTableData
                        value={row.trade_in_value}
                        isAmount
                        onClick={() =>
                            setIsTradeInValueEdit({
                                isEdit: true,
                                configurationId: row.configuration_id,
                                value: String(row.trade_in_value),
                            })
                        }
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: 'Balance To Finance',
                value: exportBalanceToFinance,
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('balance_to_finance')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'balance_to_finance',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'balance_to_finance'])
                    }
                    setExportBalanceToFinance(e.target.checked)
                },
            }),
            left: true,
            width: '250px',
            cell: (row: {
                discount_percentage: number
                machine_total_price: number
                quantity: number
                discount_amount: number
                trade_in_value: number
            }) => {
                const totalAmount: number =
                    row.machine_total_price * row.quantity -
                    row.quantity * row.discount_amount -
                    (row.discount_percentage * row.machine_total_price * row.quantity) / 100 -
                    row.trade_in_value
                return (
                    <div>
                        <p className="text-black text-bold">
                            {formatCurrency(totalAmount.toFixed(2), 'GBP')}
                        </p>
                    </div>
                )
            },
        },
        // lease and hire purchase
        {
            name: getTitle({
                heading: 'Hire Purchase 2 years',
                value: exportLeaseCalculation1,
                subHeading: '1+1 Annual Payments',
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('lease_calculation1')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'lease_calculation1',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'lease_calculation1'])
                    }
                    setExportLeaseCalculation1(e.target.checked)
                },
            }),
            left: true,
            width: '250px',
            cell: (row: {
                discount_percentage: number
                machine_total_price: number
                quantity: number
                discount_amount: number
            }) => {
                const totalAmount: number =
                    row.quantity *
                    (row.machine_total_price -
                        row.discount_amount +
                        (row.discount_percentage * row.machine_total_price * row.quantity) / 100)
                const residualValue =
                    (row.quantity *
                        (row.machine_total_price -
                            row.discount_amount +
                            (row.discount_percentage * row.machine_total_price) / 100)) /
                    10
                const hire2Year = leaseCalculationFormula(
                    totalAmount,
                    residualValue,
                    interestRate,
                    24,
                )
                return (
                    <div>
                        <p className="text-black text-bold">{formatCurrency(hire2Year, 'GBP')}</p>
                    </div>
                )
            },
        },
        {
            name: getTitle({
                heading: 'Lease Hire 2 years	',
                value: exportLeaseCalculation2,
                subHeading: '1+1 Annual Payments',
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('lease_calculation2')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'lease_calculation2',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'lease_calculation2'])
                    }
                    setExportLeaseCalculation2(e.target.checked)
                },
            }),
            left: true,
            width: '250px',
            cell: (row: {
                discount_percentage: number
                machine_total_price: number
                quantity: number
                discount_amount: number
            }) => {
                const totalAmount: number =
                    row.quantity *
                    (row.machine_total_price -
                        row.discount_amount +
                        (row.discount_percentage * row.machine_total_price) / 100)
                const residualValue =
                    (row.quantity *
                        (row.machine_total_price -
                            row.discount_amount +
                            (row.discount_percentage * row.machine_total_price) / 100)) /
                    10
                const hire2Year = leaseCalculationFormula(
                    totalAmount,
                    residualValue,
                    interestRate,
                    24,
                )
                return (
                    <div>
                        <p className="text-black text-bold">{formatCurrency(hire2Year, 'GBP')}</p>
                    </div>
                )
            },
        },
        {
            name: getTitle({
                heading: 'Hire Purchase 5 years	',
                value: exportLeaseCalculation3,
                subHeading: '1+4 Annual Payments',
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('lease_calculation3')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'lease_calculation3',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'lease_calculation3'])
                    }
                    setExportLeaseCalculation3(e.target.checked)
                },
            }),
            left: true,
            width: '250px',
            cell: (row: {
                discount_percentage: number
                machine_total_price: number
                quantity: number
                discount_amount: number
            }) => {
                const totalAmount: number =
                    row.quantity *
                    (row.machine_total_price -
                        row.discount_amount +
                        (row.discount_percentage * row.machine_total_price * row.quantity) / 100)
                const residualValue =
                    (row.quantity *
                        (row.machine_total_price -
                            row.discount_amount +
                            (row.discount_percentage * row.machine_total_price) / 100)) /
                    10
                const hire5Year = leaseCalculationFormula(
                    totalAmount,
                    residualValue,
                    interestRate,
                    60,
                )
                return (
                    <div>
                        <p className="text-black text-bold">{formatCurrency(hire5Year, 'GBP')}</p>
                    </div>
                )
            },
        },
        {
            name: getTitle({
                heading: 'Lease Hire 5 years',
                value: exportLeaseCalculation4,
                subHeading: '1+4 Annual Payments',
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('lease_calculation4')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'lease_calculation4',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'lease_calculation4'])
                    }
                    setExportLeaseCalculation4(e.target.checked)
                },
            }),
            left: true,
            width: '250px',
            cell: (row: {
                discount_percentage: number
                machine_total_price: number
                quantity: number
                discount_amount: number
            }) => {
                const totalAmount: number =
                    row.quantity *
                    (row.machine_total_price -
                        row.discount_amount +
                        (row.discount_percentage * row.machine_total_price) / 100)
                const residualValue =
                    (row.quantity *
                        (row.machine_total_price -
                            row.discount_amount +
                            (row.discount_percentage * row.machine_total_price) / 100)) /
                    10
                const hire2Year = leaseCalculationFormula(
                    totalAmount,
                    residualValue,
                    interestRate,
                    60,
                )
                return (
                    <div>
                        <p className="text-black text-bold">{formatCurrency(hire2Year, 'GBP')}</p>
                    </div>
                )
            },
        },
        // end of lease & hire
        {
            name: getTitle({
                heading: 'Service',
                value: exportService,
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('service_rate')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'service_rate',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'service_rate'])
                    }
                    setExportService(e.target.checked)
                },
            }),
            center: true,
            width: '200px',
            cell: (row: { service_rate: number; configuration_id: string }) => {
                if (
                    isServiceRateEdit.configurationId &&
                    isServiceRateEdit.configurationId === row.configuration_id
                ) {
                    return (
                        <EditForm
                            value={isServiceRateEdit.value || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setIsServiceRateEdit({
                                    ...isServiceRateEdit,
                                    value: e.target.value,
                                })
                            }
                            onConfirm={() => {
                                handleConfirm('service_rate', row, isServiceRateEdit?.value || '')
                                setIsServiceRateEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }}
                            onCancel={() =>
                                setIsServiceRateEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }
                        />
                    )
                }
                return (
                    <ShowTableData
                        value={row.service_rate}
                        isAmount
                        onClick={() =>
                            setIsServiceRateEdit({
                                isEdit: true,
                                configurationId: row.configuration_id,
                                value: String(row.service_rate),
                            })
                        }
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: 'Comments',
                value: exportComments,
                onChange: (e: any) => {
                    if (e.target.checked && exportArray.includes('comments')) {
                        const exportArrayNew = exportArray.filter(
                            (item: string) => item !== 'comments',
                        )
                        setExportArray(exportArrayNew)
                    } else {
                        setExportArray([...exportArray, 'comments'])
                    }
                    setExportComments(e.target.checked)
                },
            }),
            left: true,
            width: '250px',
            cell: (row: { comments: string; configuration_id: string }) => {
                if (
                    isCommentsEdit.configurationId &&
                    isCommentsEdit.configurationId === row.configuration_id
                ) {
                    return (
                        <EditForm
                            value={isCommentsEdit.value || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setIsCommentsEdit({ ...isCommentsEdit, value: e.target.value })
                            }
                            onConfirm={() => {
                                handleConfirm('comments', row, isCommentsEdit?.value || '')
                                setIsCommentsEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }}
                            onCancel={() =>
                                setIsCommentsEdit({
                                    isEdit: false,
                                    configurationId: '',
                                    value: '',
                                })
                            }
                        />
                    )
                }
                return (
                    <ShowTableData
                        value={row.comments}
                        onClick={() =>
                            setIsCommentsEdit({
                                isEdit: true,
                                configurationId: row.configuration_id,
                                value: row.comments,
                            })
                        }
                    />
                )
            },
        },
    ]

    if (isLoading || updateConfigurationDiscount.isLoading) {
        return (
            <div className="w-full h-full grid place-content-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 h-screen">
            <div>
                <div className="flex justify-between items-center">
                    <div className="flex flex-col gap-2 items-start justify-start ">
                        <div
                            className="flex items-center justify-center gap-2 hover:text-primary cursor-pointer"
                            onClick={() => navigate(`/quotes/${quoteId}`)}
                        >
                            <div>
                                <IoIosArrowBack size={24} color="#E30613" />
                            </div>
                            <div className="font-bold">Go Back</div>
                        </div>
                        <div>
                            <p className="text-black text-xl">Project Overview</p>
                        </div>
                    </div>
                    <div className="w-fit">
                        <Button label="Export" onClick={() => exportProjectData()} />
                    </div>
                </div>
                <hr className="mt-4 text-black border-[#E0E0E0]" />
            </div>
            <div className="flex flex-col gap-2">
                {/* <div>
                    <Form.Item
                        name="quotation_name"
                        className="flex flex-col w-[10%] gap-2"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <h5 className="capitalize mb-2">Interest Rate (%)</h5>
                        <Input
                            name="quotation_name"
                            type="number"
                            value={interest}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setInterest(e.target.value)
                            }
                            placeholder="0"
                            size={size}
                            className={roundedClass}
                        />
                    </Form.Item>
                </div> */}
                <div className="flex items-center gap-4">
                    <p className="w-fit">Interest Rate:</p>
                    <div className="w-[5rem]">
                        <Input
                            name="interestRate"
                            value={interestRate}
                            type="number"
                            max={100}
                            min={0}
                            onChange={event =>
                                setInterestRate(
                                    parseInt(event.target.value, 10) >= 0 &&
                                        parseInt(event.target.value, 10) <= 100
                                        ? parseInt(event.target.value, 10)
                                        : interestRate,
                                )
                            }
                        />
                    </div>{' '}
                    %
                </div>
                <Table columns={columns} data={projectOverview?.configurations || ''} />
            </div>
        </div>
    )
}

export default ProjectOverview
