import Table from 'components/table'
import OrderAction from '../component/OrderAction'
import { useOrderLayout } from '../OrderLayout'

const columns = [
    {
        name: 'Name',
        cell: (row: { name: string }) => {
            return (
                <div>
                    <p className="text-black text-bold">{row.name}</p>
                </div>
            )
        },
    },
    {
        name: 'Quantity',
        center: true,
        cell: (row: { quantity: string }) => {
            return (
                <div>
                    <p className="text-black text-bold">{row.quantity}</p>
                </div>
            )
        },
    },
    {
        name: 'Action',
        center: true,
        cell: (row: { id: string }) => <OrderAction configuration={row} />,
        sortable: true,
    },
]

const OrderDetail = () => {
    const { orderConfigurations, isLoading } = useOrderLayout()

    return (
        <div className="h-[100%]">
            <div>
                <p className="text-black text-2xl">Configurations List</p>
                <hr className="mt-4 text-black border-[#E0E0E0]" />
            </div>
            <Table
                columns={columns}
                data={orderConfigurations || []}
                loading={isLoading}
                striped
                pagination={false}
                totalRows={5}
            />
        </div>
    )
}

export default OrderDetail
