import { callAxios } from 'plugins/api'
import { FeedbackRequest } from '../models/Feedback'

const feedbackTypeApi = 'feedback/showAllTypes'
const feedbackApi = 'feedback/create'

const getFeedbackType = () => {
    return callAxios({
        url: `${feedbackTypeApi}`,
        method: 'GET',
    })
}

const addFeedback = (data: FeedbackRequest | null) => {
    const fd = new FormData()
    fd.append('type_id', String(data?.type_id))
    fd.append('problem_description', String(data?.problem_description))

    return callAxios({
        url: `${feedbackApi}`,
        method: 'POST',
        data: fd,
    })
}

export const FeedbackQueries = {
    getFeedbackType,
    addFeedback,
}
