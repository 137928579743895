/* eslint-disable consistent-return */
import Stepper, { StepType } from 'components/stepper'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'

import Button from 'components/button'
import Loading from 'components/loading'
import { filter, map } from 'lodash'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { errorToast, successToast } from '../../../utils/toasterUtil'
import { useAuth } from '../../auth/context/useAuth'
import ReviewConfiguration from '../components/createSteps/ReviewConfiguration'
import ConfigurationInformation from '../components/createSteps/configurationInfo'
import PratSelection from '../components/createSteps/partSelection'
import { ConfigurationQueries, ConfigurationType } from '../queries/configuration'

export interface ManualAdjustmentType {
    manual_adjustment_model_number: string | null | number
    manual_adjustment_name: string | null
    manual_adjustment_type: string | null
    manual_adjustment_note: string | null
    manual_adjustment_price: number
    manual_adjustment_quantity: number
    currency_symbol_html: string | null
}

export interface CreateConfigurationType {
    productId: number
    productConfigurationName: string
    productCategory: string
    onlyParts: boolean
    currency: string
    totalTypes: string[]
    compatibleMachinePartsData: any
    manualAdjustments: ManualAdjustmentType[]
    machineConfigurationDetailData: any
    parentModelNumber: number[]
    negativeParentModelNumber: number[]
    configuration_cost_total_price: number
    netNetNetPurchasePrice: number
    landedCost: number
    retailPrice: number
    netRetailPrice: number
    dataAccessoriesPrice: number
    discountAmount: number
    dealerDiscountAmount: number
    oemDiscount: number
    extraDiscountPercentage: number
    salesMarginPercentage: number
    salesMarginAmount: number
    endUserSalesPrice: number
    quantity: number
    discountPercentage: number
    selectedParts: []
    isLibrary: boolean
    isPrivate: boolean
    user?: string
    trade_in_value?: number
}

const CreateConfiguration = () => {
    const { configurationId, from } = useParams()
    const [searchParams] = useSearchParams()

    const { user, userExtra } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()

    const [tempSelectedParts, setTempSelectedParts] = useState<any>(null)
    const [showDealerFields, setShowDealerFields] = useState<boolean>(!!userExtra.is_dealer)

    const validationSchemaList = [
        Yup.object().shape({
            productId: Yup.number()
                .required('Product is required')
                .notOneOf([0], 'Product is required'),
            productConfigurationName: Yup.string().required('ReviewConfiguration Name is required'),
            user:
                userExtra.role_id === 4 ? Yup.string().required('User is required') : Yup.string(),
        }),
        Yup.object().shape({
            productCategory: Yup.string().required('Product Category is not loaded'),
            manualAdjustments: Yup.array().of(
                Yup.object().shape({
                    manual_adjustment_model_number: Yup.string().required('Model name is required'),
                    manual_adjustment_name: Yup.string().required('Description is required'),
                    // manual_adjustment_note: Yup.string().required('Note is required'),
                    manual_adjustment_price: Yup.number().required('Price is required'),
                    manual_adjustment_quantity: Yup.number().required('Quantity is required'),
                }),
            ),
        }),
    ]

    const createConfiguration = useMutation(
        ['createConfiguration'],
        (configurationData: ConfigurationType) =>
            ConfigurationQueries.createConfigurations({ configuration: configurationData }),
        {
            onSuccess: (data: any) => {
                toast(data?.data?.message || 'Configuration Created Successfully', successToast)
                navigate('/configuration/success')
            },
            onError: (error: any) => {
                toast(error?.message || 'Something went wrong', errorToast)
            },
        },
    )

    const checkConfigurationIfExist = useMutation(
        ['checkConfigurationIfExist'],
        (configurationName: string) =>
            ConfigurationQueries.checkIfConfigurationNameExist({ configurationName }),
    )

    const editLibraryConfiguration = useMutation(
        ['editLibraryConfiguration'],
        (configurationData: ConfigurationType) =>
            ConfigurationQueries.editLibraryConfiguration({
                configurationId: parseInt(configurationId || '0', 10),
                configuration: configurationData,
            }),
        {
            onSuccess: (data: any) => {
                toast(data?.data?.message || 'Configuration Edited Successfully', successToast)
                navigate('/configuration/success')
            },
            onError: (error: any) => {
                toast(error?.message || 'Something went wrong', errorToast)
            },
        },
    )

    const editConfiguration = useMutation(
        ['editConfiguration'],
        (configurationData: ConfigurationType) =>
            ConfigurationQueries.editConfiguration({
                configurationId: parseInt(configurationId || '0', 10),
                configuration: configurationData,
            }),
        {
            onSuccess: (data: any) => {
                const quoteId = searchParams.get('quoteId')
                toast(data?.data?.message || 'Configuration Edited Successfully', successToast)
                navigate(
                    quoteId && quoteId !== null
                        ? `/configuration/success?quoteId=${quoteId}`
                        : '/configuration/success',
                )
            },
            onError: (error: any) => {
                toast(error?.message || 'Something went wrong', errorToast)
            },
        },
    )

    const [step, setStep] = useState<number>(0)

    const formik: FormikProps<CreateConfigurationType> = useFormik({
        initialValues: {
            productId: 0,
            productCategory: '',
            productConfigurationName: '',
            currency: '',
            totalTypes: [],
            onlyParts: false,
            compatibleMachinePartsData: [],
            manualAdjustments: [],
            machineConfigurationDetailData: [],
            parentModelNumber: [],
            negativeParentModelNumber: [],
            configuration_cost_total_price: 0,
            landedCost: 0,
            retailPrice: 0,
            netRetailPrice: 0,
            dataAccessoriesPrice: 0,
            discountAmount: 0,
            dealerDiscountAmount: 0,
            oemDiscount: 0,
            extraDiscountPercentage: 0,
            salesMarginPercentage: 0,
            salesMarginAmount: 0,
            endUserSalesPrice: 0,
            quantity: 1,
            discountPercentage: 0,
            selectedParts: [],
            isLibrary: false,
            isPrivate: true,
            netNetNetPurchasePrice: 0,
            user: userExtra.is_support ? '' : user.id,
            trade_in_value: 0,
        },
        validationSchema: validationSchemaList[step],
        onSubmit: async (values, { setSubmitting }: FormikHelpers<CreateConfigurationType>) => {
            if (step === 2) {
                // final change need to make it on
                const params: ConfigurationType = {
                    user_id: formik.values.user,
                    configuration_name: values.productConfigurationName,
                    only_parts: values.onlyParts,
                    machine_name: values.productConfigurationName,
                    machine_total_price: values.netRetailPrice,
                    machine_category: values.productId,
                    machine_category_year_price_count:
                        values.machineConfigurationDetailData[0].year_price_count,
                    machine_category_cost_year_price_count:
                        formik.values.machineConfigurationDetailData[0].cost_year_price_count,
                    discount_amount: values.discountAmount,
                    dealer_discount_amount: values.dealerDiscountAmount,
                    oem_discount: values.oemDiscount,
                    extra_discount: values.extraDiscountPercentage,
                    sales_margin: values.salesMarginPercentage,
                    sales_margin_amount: values.salesMarginAmount,
                    end_user_sales_price: values.endUserSalesPrice,
                    landed_cost: parseFloat(
                        (
                            (formik.values.netNetNetPurchasePrice -
                                formik.values.quantity *
                                    formik.values.configuration_cost_total_price -
                                (formik.values.quantity *
                                    formik.values.configuration_cost_total_price *
                                    formik.values.landedCost) /
                                    100) /
                            formik.values.netNetNetPurchasePrice
                        ).toFixed(2),
                    ),
                    quantity: values.quantity,
                    discount_percentage: values.discountPercentage,
                    selected_parts: values.selectedParts,
                    manual_adjustments: values.manualAdjustments,
                    is_library: values.isLibrary,
                    is_private: values.isPrivate,
                    country_id: user.country_id,
                    trade_in_value: values?.trade_in_value || 0,
                }
                if (configurationId) {
                    if (from?.includes('library') && location.pathname.includes('edit')) {
                        editLibraryConfiguration.mutate(params)
                    } else if (location.pathname.includes('edit')) {
                        editConfiguration.mutate(params)
                    } else {
                        createConfiguration.mutate(params)
                    }
                } else {
                    createConfiguration.mutate(params)
                }
            } else if (step === 1) {
                if (formik.values.parentModelNumber.length > 0) {
                    setStep(step + 1)
                } else if (formik.values.negativeParentModelNumber.length > 0) {
                    if (
                        !formik.values.negativeParentModelNumber.find(n =>
                            formik.values.selectedParts.some(
                                (s: any) => s.machine_part_model_number === n,
                            ),
                        )
                    ) {
                        setStep(step + 1)
                    } else {
                        toast(
                            `Please Remove ${String(
                                formik.values.negativeParentModelNumber,
                            )} from selection`,
                            errorToast,
                        )
                    }
                } else {
                    setStep(step + 1)
                }
            } else {
                checkConfigurationIfExist.mutate(formik.values.productConfigurationName)
            }
            setSubmitting(false)
        },
    })

    useEffect(() => {
        if (checkConfigurationIfExist.data) {
            if (
                !configurationId &&
                checkConfigurationIfExist.data?.data?.configuration_name_exists
            ) {
                formik.setFieldError(
                    'productConfigurationName',
                    'The configuration name is already in use, please choose a different name.',
                )
            } else {
                setStep(step + 1)
            }
        }
    }, [checkConfigurationIfExist.data])

    const { isLoading, isRefetching } = useQuery(
        ['configurationDetail', configurationId],
        () => {
            if (from?.includes('library')) {
                return ConfigurationQueries.getLibraryConfigurationById({
                    configurationId: configurationId || '',
                    edit: true,
                    role: userExtra.role,
                })
            }
            return ConfigurationQueries.configurationDetail({
                configurationId: configurationId || '',
                edit: true,
                is_support: userExtra.is_support,
                userId: user.id,
            })
        },
        {
            enabled: !!configurationId,
            refetchInterval: false,
            onSuccess: (data: any) => {
                formik.setFieldValue(
                    'productId',
                    data.data.configuration[0].cost_machine_category_id,
                )
                formik.setFieldValue('onlyParts', data.data.configuration[0].only_parts === 1)
                formik.setFieldValue('manualAdjustments', data.data.manual_adjustments, true)
                formik.setFieldValue('productConfigurationName', data.data.configuration[0].name)
                formik.setFieldValue('discountAmount', data.data.configuration[0].discount_amount)
                formik.setFieldValue(
                    'dealerDiscountAmount',
                    data.data.configuration[0].dealer_discount_amount,
                )
                formik.setFieldValue('oemDiscount', data.data.configuration[0].oem_discount)
                formik.setFieldValue(
                    'extraDiscountPercentage',
                    data.data.configuration[0].extra_discount,
                )
                formik.setFieldValue(
                    'salesMarginPercentage',
                    data.data.configuration[0].sales_margin,
                )
                formik.setFieldValue(
                    'salesMarginAmount',
                    data.data.configuration[0].sales_margin_amount,
                )
                formik.setFieldValue(
                    'endUserSalesPrice',
                    data.data.configuration[0].end_user_sales_price,
                )
                formik.setFieldValue('landed_cost', data.data.configuration[0].landed_cost)
                formik.setFieldValue('quantity', data.data.configuration[0].quantity)
                formik.setFieldValue('trade_in_value', data.data.configuration[0].trade_in_value)
                formik.setFieldValue(
                    'discountPercentage',
                    data.data.configuration[0].discount_percentage,
                )
                setTempSelectedParts(data.data.parts)
            },
        },
    )

    useEffect(() => {
        if (tempSelectedParts && formik.values.compatibleMachinePartsData) {
            const selectedParts = tempSelectedParts.map((selectedPart: any) =>
                formik.values.compatibleMachinePartsData.find((compatiblePart: any) => {
                    if (
                        compatiblePart.machine_part_model_number ===
                        selectedPart.machine_part_model_number
                    ) {
                        const tempPart = compatiblePart
                        tempPart.quantity = selectedPart.quantity
                        return tempPart
                    }
                    return null
                }),
            )
            formik.setFieldValue('selectedParts', selectedParts)
        }
    }, [tempSelectedParts, formik.values.compatibleMachinePartsData])

    const { isLoading: machineConfigurationDetailIsLoading, data: machineConfigurationDetailData } =
        useQuery(
            ['getMachineConfiguration', formik.values.productId],
            () =>
                ConfigurationQueries.getDetailById({
                    configurationId: formik.values.productId || 0,
                }),
            {
                enabled: !!formik.values.productId,
                refetchOnMount: !configurationId,
                retryOnMount: !configurationId,
                refetchIntervalInBackground: false,
                retry: false,
                refetchInterval: false,
                refetchOnReconnect: false,
                staleTime: undefined,
                onSuccess: (data: any) => {
                    formik.setFieldValue('machineConfigurationDetailData', data.data)
                    formik.setFieldValue('productCategory', data.data[0].machine_category)
                    formik.setFieldValue('currency', data.data[0].currency)
                },
            },
        )

    const { isLoading: configurationSequenceIsLoading, data: configurationSequenceData } = useQuery(
        ['getConfigurationSequence', formik.values.productId],
        () =>
            ConfigurationQueries.getSequenceByID({
                configurationId: formik.values.productId || 0,
            }),
        {
            retry: false,
            refetchOnMount: !configurationId,
            retryOnMount: !configurationId,
            refetchIntervalInBackground: false,
            refetchInterval: false,
            refetchOnReconnect: false,
            enabled: !!formik.values.productId,
            keepPreviousData: true,
            staleTime: undefined,
        },
    )

    const finalizeMachineParts = async (machineParts: []) => {
        let types: string[] = []

        machineParts.forEach((element: any) => {
            types.push(element.machine_part_type.toLowerCase())
        })

        const set: Set<string> = new Set(types)
        types = Array.from(set)
        const sequence = configurationSequenceData?.data

        if (sequence.length === 0 || sequence.length < types.length) {
            types.sort((a, b) => {
                if (sequence.indexOf(a) === -1) {
                    return types.length
                }
                return sequence.indexOf(a) - sequence.indexOf(b)
            })
        } else {
            types.sort((a, b) => {
                return sequence.indexOf(a) - sequence.indexOf(b)
            })
        }

        return types
    }

    const { isLoading: compatibleMachinePartsIsLoading } = useQuery(
        [
            'getCompatibleMachineParts',
            formik.values.productId,
            machineConfigurationDetailData,
            formik.values.onlyParts,
        ],
        () =>
            ConfigurationQueries.getCompatibleMachinePartsWithQuantity({
                configurationId: formik.values.productId || 0,
                countryId: machineConfigurationDetailData?.data[0].country_id,
            }),
        {
            enabled: !!formik.values.productId && !!machineConfigurationDetailData,
            refetchOnMount: !configurationId,
            refetchIntervalInBackground: false,
            refetchInterval: false,
            retryOnMount: !configurationId,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: undefined,
            onSuccess: async data => {
                const types = await finalizeMachineParts(data.data)
                formik.setFieldValue('totalTypes', types)
                const parts = map(data.data, (item: any) => {
                    return {
                        ...item,
                        quantity: formik.values.onlyParts ? 0 : item.quantity,
                    }
                })
                const includedParts = map(data.data, (item: any) => {
                    if (item.is_included === 1 && item.quantity > 0) {
                        return item
                    }
                    return null
                })
                formik.setFieldValue(
                    'selectedParts',
                    configurationId || formik.values.onlyParts
                        ? []
                        : filter(includedParts, item => item !== null),
                )
                formik.setFieldValue('compatibleMachinePartsData', parts)
            },
        },
    )

    const changeStep = (item: number) => {
        setStep(item)
    }

    const steppers: StepType[] = [
        {
            id: 0,
            step: 0,
            label: 'Configurations Information',
            component: (
                <ConfigurationInformation
                    formik={formik}
                    configurationId={configurationId ? parseInt(configurationId, 10) : undefined}
                />
            ),
        },
        {
            id: 1,
            step: 1,
            label: 'Parts Selection',
            component: (
                <PratSelection
                    formik={formik}
                    compatibleMachinePartsIsLoading={compatibleMachinePartsIsLoading}
                    machineConfigurationDetailIsLoading={machineConfigurationDetailIsLoading}
                    configurationSequenceIsLoading={configurationSequenceIsLoading}
                    configurationLoading={isLoading || isRefetching}
                />
            ),
        },
        {
            id: 2,
            step: 2,
            label: 'Review & Confirmation',
            component: <ReviewConfiguration formik={formik} showDealerFields={showDealerFields} />,
        },
    ]

    if (
        configurationSequenceIsLoading ||
        machineConfigurationDetailIsLoading ||
        isLoading ||
        isRefetching
    ) {
        return (
            <div className="w-full h-full grid place-content-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="w-full bg-white px-4 py-4 rounded-md flex flex-col gap-4">
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4">
                <Stepper steppers={steppers} activeStep={steppers[step]} />
                <div className="flex w-full justify-end items-start  gap-4 md:flex">
                    {step > 0 && (
                        <div className="w-52">
                            <Button
                                label="Back"
                                variant="outline"
                                onClick={() => changeStep(step - 1)}
                            />
                        </div>
                    )}
                    {step !== 2 ? (
                        <div className="w-52">
                            <Button
                                label="Next Step"
                                onClick={() => formik.handleSubmit()}
                                variant="primary"
                            />
                        </div>
                    ) : (
                        <div className="w-52">
                            <Button
                                label="Confirm"
                                onClick={() => formik.handleSubmit()}
                                variant="outline"
                            />
                        </div>
                    )}
                    {/* <div className="w-52">
                        <Button
                            label="Configuration List"
                            variant="outline"
                            onClick={() => navigate('/configuration')}
                        />
                    </div> */}
                    {userExtra.change_view !== 0 && step === 2 && (
                        <div className="w-32">
                            <Button
                                label="Change View"
                                variant="primary"
                                onClick={() => setShowDealerFields(!showDealerFields)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="h-full pb-2 overflow-auto flex flex-col justify-between">
                <div className="scrolling px-2 py-0">
                    {steppers[step]?.component || <p>Error in stepper</p>}
                </div>
                <div
                    className={`flex ${
                        step > 0 ? 'justify-between' : 'justify-end'
                    } align-center flex-wrap md:flex-nowrap`}
                >
                    {step > 0 && (
                        <div className="w-52">
                            <Button
                                label="Back"
                                variant="outline"
                                onClick={() => changeStep(step - 1)}
                            />
                        </div>
                    )}
                    <div className="flex justify-end gap-8 flex-wrap md:flex-nowrap">
                        <div className="w-52">
                            <Button
                                label="Cancel"
                                variant="ghost"
                                onClick={() => {
                                    formik.resetForm()
                                    navigate('/configuration')
                                }}
                            />
                        </div>
                        {step !== 2 ? (
                            <div className="w-52">
                                <Button
                                    label="Next Step"
                                    onClick={() => {
                                        formik.handleSubmit()
                                    }}
                                    variant="primary"
                                />
                            </div>
                        ) : (
                            <div className="w-52">
                                <Button
                                    label="Confirm"
                                    onClick={() => formik.handleSubmit()}
                                    variant="primary"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateConfiguration
