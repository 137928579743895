/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import Button from 'components/button'
import Loading from 'components/loading'
import { extend } from 'lodash'
import ConfigureDetail from 'modules/configuration/components/details/configureDetail'
import { ConfigurationQueries } from 'modules/configuration/queries/configuration'
import { useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useQueries } from 'react-query'
import { useParams } from 'react-router-dom'
import LeaseCalculator from './components/LeaseCalculator'
import { getCompanyRates, getLeaseForConfiguration, getResudialPercentages } from './queries/lease'
import { useAuth } from '../auth/context/useAuth'

export type LeaseType = {
    lease_duration: number
    configuration_quantity: number
    net_total: string
    interest_rate: number
    residual_value: string
    service_cost: number
    price_per_month: string
    configuration_id?: string
}
const LeaseCalculation = () => {
    const { configurationId } = useParams()
    const { user } = useAuth()
    const [machine, setMachineDetail] = useState<any>([])
    const [companyRates, setCompanyRates] = useState<any>([])
    const [resudialPercentage, setResudialPercentage] = useState<any>([])
    const [leaseConfiguration, setLeaseConfiguration] = useState<any>([])

    const leaseInfo = useQueries([
        {
            queryKey: ['companyRates'],
            queryFn: () => getCompanyRates(),
            onSuccess: (data: any) => {
                setCompanyRates(data?.data)
            },
        },
        {
            queryKey: ['resudialPercentage'],
            queryFn: () => getResudialPercentages(),
            onSuccess: (data: any) => {
                const resudialPercentageOptions = data?.data.map((residual: { year: number }) =>
                    extend(residual, {
                        year: residual.year * 12,
                    }),
                )
                setResudialPercentage(resudialPercentageOptions)
            },
        },
        {
            queryKey: ['leaseConfiguration', configurationId],
            queryFn: () => getLeaseForConfiguration(configurationId || ''),
            onSuccess: (data: any) => {
                setLeaseConfiguration(data?.data)
            },
        },
        {
            queryKey: ['currentConfiguration', configurationId],
            queryFn: () =>
                ConfigurationQueries.configurationDetail({
                    configurationId: configurationId || '',
                    userId: user.id,
                }),
            onSuccess: async (data: any) => {
                await setMachineDetail(data?.data.configuration[0] || [])
            },
        },
    ])

    const isLoading = leaseInfo.some((result: { isLoading: boolean }) => result.isLoading)
    const isFetching = leaseInfo.some((result: { isFetching: boolean }) => result.isFetching)

    if (isLoading || isFetching) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-8 mt-4 mr-4">
            <div className="flex flex-start">
                <a href={`/configuration/${configurationId}`}>
                    <Button label="Configuration Detail" leftIcon={<IoIosArrowBack size={20} />} />
                </a>
            </div>
            <ConfigureDetail machine={machine} hideImage={false} orderId />
            <LeaseCalculator
                configurationId={configurationId || ''}
                companyRates={companyRates || []}
                machine={machine}
                leaseConfiguration={leaseConfiguration || []}
                resudialPercentage={resudialPercentage}
            />
        </div>
    )
}

export default LeaseCalculation
