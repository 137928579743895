import { FormikHelpers, FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import Input from 'components/input'
import Button from 'components/button'
import { useParams } from 'react-router-dom'
import { LOGIN_ROUTE } from 'route/appRoutes'
import { ResetPasswordProps, useAuth } from '../context/useAuth'
import useTranslationData from '../../translation/hooks/useTranslationData'

const ResetPasswordForm = () => {
    const { resetPassword, loading } = useAuth()

    const { resetToken } = useParams()

    const {
        translation: { t },
    } = useTranslationData()

    const formik: FormikProps<ResetPasswordProps> = useFormik<ResetPasswordProps>({
        initialValues: {
            email: '',
            password: '',
            password_confirmation: '',
            token: resetToken || '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .required('Password is required')
                .min(8, 'Password must have atleast 8 characters')
                .matches(/^(?=.*[a-z])/, 'Password must include lowercase letter')
                .matches(/^(?=.*[A-Z])/, 'Password must include uppercase letter')
                .matches(/^(?=.*[0-9])/, 'Password must include digit')
                .matches(
                    /^(?=.*[!@#$%^&*])/,
                    'Password must include special character (!, @, #, $, %, ^, &, *)',
                ),
            email: Yup.string().email('Enter the valid email').required('Email is required'),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is Required'),
        }),
        onSubmit: async (values, { setSubmitting }: FormikHelpers<ResetPasswordProps>) => {
            await resetPassword(values)
            setSubmitting(false)
        },
    })

    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

    return (
        <div className="bg-transparent rounded-md flex flex-col gap-4 w-auto flex-1">
            <div>
                <h1 className="text-2xl text-center font-medium">{t('api.reset_password')}</h1>
            </div>
            <div>
                <Input
                    type="text"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name="email"
                    label="Email"
                />
                {formik.touched.email && formik.errors.email && (
                    <p className="text-validation-warning">{formik.errors.email}</p>
                )}
            </div>
            <div>
                <Input
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    name="password"
                    label="Password"
                    rightContent={
                        <span
                            onClick={() => setShowPassword(!showPassword)}
                            className="cursor-pointer"
                        >
                            {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                        </span>
                    }
                />
                {formik.touched.password && formik.errors.password && (
                    <p className="text-validation-warning">{formik.errors.password}</p>
                )}
            </div>
            <div>
                <Input
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    name="password_confirmation"
                    label="Confirm Password"
                    rightContent={
                        <span
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            className="cursor-pointer"
                        >
                            {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                        </span>
                    }
                />
                {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                    <p className="text-validation-warning">{formik.errors.password_confirmation}</p>
                )}
            </div>
            <div className="text-right">
                <a href={LOGIN_ROUTE} className="link text-sm">
                    {t('api.back_to_login')}
                </a>
            </div>
            <div className="my-4">
                <Button
                    label={t('api.reset_password')}
                    isLoading={Boolean(loading) || Boolean(formik.isSubmitting)}
                    onClick={() => formik.handleSubmit()}
                />
            </div>
        </div>
    )
}

export default ResetPasswordForm
