import { extend } from 'lodash'
import { callAxios } from 'plugins/api'
import { getUserRole, UserRole } from 'utils/userRole'

export const getAllOrder = (
    userId: number,
    isSupport: boolean,
    isDealer: boolean,
    isManager: boolean,
    roleId: number,
) => {
    const role: UserRole = getUserRole(isSupport, isDealer, isManager, roleId)
    if (role === 'sales-support') {
        return callAxios({
            url: `sales-support/getAllOrdersSalesSupport/${userId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `order/user/${userId}`,
        method: 'GET',
    })
}

export const getAllTeamOrder = (userId: number) => {
    return callAxios({
        url: `user-hierarchy/showChildrenOrders/${userId}`,
        method: 'GET',
    })
}

export const getOrderDetail = ({
    orderId,
    userId,
    isSupport,
    isDealer,
    isManager,
    roleId,
}: {
    orderId: string
    userId: number
    isSupport: boolean
    isDealer: boolean
    isManager: boolean
    roleId: number
}) => {
    const role: UserRole = getUserRole(isSupport, isDealer, isManager, roleId)
    if (role === 'sales-support') {
        return callAxios({
            url: `sales-support/getSingleOrderSalesSupport/${userId}/${orderId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `order/user/${userId}/${orderId}`,
        method: 'GET',
    })
}

export const deleteOrder = (orderId: string) => {
    return callAxios({
        url: `order/deleteOrderFormData/${orderId}`,
        method: 'GET',
    })
}

export const reOrder = (
    orderId: string,
    userId: number,
    isSupport: boolean,
    isDealer: boolean,
    isManager: boolean,
    roleId: number,
) => {
    const role: UserRole = getUserRole(isSupport, isDealer, isManager, roleId)
    if (role === 'sales-support') {
        return callAxios({
            url: `sales-support/reorderOrderSalesSupport/${orderId}/${userId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `order/reorder/${orderId}/${userId}`,
        method: 'GET',
    })
}

export const cancelOrder = (
    orderId: string,
    userId: number,
    isSupport: boolean,
    isDealer: boolean,
    isManager: boolean,
    roleId: number,
) => {
    const role: UserRole = getUserRole(isSupport, isDealer, isManager, roleId)
    if (role === 'sales-support') {
        return callAxios({
            url: `sales-support/cancelOrderSalesSupport/${orderId}/${userId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `order/cancel/${orderId}/${userId}`,
        method: 'GET',
    })
}

export const orderFormData = (orderId: string) => {
    return callAxios({
        url: `order/getOrderFormData/${orderId}`,
        method: 'GET',
    })
}

export const updateOrderFormData = (orderId: string, data: any, userId: number) => {
    return callAxios({
        url: `order/updateOrderFormData/${orderId}`,
        method: 'POST',
        data: extend(data, {
            user_id: userId,
        }),
    })
}

export const saveOrderFormData = (orderId: string, data: any, userId: number) => {
    return callAxios({
        url: `order/saveOrderFormData/${orderId}`,
        method: 'POST',
        data: extend(data, {
            user_id: userId,
        }),
    })
}
