import { Form, Input, Collapse } from 'antd'
import Table from 'components/table'
import PanelHeader from 'modules/configuration/components/panelHeader'
import TextArea from 'antd/es/input/TextArea'
import appJson from '../../../app.json'

interface OrderConfigurationFormProps {
    formik: any
}

const roundedClass = 'rounded-lg flex-1'
const size = 'large'

const columns: any = [
    {
        id: 'group',
        name: 'Model Number',
        left: true,
        cell: (row: any) => {
            return (
                <div className="flex align-center items-center gap-2">
                    <h1 className="text-semibold mb-2 text-md text-center">
                        {row.machine_part_model_number
                            ? row.machine_part_model_number
                            : row.machine_category_model_number}
                        {row.manual_adjustment_model_number}
                    </h1>
                </div>
            )
        },
    },
    {
        id: 'description',
        name: 'Description',
        left: true,
        selector: (row: any) => (
            <p title={row.machine_part_name} className="cursor-pointer text-md text-left">
                {row.machine_part_name ? row.machine_part_name : row.machine_category}
                {row.manual_adjustment_name}
            </p>
        ),
    },
    {
        id: 'remarks',
        name: 'Remarks',
        left: true,
        selector: (row: any) => <p className="text-md">{row.name}</p>,
    },
    {
        id: 'note',
        name: 'Note',
        selector: (row: any) => (
            <p className="text-md">
                {' '}
                {row.name} {row.manual_adjustment_note}
            </p>
        ),
    },
    {
        id: 'price',
        name: 'Price',
        right: true,
        selector: (row: any) => (
            <p className="text-md">
                {appJson.companyCurrency} {row.price} {row.manual_adjustment_price}
            </p>
        ),
    },
    {
        id: 'qty',
        name: 'Qty',
        right: true,
        selector: (row: any) => (
            <p className="text-md">
                {row.quantity} {row.manual_adjustment_quantity}
            </p>
        ),
    },
    {
        id: 'total_price',
        name: 'Total Price',
        right: true,
        selector: (row: any) => (
            <p className="text-md">
                {appJson.companyCurrency}
                {row.manual_adjustment_quantity
                    ? row.manual_adjustment_quantity * row.manual_adjustment_price
                    : row.price && row.quantity && row.price * row.quantity}
            </p>
        ),
    },
]

const OrderConfigurationForm = (props: OrderConfigurationFormProps) => {
    const { formik } = props
    const { Panel } = Collapse

    return (
        <div className="flex flex-col gap-y-8 mb-8">
            <hr />
            {formik.values.configurations.length ? (
                formik.values.configurations.map((configuration: any, index: number) => (
                    <div>
                        <h5 className="capitalize font-bold text-xl">
                            {configuration.item.configuration[0].name}
                        </h5>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 mt-4">
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">Serial Number</h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].serial_number`}
                                    value={formik.values.configurations[index].serial_number}
                                    onChange={formik.handleChange}
                                    placeholder="Serial Number"
                                    size={size}
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">Special Instruction</h5>
                                </div>
                                <TextArea
                                    name={`configurations[${index}].special_instruction`}
                                    value={formik.values.configurations[index].special_instruction}
                                    onChange={formik.handleChange}
                                    placeholder="Special Instruction"
                                    size={size}
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">Trade-In Information</h5>
                                </div>
                                <TextArea
                                    name={`configurations[${index}].trade_in_information`}
                                    value={formik.values.configurations[index].trade_in_information}
                                    onChange={formik.handleChange}
                                    placeholder="Trade-In Information"
                                    size={size}
                                    className={roundedClass}
                                />
                            </Form.Item>
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-x-8">
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize"> Discount Given </h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].discount_given`}
                                    value={configuration.item.configuration[0].discount_percentage}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize"> Discount Amount </h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].discount_amount`}
                                    value={configuration.item.configuration[0].discount_amount}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize"> Total Retail Price </h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].total_retail_price`}
                                    value={configuration.item.configuration[0].machine_total_price.toFixed(
                                        2,
                                    )}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize"> Net Price </h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].net_price`}
                                    value={(
                                        configuration.item.configuration[0].machine_total_price -
                                        configuration.item.configuration[0].discount_amount *
                                            configuration.item.configuration[0].quantity -
                                        (configuration.item.configuration[0].machine_total_price *
                                            configuration.item.configuration[0]
                                                .discount_percentage) /
                                            100
                                    ).toFixed(2)}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize"> Trade In Value </h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].trade_in_value`}
                                    value={configuration.item.configuration[0].trade_in_value}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize"> Quantity </h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].quantity`}
                                    value={configuration.item.configuration[0].quantity}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                        </div>
                        <Collapse
                            style={{ backgroundColor: 'transparent' }}
                            // defaultActiveKey={[1, 2, 3]}
                        >
                            <Panel key={1} header={PanelHeader({ title: 'Component' })}>
                                <Table
                                    columns={columns}
                                    data={configuration.item.configuration}
                                    pagination={false}
                                    striped={false}
                                />
                            </Panel>
                            {configuration.item.parts.length > 0 && (
                                <Panel key={2} header={PanelHeader({ title: 'Compatible Parts' })}>
                                    <Table
                                        columns={columns}
                                        data={configuration.item.parts}
                                        pagination={false}
                                        striped={false}
                                    />
                                </Panel>
                            )}
                            {configuration.item.manual_adjustments.length > 0 && (
                                <Panel
                                    key={3}
                                    header={PanelHeader({ title: 'Manual Adjustments' })}
                                >
                                    <Table
                                        columns={columns}
                                        data={configuration.item.manual_adjustments}
                                        pagination={false}
                                        striped={false}
                                    />
                                </Panel>
                            )}
                        </Collapse>
                        <hr />
                    </div>
                ))
            ) : (
                <p>No Configuration</p>
            )}
        </div>
    )
}

export default OrderConfigurationForm
