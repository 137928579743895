import { useRoutes } from 'react-router-dom'
import useRouteData from './useRouteData'

const AppRoutes = () => {
    const { routes } = useRouteData()
    const element = useRoutes(routes)
    return element
}

export default AppRoutes
