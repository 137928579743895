/* eslint-disable react/no-unstable-nested-components */
import Button from 'components/button'
import Table from 'components/table'
import { useAuth } from 'modules/auth/context/useAuth'
import { useState } from 'react'
import { AddConfigurationOnQuote } from '../components/AddConfigurationOnQuote'
import QuoteAction from '../components/QuoteAction'
import { useQuoteLayout } from '../QuoteLayout'

const QuoteDetail = () => {
    const { quoteConfigurations, isLoading, quoteDetail, refetch } = useQuoteLayout()
    const { user } = useAuth()
    const [showAddConfigurationForm, setShowConfigurationForm] = useState<boolean>(false)

    const columns = [
        {
            name: 'Name',
            cell: (row: { name: string }) => {
                return (
                    <div>
                        <p className="text-black text-bold">{row.name}</p>
                    </div>
                )
            },
        },
        {
            name: 'Quantity',
            center: true,
            cell: (row: { quantity: string }) => {
                return (
                    <div>
                        <p className="text-black text-bold">{row.quantity}</p>
                    </div>
                )
            },
        },
        {
            name: 'Action',
            center: true,
            cell: (row: { id: string }) => (
                <QuoteAction
                    configuration={row}
                    isSameUser={user.id === quoteDetail.user_id}
                    refetch={refetch}
                />
            ),
            sortable: true,
        },
    ]

    return (
        <div>
            <div className="flex flex-col gap-2 md:flex-row md:justify-between md:items-center">
                <p className="text-black text-lg md:text-2xl">
                    {showAddConfigurationForm ? 'Add Configuration' : 'Configurations List'}
                </p>
                {user.id === quoteDetail.user_id ? (
                    <div className="w-44">
                        <Button
                            label={!showAddConfigurationForm ? 'Add Configuration' : 'Close'}
                            onClick={() => setShowConfigurationForm(!showAddConfigurationForm)}
                        />
                    </div>
                ) : null}
            </div>
            <hr className="mt-4 text-black border-[#E0E0E0]" />
            {showAddConfigurationForm ? (
                <AddConfigurationOnQuote
                    callback={() => {
                        setShowConfigurationForm(false)
                        refetch()
                    }}
                />
            ) : (
                <Table
                    columns={columns}
                    data={quoteConfigurations || []}
                    loading={isLoading}
                    striped
                    pagination={false}
                    totalRows={5}
                />
            )}
        </div>
    )
}

export default QuoteDetail
