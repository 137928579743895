import Button from 'components/button'
import Stepper from 'components/stepper'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { pick, omit } from 'lodash'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useAuth } from 'modules/auth/context/useAuth'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../components/loading'
import OrderDealerForm from '../component/OrderDealerForm'
import OrderQuoteForm from '../component/OrderQuoteForm'
import OrderConfigurationForm from './OrderConfigurationForm'
import { saveOrderFormData, updateOrderFormData } from '../queries/order'

const validationSchemaList = [
    Yup.object().shape({
        dealer_name: Yup.string().required('Name is required'),
        v55_email_address: Yup.string().required('V55 is required'),
        country: Yup.string().required('Country is required'),

        state: Yup.string().required('State is required'),
        city: Yup.string().required('City is required'),
        postal_code: Yup.string().required('Postal code is required'),
        address1: Yup.string().required('Address 1 is required'),

        delivery_option: Yup.string().required('Delivery option is required'),
        // delivery_for_retail: Yup.string().required('Delivery Retail is required'),
    }),
    Yup.object().shape({
        expected_delivery_date: Yup.string().when('type', {
            is: false,
            then: Yup.string().required('Expected delivery date is required'),
        }),
        dealer_request_date: Yup.string().when('type', {
            is: true,
            then: Yup.string().required('Dealer Request date is required'),
        }),
        finance_account_number: Yup.string().when('finance_company_exists', {
            is: false,
            then: Yup.string().required('Finance Detail is required'),
        }),
        // exisiting_finance_company: Yup.string().when('finance_company_exists', {
        //     is: false,
        //     then: Yup.string().required('Finance Company is required'),
        // }),
        // finance_name: Yup.string().when('finance_company_exists', {
        //     is: true || false,
        //     then: Yup.string().required('Finance Company is required'),
        // }),
        // finance_account_number: Yup.string().required('Finance Detail number is required'),
        // finance_name: Yup.string().required('Finance Detail is required'),
    }),
]

const OrderFormLayout = (props: { order: any; configurations: any; orderId: string }) => {
    const { orderId, order, configurations } = props
    const [loading, setLoading] = useState(false)
    const { user, userExtra } = useAuth()
    const navigate = useNavigate()

    const [step, setStep] = useState(0)

    const changeScreen = (key: string) => {
        switch (key) {
            case 'next':
                setStep(step + 1)
                break
            case 'previous':
                setStep(step - 1)
                break
            default:
                setStep(0)
                break
        }
    }

    const submitOrderMutation = useMutation(
        'submitOrder',
        (orderFormData: any) => updateOrderFormData(orderId, orderFormData, user.id),
        {
            onSuccess: () => {
                toast.success('Order submitted successfully')
                if (step === 2) {
                    navigate(`/order`)
                } else {
                    changeScreen('next')
                }
            },
            onError: () => {
                toast.error('Something went wrong, please try again')
            },
        },
    )

    const saveOrderForm = useMutation(
        'saveOrder',
        (orderFormData: any) => saveOrderFormData(orderId, orderFormData, user.id),
        {
            onSuccess: () => {
                toast.success('Order updated successfully')
                if (step === 2) {
                    navigate(`/order`)
                } else {
                    changeScreen('next')
                }
            },
            onError: () => {
                toast.error('Something went wrong, please try again')
            },
        },
    )

    const formik = useFormik({
        initialValues: {
            is_update: order?.customer_id !== null ? 1 : 0 || 0,
            dealer_name: order?.customer_name || '',
            v55_email_address: order?.customer_v55_email_address || '',
            order_number: order.id || '',
            order_date: null,
            order_name: order?.order_name || '',
            purchase_order_number: order?.purchase_order_number || '',
            end_user_name: order?.quotation_for || '',

            country: order?.country_id || '',
            state: order?.customer_state || '',
            city: order?.customer_city || '',
            postal_code: order?.customer_postal_code || '',
            address1: order?.customer_address1 || '',
            address2: order?.customer_address2 || '',

            delivery_option: 0,
            delivery_for_retail: order?.delivery_type || '',
            dealer_request_date: '',
            expected_delivery_date: '',

            finance_account_number: order?.customer_account_number || null,
            finance_name: order?.finance_name || ' ',
            exisiting_finance_company: order?.finance_id || '',
            finance_company_exists: true,

            payment_terms: false,
            is_dealer: false,
            type: false,

            name: order?.name || '',
            email: order?.email || '',

            orderBy: user?.name || '',
            orderByEmail: user?.email || '',

            configurations: configurations?.map((item: any, index: number) => {
                return {
                    configuration_id: item?.configuration[index]?.configuration_id || '',
                    serial_number: item?.configuration[index]?.serial_number || '',
                    trade_in_information: item?.configuration[index]?.trade_in_information || '',
                    special_instruction: item?.configuration[index]?.special_instruction || '',
                    item,
                }
            }),
        },
        validationSchema: validationSchemaList[step],
        onSubmit: values => {
            const orderFormData = {
                ...values,
                configurations: (values.configurations || []).map((configuration: any) =>
                    pick(configuration, [
                        'configuration_id',
                        'serial_number',
                        'trade_in_information',
                        'special_instruction',
                        'orderBy',
                        'orderByEmail',
                    ]),
                ),
            }
            if (step < 2) {
                saveOrderForm.mutate(
                    omit(orderFormData, [
                        'is_dealer',
                        'email',
                        'end_user_name',
                        'name',
                        'order_date',
                        'order_number',
                        'order_name',
                    ]),
                )
            }
            if (step === 2) {
                if (userExtra.submit_order_form) {
                    submitOrderMutation.mutate({
                        ...omit(orderFormData, [
                            'is_dealer',
                            'email',
                            'end_user_name',
                            'name',
                            'order_date',
                            'order_number',
                            'order_name',
                        ]),
                        send_email: true,
                    })
                } else {
                    saveOrderForm.mutate({
                        ...omit(orderFormData, [
                            'is_dealer',
                            'email',
                            'end_user_name',
                            'name',
                            'order_date',
                            'order_number',
                            'order_name',
                        ]),
                        send_email: true,
                    })
                }
            }
        },
    })

    useEffect(() => {
        formik.setFieldValue(
            'configurations',
            configurations?.map((item: any, index: number) => {
                return {
                    configuration_id: item?.configuration[index]?.configuration_id || '',
                    serial_number: item?.configuration[index]?.serial_number || '',
                    trade_in_information: item?.configuration[index]?.trade_in_information || '',
                    special_instruction: item?.configuration[index]?.special_instruction || '',
                    item,
                }
            }),
        )
    }, [configurations])
    const setInitialData = async () => {
        await formik.setFieldValue('is_update', order?.customer_id !== null ? 1 : 0 || 0)
        await formik.setFieldValue('dealer_name', order?.customer_name || '')
        await formik.setFieldValue(
            'v55_email_address',
            ['0', '1'].includes(order?.customer_v55_email_address)
                ? order?.customer_v55_email_address
                : 0,
        )
        await formik.setFieldValue('order_number', order.id || '')
        await formik.setFieldValue('order_date', moment(order?.order_date))
        await formik.setFieldValue('order_name', order?.order_name || '')
        await formik.setFieldValue('purchase_order_number', order?.purchase_order_number || '')
        await formik.setFieldValue('end_user_name', order?.quotation_for || '')
        await formik.setFieldValue('country', order?.country_id || '')
        await formik.setFieldValue('state', order?.customer_state || '')
        await formik.setFieldValue('city', order?.customer_city || '')
        await formik.setFieldValue('postal_code', order?.customer_postal_code || '')
        await formik.setFieldValue('address1', order?.customer_address1 || '')
        await formik.setFieldValue('address2', order?.customer_address2 || '')
        await formik.setFieldValue('delivery_option', order?.delivery_option || 0)
        await formik.setFieldValue('finance_account_number', order?.customer_account_number || '')
        await formik.setFieldValue('finance_name', order?.finance_name || '')
        await formik.setFieldValue('finance_company_name', order?.finance_name || '')
        await formik.setFieldValue('exisiting_finance_company', order?.finance_id || '')
        await formik.setFieldValue('type', parseInt(order?.customer_type, 10) === 1)
        await formik.setFieldValue('payment_terms', parseInt(order?.payment_terms, 10) === 1)
        await formik.setFieldValue('email', order?.email || '')
        await formik.setFieldValue('orderBy', user?.name || '')
        await formik.setFieldValue('orderByEmail', user?.email || '')
        await formik.setFieldValue('finance_company_exists', order?.finance_company_exists || false)
        await formik.setFieldValue('type', order?.type || false)
        await formik.setFieldValue(
            'expected_delivery_date',
            order?.expected_date
                ? moment(order?.expected_date, 'YYYY-MM-DD')
                : moment(moment(), 'YYYY-MM-DD'),
        )
        await formik.setFieldValue(
            'dealer_request_date',
            order?.expected_date
                ? moment(order?.expected_date, 'YYYY-MM-DD')
                : moment(moment(), 'YYYY-MM-DD'),
        )
    }

    const settingData = async () => {
        setLoading(true)
        await setInitialData()
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }

    useEffect(() => {
        settingData()
    }, [order])

    const steppers = [
        {
            id: 0,
            step: 0,
            label: 'Order form configuration',
            component: <OrderDealerForm formik={formik} />,
        },
        {
            id: 1,
            step: 1,
            label: 'Order Quote & Finance Configuration',
            component: <OrderQuoteForm formik={formik} />,
        },
        {
            id: 2,
            step: 2,
            label: 'Order Configurations',
            component: <OrderConfigurationForm formik={formik} />,
        },
    ]
    if (loading) {
        return (
            <div className="w-full h-full flex items-center justify-center">
                <Loading />
            </div>
        )
    }
    return (
        <div className="flex h-full flex-col gap-4">
            <div className="h-[6%]">
                <Stepper steppers={steppers} activeStep={steppers[step]} />
            </div>
            <div className="mt-6 md:mt-8">{steppers[step].component}</div>
            <div className="h-[4%] flex items-center justify-end gap-8">
                {step > 0 && (
                    <div className="w-28">
                        <Button
                            label="Previous"
                            variant="outline"
                            onClick={() => changeScreen('previous')}
                        />
                    </div>
                )}
                {step < steppers.length - 1 && (
                    <div className="w-28">
                        <Button
                            isLoading={submitOrderMutation.isLoading || saveOrderForm.isLoading}
                            label="Next"
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                )}
                {step === steppers.length - 1 && (
                    <div className="w-28">
                        <Button
                            isLoading={submitOrderMutation.isLoading || saveOrderForm.isLoading}
                            label={`${userExtra.submit_order_form ? 'Submit' : 'Update'}`}
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrderFormLayout
