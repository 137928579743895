import Table from 'components/table'
import { useAuth } from 'modules/auth/context/useAuth'
import QuoteAction from './QuoteAction'

interface ExpandQuoteComponentProps {
    configurations: any
    userId: string
    quoteId: string
    callback: () => void
}

const ExpandQuoteComponent = (props: ExpandQuoteComponentProps) => {
    const { configurations, userId, callback, quoteId } = props
    const { user } = useAuth()

    const columns = [
        {
            name: 'Configuration Name',
            width: '300px',
            wrap: true,
            selector: (row: { name: string }) => {
                return (
                    <div>
                        <p className="text-black text-bold">{row.name}</p>
                    </div>
                )
            },
        },

        {
            name: 'Quantity',
            center: true,
            cell: (row: { quantity: string }) => {
                return (
                    <div>
                        <p className="text-black text-bold">{row?.quantity || '-'}</p>
                    </div>
                )
            },
        },
        {
            name: 'Discount Percent',
            wrap: true,
            selector: (row: { discount_percentage: string }) => {
                return (
                    <div>
                        <p className="text-black text-bold">{row?.discount_percentage || '-'} %</p>
                    </div>
                )
            },
        },
        {
            name: 'Trade In Value',
            wrap: true,
            selector: (row: { trade_in_value: string }) => {
                return (
                    <div>
                        <p className="text-black text-bold">{row?.trade_in_value || '-'}</p>
                    </div>
                )
            },
        },
        {
            name: 'Action',
            center: true,
            cell: (row: { id: string }) => (
                <QuoteAction
                    configuration={row}
                    isSameUser={user.id === userId}
                    refetch={callback}
                    quote={quoteId}
                />
            ),
            sortable: true,
        },
    ]
    return (
        <div className="border">
            <Table
                columns={columns}
                data={configurations || []}
                loading={false}
                striped
                pagination={false}
                totalRows={5}
            />
        </div>
    )
}

export default ExpandQuoteComponent
