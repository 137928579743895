import Header from 'components/header'
import { Outlet } from 'react-router-dom'
import Footer from '../modules/shared/components/Footer'

function DashboardLayout() {
    return (
        <div className="w-full min-h-screen bg-inputBorder relative flex flex-col justify-between gap-4">
            <Header />
            <div className="w-full mx-auto flex-grow flex relative px-4 md:px-12">
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}

export default DashboardLayout
