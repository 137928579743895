import { callAxios } from 'plugins/api'

const getLibraryApi = 'configuration/library/user/'

export const getLibrary = (params: { userId: string | number }) => {
    return callAxios({
        url: `${getLibraryApi}${params.userId}`,
        method: 'GET',
    })
}
