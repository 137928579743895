import Button from 'components/button'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { successToast } from 'utils/toasterUtil'
import { useAuth } from 'modules/auth/context/useAuth'
import DiscountRequestForm from '../components/DiscountRequestForm'
import { requestMultipleDiscountRequest } from '../queries/quotes'
import { useQuoteLayout } from '../QuoteLayout'

const MultipleDiscountRequest = () => {
    const { quoteConfigurations, isLoading } = useQuoteLayout()
    const { user } = useAuth()

    const higherDiscountRequest = useMutation(
        ['higeherDiscountrequest'],
        (discountRequest: any) => requestMultipleDiscountRequest(discountRequest, user.id),
        {
            onSuccess: data => {
                toast(data?.data?.message || 'Discount Requested Successfully', successToast)
            },
            onError: (error: any) => {
                toast(error?.message || 'Discount Requested Failed', successToast)
            },
        },
    )

    const formik = useFormik({
        initialValues: {
            configurationsRequest: [
                {
                    requested_comment: '',
                    requested_configuration_id: '',
                    requested_configuration_name: '',
                    requested_discount_percentage: '',
                },
            ],
        },
        onSubmit: values => {
            higherDiscountRequest.mutate(values)
        },
    })

    useEffect(() => {
        if (quoteConfigurations) {
            const quoteDiscountItems = quoteConfigurations.map(
                (item: { id: string; name: string }) => {
                    return {
                        requested_comment: '',
                        requested_configuration_id: item.id,
                        requested_configuration_name: item.name,
                        requested_discount_percentage: '',
                    }
                },
            )
            formik.setFieldValue('configurationsRequest', quoteDiscountItems)
        }
    }, [])

    if (isLoading) {
        return <p>Loading</p>
    }

    return (
        <div className="h-full flex flex-col gap-4 h-screen overflow-auto">
            <div className="flex justify-between items-center">
                <p className="text-black text-2xl">Multiple Discount Request</p>
                <div className="w-fit">
                    <Button label="Submit Discount Request" onClick={() => formik.handleSubmit()} />
                </div>
            </div>
            <hr className="mt-4 text-black border-[#E0E0E0]" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 flex-1 overflow-auto">
                {(formik.values.configurationsRequest || []).map(
                    (item: { requested_configuration_id: string }, index: number) => (
                        <div key={item.requested_configuration_id}>
                            <DiscountRequestForm formik={formik} index={index} />
                        </div>
                    ),
                )}
            </div>
        </div>
    )
}

export default MultipleDiscountRequest
