/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import settingImage from 'assets/svg/setting.png'
import Table from 'components/table'
import Fuse from 'fuse.js'
import { isNaN } from 'lodash'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import { formatCurrency } from '../../utils/currency/formatCurrency'
import { useAuth } from '../auth/context/useAuth'
import TableAction from './components/tableAction'
import ConfigureTabs from './components/tabs/configureTabs'
import { ConfigurationQueries } from './queries/configuration'
import { getLibrary } from './queries/library'

const imageUrl = process.env.REACT_APP_IMAGE_URL
const searchKey = 'CPQ_SEARCH_CA'

const initialUserSelectList = [
    {
        label: 'All',
        value: 'All',
    },
]

const Configuration = () => {
    const { user, userExtra, country } = useAuth()
    const [activeTab, setActiveTab] = useState<number>(userExtra.role === 'Sales Merged' ? 3 : 1)
    const [pattern, setPattern] = useState<string>('')
    const navigate = useNavigate()
    const [list, setList] = useState<[]>([])
    const [userList, setUserList] = useState<any[]>(initialUserSelectList)
    const [selectedUser, setSelectedUser] = useState<any>()
    const [tempConfigurationData, setTempConfigurationData] = useState<any>()

    useQuery(['userList', activeTab], () => ConfigurationQueries.getUserList(), {
        onSuccess: data => {
            if (data?.data.teamUsers) {
                setUserList(
                    data?.data.teamUsers.map((x: any) => ({
                        label: x.name,
                        value: x.name,
                    })),
                )
            }
            if (data?.data.childUser) {
                setUserList(
                    data?.data.childUser.map((x: any) => ({
                        label: x.name,
                        value: x.name,
                    })),
                )
            }
        },
    })

    const {
        data: configurationData,
        isLoading: configurationLoading,
        isFetching,
        refetch,
    } = useQuery(
        ['allConfiguration', activeTab, user.id],
        () => {
            if (activeTab === 1) {
                if (userExtra.is_support) {
                    return ConfigurationQueries.getConfiguration(
                        user.id,
                        userExtra.is_support,
                        userExtra.is_dealer,
                        userExtra.is_manager,
                        userExtra.roleId,
                    )
                }
                return ConfigurationQueries.getUserConfigurations({ userId: user.id })
            }
            if (activeTab === 2) {
                return getLibrary({ userId: user.id })
            }
            if (activeTab === 3) {
                return ConfigurationQueries.getChildrenConfiguration({ userId: user.id })
            }
            if (activeTab === 4) {
                return ConfigurationQueries.getParentLibraryConfiguration({ userId: user.id })
            }
            return null
        },
        {
            onSuccess: (data: any) => {
                setTempConfigurationData(data.data)
            },
        },
    )

    const fuse = new Fuse(tempConfigurationData || [], {
        shouldSort: true,
        threshold: 0.1,
        ignoreLocation: true,
        keys: ['name', 'user_name'],
    })

    const handleTabChanged = (tab: number) => {
        setActiveTab(tab)
    }

    const getTabRowAction = (row: any) => {
        return (
            <TableAction
                activeTab={activeTab}
                row={row}
                refetch={refetch}
                tag={activeTab === 2 ? 'library' : ''}
            />
        )
    }

    const handleRowClicked = (configurationItem: any) => {
        navigate(
            `/configuration/${activeTab === 4 || activeTab === 2 ? 'library/' : ''}${
                activeTab !== 4 ? configurationItem.id : configurationItem.configuration_id
            }`,
        )
    }

    const columns = [
        {
            name: 'Model',
            cell: (row: any) => {
                return (
                    <img
                        onClick={() => handleRowClicked(row)}
                        src={`${
                            row.machine_category_image
                                ? `${imageUrl}${row.machine_category_image}`
                                : settingImage
                        }`}
                        alt=""
                        className="rounded-lg object-contain h-[10vh] w-[10vw]"
                        loading="lazy"
                    />
                )
            },
        },
        {
            name: 'Name',
            left: true,
            selector: (row: any) => row.name,
            cell: (row: any) => (
                <div className="flex flex-col py-4" onClick={() => handleRowClicked(row)}>
                    <h1 className="text-black text-semibold text-lg mb-2">
                        {row?.name || 'unknown'}
                    </h1>
                    <h5 className="text-placeholderColor flex flex-col justify-start font-semibold gap-2">
                        <span className="text-md">Category:</span>
                        <span className="text-md">{row?.machine_category || ''}</span>
                    </h5>
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Connected to',
            sortable: true,
            selector: (row: any) => Boolean(row.quote_id),
            cell: (row: any) => (
                <div className="flex flex-col text-base" onClick={() => handleRowClicked(row)}>
                    {row?.quote_id ? (
                        <div>
                            <a
                                href={`/quotes/${row?.quote_id}`}
                                className="leading-3 flex font-semibold text-primaryLight hover:text-primary"
                                aria-label="Connected to"
                            >
                                {/* {row?.quote_id} */}
                                Connected
                            </a>
                        </div>
                    ) : (
                        <p>New</p>
                    )}
                </div>
            ),
            omit: Boolean(activeTab === 2),
        },
        {
            name: 'Quantity',
            center: true,
            selector: (row: any) => row.quantity,
            cell: (row: any) => {
                return (
                    <p className="text-base" onClick={() => handleRowClicked(row)}>
                        {row.quantity}
                    </p>
                )
            },
            sortable: true,
            omit: Boolean(activeTab === 3),
        },
        {
            name: 'Retail Price',
            left: true,
            selector: (row: any) => row.retail_price,
            cell: (row: any) => {
                const retailPrice = row.machine_total_price / (row ? row.quantity : 1)
                return (
                    <div className="flex flex-col" onClick={() => handleRowClicked(row)}>
                        <h1 className="text-black text-base text-semibold mb-2">
                            {formatCurrency(
                                isNaN(retailPrice) ? 0 : retailPrice?.toFixed(2),
                                country.currency || country.country_code || '',
                            )}
                        </h1>
                    </div>
                )
            },
        },
        {
            name: 'Total Price',
            center: true,
            selector: (row: any) => row?.final_total_price || 0,
            cell: (row: any) => {
                return (
                    <div onClick={() => handleRowClicked(row)}>
                        <p className="text-base">
                            {formatCurrency(
                                row?.final_total_price?.toFixed(2),
                                country.currency || country.country_code || '',
                            )}
                        </p>
                    </div>
                )
            },
            sortable: true,
            omit: Boolean(activeTab === 3),
        },
        {
            name: 'Created By',
            sortable: true,
            selector: (row: any) => row.user_name,
            cell: (row: any) => {
                return (
                    <p onClick={() => handleRowClicked(row)} className="text-lg">
                        {row.user_name}
                    </p>
                )
            },
            omit: Boolean(activeTab !== 3),
        },
        {
            name: 'Created At',
            sortable: true,
            selector: (row: any) => row.created_at,
            cell: (row: any) => {
                return (
                    <p onClick={() => handleRowClicked(row)} className="text-lg">
                        {moment(row.created_at).format('YYYY-MM-DD') || '-'}
                    </p>
                )
            },
            omit: Boolean(activeTab !== 3),
        },
        {
            name: 'Action',
            center: true,
            cell: (row: any) => getTabRowAction(row),
        },
    ]

    const cardClass = 'w-full bg-white px-4 py-4 rounded-md flex flex-col'

    useEffect(() => {
        const lsQuery = String(localStorage?.getItem(searchKey) || '')
        setPattern(lsQuery || '')
        const results: any = fuse.search(String(localStorage.getItem(searchKey)))
        setList(results.map((b: any) => b.item).flat())
    }, [tempConfigurationData])

    useEffect(() => {
        const lsQuery = String(localStorage?.getItem(searchKey) || '')
        setPattern(lsQuery || '')
        const results: any = fuse.search(lsQuery)
        setList(results.map((b: any) => b.item).flat())
    }, [])

    useEffect(() => {
        if (tempConfigurationData && tempConfigurationData.length > 0) {
            setTempConfigurationData([])
        }
    }, [activeTab])

    useEffect(() => {
        if (selectedUser) {
            if (selectedUser.value === 'All') {
                setTempConfigurationData(configurationData?.data)
            } else {
                setTempConfigurationData(
                    configurationData?.data.filter(
                        (configuration: any) =>
                            configuration.user_name.toLowerCase() ===
                            selectedUser.value.toLowerCase(),
                    ),
                )
            }
        }
    }, [selectedUser])

    useEffect(() => {
        if (pattern) {
            const results: any = fuse.search(pattern)
            setList(results.map((b: any) => b.item).flat())
        } else {
            setList([])
        }
    }, [pattern])

    return (
        <div className={cardClass}>
            <div className="min-h-fit h-[8%]">
                <ConfigureTabs
                    activeTab={activeTab}
                    setActiveTab={(index: number) => handleTabChanged(index)}
                    pattern={pattern}
                    setPattern={(value: string) => {
                        setPattern(value)
                    }}
                    userList={userList}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                />
            </div>
            <Table
                columns={columns}
                data={list.length > 0 ? list : tempConfigurationData || []}
                loading={configurationLoading || isFetching}
                striped
                onRowCLicked={handleRowClicked}
                pagination
                totalRows={5}
                customStyles={{
                    responsiveWrapper: {
                        style: {
                            minHeight: '680px',
                        },
                    },
                }}
            />
        </div>
    )
}

export default Configuration
