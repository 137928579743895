/* eslint-disable @typescript-eslint/no-explicit-any */
import currencySymbolMap from './curreny.json'

const currencySymbols: any = currencySymbolMap

export const formatCurrency = (value: string | number, currencyCode: string): string => {
    const code = String(currencyCode).toUpperCase()
    const symbol = String(currencySymbols[code])
    if (!value) {
        return `0 ${symbol} `
    }
    if (symbol) {
        return `${value} ${symbol} `
    }
    return String(value)
}

export const getCurrencySymbol = (currencyCode: string): string => {
    const code = String(currencyCode).toUpperCase()
    const symbol = String(currencySymbols[code])
    if (symbol) {
        return symbol
    }
    return ''
}
