/* eslint-disable */
import { useState } from 'react'
import { BiMenu, BiMessageDetail, BiUser } from 'react-icons/bi'
import { IoMdClose, IoMdNotificationsOutline } from 'react-icons/io'
import { AiFillCaretDown } from 'react-icons/ai'
import { useQuery } from 'react-query'
import { Link, NavLink } from 'react-router-dom'

import companyLogo from 'assets/reesink-uk/logo.svg'
import { navMenus } from 'constants/navLinks'
import { useAuth } from 'modules/auth/context/useAuth'
import classNames from 'classnames'
import {
    CUSTOMER_FEEDBACK_ROUTE,
    DISCOUNT_REQUEST_ROUTE,
    LOGIN_ROUTE,
    PROFILE_ROUTE,
} from 'route/appRoutes'
import appConfig from '../app.json'
import Dropdown from './dropdown'
import Image from './image'
import { DashboardQueries } from '../modules/dashboard/queries/dashboard'
import Button from './button'
import { includes } from 'lodash'

const profileDropDown = [
    {
        label: 'Profile',
        icon: <BiUser size={20} />,
        link: PROFILE_ROUTE,
        hideFrom: [],
    },
    {
        label: 'Discount Requests',
        icon: <IoMdNotificationsOutline size={20} />,
        link: DISCOUNT_REQUEST_ROUTE,
        hideFrom: ['dealer'],
    },
    {
        label: 'Feedback',
        icon: <BiMessageDetail size={20} />,
        link: CUSTOMER_FEEDBACK_ROUTE,
        hideFrom: [],
    },
]

const Header = () => {
    const { companyName } = appConfig
    const [mobile, setMobile] = useState<boolean>(false)
    const [navIcons, setNavIcons] = useState<
        {
            icon: JSX.Element
            link: string
        }[]
    >([])

    const { logoutUser, userExtra, user, isUserLoggedIn } = useAuth()
    const getUserRole = () => {
        if (userExtra?.is_manager || userExtra?.is_manager === 1) {
            return 'manager'
        }
        if (
            (userExtra?.is_dealer && !userExtra?.is_manager) ||
            (userExtra?.is_dealer === 1 && userExtra?.is_manager === 0)
        ) {
            return 'dealer'
        }
        return 'sales'
    }

    useQuery(
        ['getDashboardCount'],
        () => DashboardQueries.getDiscountCount({ userId: user && user.id }),
        {
            onSuccess: data => {
                if (data.length) {
                    if (!userExtra?.is_dealer) {
                        setNavIcons([
                            {
                                icon: (
                                    <div>
                                        <span className="relative inline-block">
                                            <IoMdNotificationsOutline size={30} />
                                            {data?.data?.requestedNotifications > 0 && (
                                                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                                    {data?.data?.requestedNotifications}
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                ),
                                link: '/notifications',
                            },
                        ])
                    }
                } else {
                    setNavIcons([])
                }
            },
        },
    )

    const getRole = () => {
        if (userExtra?.is_manager === 1) {
            return 'manager'
        }
        if (userExtra && userExtra.is_dealer === 1 && userExtra.is_manager === 0) {
            return 'dealer'
        }
        if (userExtra && userExtra.is_support === 1 && userExtra.is_manager === 0) {
            return 'support'
        }
        return 'support'
    }

    const dropDownContent = (
        <Dropdown
            labelContent={
                <div className="flex items-center gap-2 cursor-pointer hover:text-primary">
                    <p className="text-md">{(user && user?.name) || user?.email || 'unknown'}</p>
                    <div>
                        <AiFillCaretDown size={20} />
                    </div>
                </div>
            }
            content={
                <>
                    <div className="px-4 py-2 text-zinc-400	font-normal	overflow-hidden">
                        <li className="text-sm">{(user && user.email) || ''}</li>
                        <li className="text-accent text-sm">{userExtra && userExtra.role}</li>
                        <li className="text-sm">
                            {userExtra && userExtra.country && userExtra.country.length
                                ? userExtra && userExtra.country[0]?.country_name
                                : ''}
                        </li>
                    </div>
                    <div className="py-6 flex flex-col gap-4" aria-labelledby="dropdownLargeButton">
                        {profileDropDown &&
                            profileDropDown.map((item, index) => {
                                if (includes(item.hideFrom, getUserRole())) {
                                    return null
                                }
                                return (
                                    <li key={item.label + index}>
                                        <a
                                            href={item.link}
                                            className="flex items-center justify-start gap-3 w-full py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-red-500"
                                        >
                                            <span>{item.icon}</span>
                                            <span> {item.label}</span>
                                        </a>
                                    </li>
                                )
                            })}

                        <li className="px-5 cursor-pointer">
                            <Button variant="primary" onClick={() => logoutUser()} label="Logout" />
                        </li>
                    </div>
                </>
            }
        />
    )

    return (
        <nav className="px-3 py-3 bg-white border-gray-200 sticky top-0 left-0 w-full z-[999999]">
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                <a href="/" className="h-10 flex justify-between items-center">
                    <Image
                        src={companyLogo}
                        altText={companyName}
                        height="h-8"
                        objectFit="object-cover"
                    />
                </a>
                {isUserLoggedIn ? (
                    <>
                        <div
                            className="md:hidden inline-flex justify-center items-center"
                            data-collapse-toggle="mobile-menu"
                            aria-controls="mobile-menu-2"
                            aria-expanded="false"
                            onClick={() => {
                                setMobile(!mobile)
                            }}
                        >
                            {mobile ? <IoMdClose size={25} /> : <BiMenu size={25} />}
                        </div>

                        {/* menus */}
                        <div
                            className={`w-full ${mobile ? 'block' : 'hidden'} md:block md:w-auto`}
                            id="mobile-menu"
                        >
                            <ul className="flex flex-col mt-4 md:flex-row md:space-x-16 md:mt-0 md:text-sm md:font-medium">
                                {navMenus &&
                                    navMenus.map(({ label, link, hideFrom }, index) => {
                                        if (
                                            hideFrom?.length > 0 &&
                                            includes(hideFrom, getUserRole())
                                        ) {
                                            return null
                                        }
                                        return (
                                            <NavLink
                                                key={`${label}-${index}`}
                                                to={link}
                                                className={({ isActive }) => {
                                                    return classNames(
                                                        'block py-2 pr-3 pl-3 border-b md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0 md:dark:hover:bg-transparent',
                                                        isActive ? 'text-primary' : 'text-gray-700',
                                                    )
                                                }}
                                            >
                                                {label}
                                            </NavLink>
                                        )
                                    })}
                                <li className="block py-2 pr-4 pl-1 text-gray-700 md:hidden">
                                    {dropDownContent}
                                </li>
                            </ul>
                        </div>

                        <div className="hidden md:block">
                            <ul className="flex flex-col mt-4 md:flex-row md:items-center md:space-x-8 md:mt-0 md:text-sm md:font-bold">
                                {navIcons &&
                                    navIcons.map(({ icon, link }, index) => (
                                        <li key={`${icon}-${index}`}>
                                            <a
                                                href={link}
                                                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 dark:hover:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                                            >
                                                {icon}
                                            </a>
                                        </li>
                                    ))}
                                <div>{dropDownContent}</div>
                            </ul>
                        </div>
                    </>
                ) : (
                    <Link to={LOGIN_ROUTE}>
                        <Button variant="primary" label="Login" />
                    </Link>
                )}
            </div>
        </nav>
    )
}

export default Header
