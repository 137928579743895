import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TranslationService } from 'modules/translation/services/translationService'

const useTranslationData = () => {
    const userLang = localStorage.getItem('i18nextLng')
    const [langLoading, setLangLoading] = useState<boolean>(false)
    const [selectedLang, setSelectedLang] = useState<string>(userLang || 'en')
    const translation = useTranslation('common')

    const fetchTranslations = async () => {
        setLangLoading(true)
        const data = await TranslationService.getTranslations()
        for (const [key, value] of Object.entries(data)) {
            translation.i18n.addResources(key, 'common', value)
        }
        await translation.i18n.changeLanguage(selectedLang)
        setLangLoading(false)
    }

    const handleLangChanged = async (lang: string) => {
        setLangLoading(true)
        setSelectedLang(lang)
        await translation.i18n.changeLanguage(lang)
        setLangLoading(false)
    }

    return {
        selectedLang,
        langLoading,
        fetchTranslations,
        translation,
        handleLangChanged,
    }
}

export default useTranslationData
