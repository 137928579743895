export type UserRole = 'manager' | 'dealer' | 'admin' | 'user' | 'sales-support'

export const getUserRole = (
    isSupport: boolean,
    isDealer: boolean,
    isManager: boolean,
    roleId: number,
): UserRole => {
    if (roleId === 1) {
        return 'admin'
    }

    if (isManager) {
        return 'manager'
    }

    if (isDealer) {
        return 'dealer'
    }

    if (isSupport) {
        return 'sales-support'
    }

    return 'user'
}
