/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import DataTable from 'react-data-table-component'
import Loading from './loading'
import './table.css'

interface DataTableProps {
    columns: any
    data: any
    totalRows?: number
    loading?: boolean
    striped?: boolean
    noHeader?: boolean
    pagination?: boolean
    expandableRows?: boolean
    ExpandedComponent?: any
    customStyles?: any
    tableHeight?: string
    selectableRows?: boolean
    onRowCLicked?: (item: any) => void
    conditionalRowStyles?: any
}

const Table = (props: DataTableProps) => {
    const {
        columns,
        data,
        totalRows,
        loading,
        noHeader,
        pagination,
        striped = true,
        expandableRows,
        ExpandedComponent,
        tableHeight,
        selectableRows,
        onRowCLicked,
        conditionalRowStyles,
        customStyles,
    } = props

    const defaultCustomStyle = {
        headRow: {
            style: {
                fontSize: '0.9rem',
                fontWeight: 'semi-bold',
            },
        },
        expanderRow: {
            style: {
                height: 'fit-content',
            },
        },
    }

    return (
        <DataTable
            className="relative whitespace-nowrap"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination={!!pagination}
            paginationPerPage={totalRows}
            striped={striped}
            highlightOnHover
            persistTableHead
            noTableHead={noHeader}
            fixedHeader
            selectableRows={selectableRows}
            onRowClicked={onRowCLicked}
            fixedHeaderScrollHeight={tableHeight || '500px'}
            expandableRows={expandableRows}
            expandableRowsComponent={ExpandedComponent}
            expandOnRowClicked={expandableRows}
            progressComponent={<Loading />}
            customStyles={customStyles || defaultCustomStyle}
            conditionalRowStyles={conditionalRowStyles}
        />
    )
}

export default Table
