import './loading.css'

const SmallLoading = () => {
    return (
        <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
        </div>
    )
}

export default SmallLoading
