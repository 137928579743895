import { useState } from 'react'

const liClass = `text-bold cursor-pointer hover:border-gray-300 w-full md:w-auto border-none md:border-b-2 md:border`

export type TabType = {
    name: string
    value: string
}

const Tabs = ({
    leftContent,
    tabs,
    getActiveTab,
    initialTab: intitalTab,
}: {
    tabs: TabType[]
    leftContent?: React.ReactElement
    getActiveTab: (item: TabType) => void
    initialTab?: TabType
}) => {
    const [activeTab, setActiveTab] = useState<TabType>(intitalTab || tabs[0])

    const handleTabChange = (tab: TabType) => {
        setActiveTab(tab)
        getActiveTab(tab)
    }

    // flex-1 w-full bg-red-300 flex-wrap gap-8 -mb-px
    return (
        <div className="flex items-center justify-between flex-wrap gap-4">
            <ul className="grid grid-cols-2 md:grid-cols-3 w-full md:w-fit gap-4">
                {tabs.map((tab: TabType) => (
                    <li
                        key={tab.name}
                        className={`${
                            activeTab.name === tab.name
                                ? 'text-primary border-primary'
                                : 'text-placeholderColor border-transparent'
                        } ${liClass}`}
                        onClick={() => handleTabChange(tab)}
                    >
                        {tab.name}
                    </li>
                ))}
            </ul>
            <div className="w-full md:w-fit">{leftContent}</div>
        </div>
    )
}

export default Tabs
