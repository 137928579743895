import { DISCOUNT_REQUEST_ROUTE, HOME_ROUTE } from 'route/appRoutes'

export const navMenus = [
    {
        label: 'Home',
        link: HOME_ROUTE,
        hideFrom: [],
    },
    {
        label: 'Configurations',
        link: '/configuration',
        hideFrom: [],
    },
    {
        label: 'Quotes',
        link: '/quotes',
        hideFrom: [],
    },
    {
        label: 'Orders',
        link: '/order',
        hideFrom: [],
    },
    {
        label: 'Discount Requests',
        link: DISCOUNT_REQUEST_ROUTE,
        hideFrom: ['dealer'],
    },
]
