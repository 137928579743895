import { callAxios } from 'plugins/api'

export const getProduct = (params: { roleId: number; countryId: number }) => {
    return callAxios({
        url: `machine-category/tree-category/${params.roleId}/${params.countryId}`,
        method: 'GET',
    })
}

export const getProductTreeSelect = (params: {
    configurationCategory: any
    roleId: number
    countryId?: number
}) => {
    return callAxios({
        url: `machine-category/showTreeSelectData/${params.configurationCategory}/${params.roleId}${
            params.countryId ? `/${params.countryId}` : ''
        }`,
        method: 'GET',
    })
}
