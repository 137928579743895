import { AiOutlineEye, AiOutlineHistory } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'

const OrderAction = ({ configuration }: { configuration: any }) => {
    const { orderId } = useParams()
    const navigate = useNavigate()
    return (
        <div className="flex gap-4 items-center">
            <div
                title="View Configutration"
                className=" cursor-pointer hover:text-primary text-lg"
                onClick={() => navigate(`/order/${orderId}/${configuration.id}`)}
            >
                <AiOutlineEye size={30} className="text-placeholderColor hover:text-primary" />
            </div>
            <div
                title="ReviewConfiguration History"
                className=" cursor-pointer hover:text-primary text-lg"
                onClick={() => navigate(`/order/${orderId}/${configuration.id}/history`)}
            >
                <AiOutlineHistory size={25} className="text-placeholderColor hover:text-primary" />
            </div>
        </div>
    )
}

export default OrderAction
