import React, { useEffect, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import Fuse from 'fuse.js'

import Input from '../../../components/input'
import Table from '../../../components/table'
import { IDownloadTable } from '../models/DownloadTable'
import Loading from '../../../components/loading'

const baseUrl = `${process.env.REACT_APP_API_URL}`

interface Props {
    heading: string
    data: any
    isLoading: boolean
    isFetching: boolean
}

const getTabRowAction = (row: { link: string }) => {
    return (
        <a
            rel="noopener noreferrer"
            target="_blank"
            download=""
            href={`${baseUrl}/storage/${row.link}`}
            className="block font-bold py-3 px-3 w-48 rounded-lg flex items-center justify-center gap-3 whitespace-nowrap bg-primary hover:bg-secondary text-textLight"
        >
            Download
        </a>
    )
}

const columns = [
    {
        name: 'Name',
        cell: (row: { name: string }) => {
            return <p className="text-black text-bold">{row.name}</p>
        },
        sortable: true,
    },
    {
        name: 'Actions',
        center: true,
        cell: (row: any) => getTabRowAction(row),
        sortable: true,
    },
]

const DownloadsTable = ({ heading, data, isLoading, isFetching }: Props) => {
    const [patterns, setPatterns] = useState<string>('')
    const [list, setList] = useState<IDownloadTable[] | []>([])

    const resetSearch = () => {
        setList(data)
    }

    const fuse = new Fuse(data, {
        shouldSort: true,
        threshold: 0.1,
        ignoreLocation: true,
        keys: ['name'],
    })

    useEffect(() => {
        resetSearch()
    }, [data])

    useEffect(() => {
        if (patterns.length > 0) {
            const results: any = fuse.search(patterns)
            setList(results.map((b: any) => b.item).flat())
        } else {
            resetSearch()
        }
    }, [patterns])

    if (isLoading || isFetching) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold capitalize text-center h-5">{heading}</h1>
                <div className="w-full md:w-auto">
                    <Input
                        type="text"
                        value={patterns}
                        name="search"
                        leftContent={<BiSearch />}
                        onChange={(e: any) => setPatterns(e.target.value)}
                    />
                </div>
            </div>
            <div className="overscroll-auto overflow-auto">
                <Table
                    columns={columns}
                    data={list || []}
                    striped
                    pagination={false}
                    totalRows={5}
                />
            </div>
        </div>
    )
}

export default DownloadsTable
