/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */

import { DatePicker, Form, Input, Select } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Fuse from 'fuse.js'
import moment from 'moment'
import { toast } from 'react-toastify'

import Button from 'components/button'
import Table from 'components/table'

import { ChangeEvent, useState } from 'react'
import AlertModal from 'components/modal/alertModal'
import { errorToast, successToast } from 'utils/toasterUtil'
import { useFormik } from 'formik'
import { filter, map, uniq } from 'lodash'
import Tabs, { TabType } from 'components/tabs'
import classnames from 'classnames'
import { useAuth } from 'modules/auth/context/useAuth'

import { MdNotificationsActive } from 'react-icons/md'
import {
    getAllQuotes,
    getGenericQuestion,
    getTeamsQuotes,
    orderQuoteData,
    updateExpectedDiscount,
    updateOrderOpportunity,
    updateQuoteStatus,
} from './queries/quotes'
import ExpandQuoteComponent from './components/ExpandQuoteComponent'

const cardClass = 'w-full bg-white px-4 py-4 rounded-md flex flex-col gap-4'

const dateFormat = 'YYYY-MM-DD'
const roundedClass = 'rounded-lg flex-1'
const size = 'large'

type OrderOpportunityType = {
    label: number | string
    value: number | string
}

const orderOpportunity: OrderOpportunityType[] = [
    {
        label: 'all',
        value: 'all',
    },
    {
        label: 0,
        value: 0,
    },
    {
        label: 10,
        value: 10,
    },
    {
        label: 30,
        value: 30,
    },
    {
        label: 70,
        value: 70,
    },
    {
        label: 90,
        value: 90,
    },
]

const statusDropDown: OrderOpportunityType[] = [
    {
        label: 'all',
        value: 'all',
    },
    {
        label: 'pending',
        value: 'pending',
    },
    {
        label: 'lost',
        value: 'lost',
    },
    {
        label: 'postponed',
        value: 'postponed',
    },
]

const tabs: TabType[] = [
    {
        name: 'My Quotes',
        value: 'my-quotes',
    },
    {
        name: 'Team Quotes',
        value: 'team-quotes',
    },
]

const teamRole: string[] = ['Management', 'Sales management']

const Quotes = () => {
    const [searchParam, setSearchParams] = useSearchParams()

    const navigate = useNavigate()
    const { user, userExtra } = useAuth()
    const [activeTab, setActiveTab] = useState(
        teamRole.includes(userExtra.role) ? tabs[1] : tabs[0],
    )

    const [statusSelectList, setStatusSelectList] = useState<OrderOpportunityType[]>([])
    const [userList, setUserList] = useState<OrderOpportunityType[]>([])

    const { data, isLoading, refetch } = useQuery(
        ['quoteList', activeTab.value],
        () =>
            activeTab.value === 'team-quotes'
                ? getTeamsQuotes(user.id)
                : getAllQuotes(
                      user.id,
                      userExtra.is_support,
                      userExtra.is_dealer,
                      userExtra.is_manager,
                      userExtra.role_id,
                  ),
        {
            onSuccess: d => {
                if (d.data && d.data.length > 0) {
                    const tempUserList: string[] = []
                    d.data.forEach((list: any) => tempUserList.push(list.user_name))
                    const uniqueUserList = uniq(tempUserList)
                    setUserList([
                        {
                            label: 'all',
                            value: 'all',
                        },
                        ...uniqueUserList.map(u => ({
                            label: u,
                            value: u,
                        })),
                    ])

                    const tempStatusList: string[] = []
                    d.data.forEach((list: any) => tempStatusList.push(list.status))
                    const uniqueStatus = uniq(tempStatusList)
                    setStatusSelectList([
                        {
                            label: 'all',
                            value: 'all',
                        },
                        ...uniqueStatus.map(s => ({
                            label: s,
                            value: s,
                        })),
                    ])
                }
            },
        },
    )

    const onChangeOpportunity = (value: string) => {
        const params = new URLSearchParams(searchParam)
        if (value === '' || value === undefined || value === 'all') {
            params.delete('op')
        } else {
            params.set('op', value)
        }
        setSearchParams(params)
    }

    const onChangeStatus = (value: string) => {
        const params = new URLSearchParams(searchParam)
        if (value === '' || value === undefined || value === 'all') {
            params.delete('st')
        } else {
            params.set('st', value)
        }
        setSearchParams(params)
    }

    const onUserChange = (value: string) => {
        const params = new URLSearchParams(searchParam)
        if (value === '' || value === undefined || value === 'all') {
            params.delete('us')
        } else {
            params.set('us', value)
        }
        setSearchParams(params)
    }
    const [alertModal, setAlertModal] = useState<any>({
        status: false,
        key: '',
        item: {},
        newValue: '',
    })

    const useMutationOptions = {
        onSuccess: (options: any) => {
            toast(options?.message || `Order Status Updated Successfully`, successToast)
            refetch()
            // navigate('/order')
        },
        onError: (error: any) => {
            toast(error?.message || `Failed to update information`, errorToast)
        },
    }

    const updateOrder = useMutation(
        'updateOrderOpportunity',
        () =>
            updateOrderOpportunity(
                alertModal?.item?.id || '',
                alertModal?.newValue || alertModal?.item?.order_opportunity,
            ),
        useMutationOptions,
    )

    const updateExpDate = useMutation('updateExpDate', () =>
        updateExpectedDiscount(alertModal?.item?.id || '', alertModal?.newValue || ''),
    )

    const updateStatus = useMutation(
        'updateStatus',
        formData => updateQuoteStatus(alertModal?.item?.id, formData, user.id),
        useMutationOptions,
    )

    const createOrder = useMutation(
        'createOrder',
        () =>
            orderQuoteData(
                {
                    quote_id: alertModal?.item?.id,
                    quotation_name: alertModal?.item?.quotation_name,
                    configuration_id: alertModal?.item?.configuration_id,
                },
                user.id,
            ),
        useMutationOptions,
    )

    const genericeQuestion = useQuery('genericQuestion', () => getGenericQuestion())

    const closeAlertModal = () => {
        setAlertModal({
            status: false,
            key: '',
            item: {},
            newValue: '',
        })
    }

    const genericQuestionFormik = useFormik({
        initialValues: {
            generic_id: '',
            custom_comment: '',
            status: alertModal.newValue,
        },
        onSubmit: async (values: any) => {
            await updateStatus.mutate({
                ...values,
                status: alertModal.newValue,
            })
            closeAlertModal()
        },
    })

    const updateOrderQuantityForRow = async () => {
        await updateOrder.mutate()
        await refetch()
        closeAlertModal()
    }

    const orderQuoteInDetail = async () => {
        await createOrder.mutate()
        await refetch()
        closeAlertModal()
    }

    const updateExpectedDiscountForRow = async () => {
        await updateExpDate.mutate()
        await refetch()
        closeAlertModal()
    }

    const handleConfirm = () => {
        switch (alertModal.key) {
            case 'order_opportunity':
                return updateOrderQuantityForRow()
            case 'expected_delivery_date':
                return updateExpectedDiscountForRow()
            case 'status':
                return orderQuoteInDetail()
            default:
                return null
        }
    }

    const columns = [
        {
            name: 'S.No(#)',
            left: true,
            cell: (row: any, index: number) => (
                <h1 className="text-black text-semibold text-base">{index + 1 || 'unknown'}</h1>
            ),
            sortable: true,
            width: '120px',
        },
        {
            name: 'Quote Name',
            left: true,
            wrap: true,
            selector: (row: any) => row?.quotation_name || 0,
            cell: (row: {
                quotation_name: string
                id: string | number
                approve_pending: boolean
                user_id: number
            }) => {
                return (
                    <Link className="flex items-center gap-4" to={`/quotes/${row.id}`}>
                        <p className="text-semibold text-base text-primaryLight">
                            {row?.quotation_name || 'unknown'}
                        </p>
                        {(row.user_id === user.id || userExtra?.is_manager) &&
                        row?.approve_pending ? (
                            <MdNotificationsActive
                                size={16}
                                className="text-primary"
                                title="Approval Request Pending"
                            />
                        ) : null}
                    </Link>
                )
            },
            width: '260px',
            sortable: true,
        },
        {
            name: 'Quote For',
            left: true,
            width: '260px',
            cell: (row: { quotation_for: string }) => {
                return (
                    <h1 className="text-black text-semibold text-base">
                        {row?.quotation_for || 'unknown'}
                    </h1>
                )
            },
            sortable: true,
            selector: (row: { quotation_for: string }) => row.quotation_for,
        },
        {
            name: 'Created At',
            left: true,
            cell: (row: { created_at: string }) => {
                return (
                    <p className="text-black text-semibold text-base">
                        {moment(row.created_at).format(dateFormat) || '-'}
                    </p>
                )
            },
            sortable: true,
            selector: (row: { created_at: string }) => row.created_at,
        },
        {
            name: 'Quantity',
            center: true,
            height: 'fit-content',
            wrap: true,
            cell: (row: any) => {
                const totalConfigurations = row.configurations
                let quantity = 0
                if (totalConfigurations && totalConfigurations.length > 0) {
                    totalConfigurations.forEach((configuration: any) => {
                        quantity += configuration?.quantity || 0
                    })
                }
                return <p className="text-black text-semibold text-lg">{quantity}</p>
            },
        },
        {
            name: 'Created By',
            width: '260px',
            left: true,
            cell: (row: { created_by: string; user_created_by: string }) => {
                return (
                    <p className="text-black text-semibold text-base w-fit">
                        {row?.created_by || row?.user_created_by || '-'}
                    </p>
                )
            },
            sortable: true,
            selector: (row: { created_by: string }) => row.created_by,
        },
        {
            name: 'Order Opportunity',
            left: true,
            selector: (row: { order_opportunity: string }) => row.order_opportunity,
            cell: (row: { order_opportunity: string; id: string }) => {
                return (
                    <Select
                        defaultValue={row?.order_opportunity || '0 %'}
                        size="large"
                        className="rounded-md w-24"
                        onChange={value => {
                            setAlertModal({
                                status: true,
                                key: 'order_opportunity',
                                item: row,
                                newValue: value,
                            })
                        }}
                    >
                        {filter(orderOpportunity, item => item.label !== 'all').map(
                            (item: OrderOpportunityType) => (
                                <Select.Option value={item.value}>{item.label} %</Select.Option>
                            ),
                        )}
                    </Select>
                )
            },
            sortable: true,
        },
        {
            name: 'E Delivery Date',
            left: true,
            selector: (row: { expected_delivery_date: string }) => row.expected_delivery_date,
            cell: (row: {
                expected_delivery_date: string
                id: string
                order_opportunity: string
            }) => {
                if (!row.expected_delivery_date) {
                    return (
                        <DatePicker
                            size="large"
                            className="rounded-md w-52"
                            onChange={(date, dateString) => {
                                setAlertModal({
                                    status: true,
                                    key: 'expected_delivery_date',
                                    item: row,
                                    newValue: dateString,
                                })
                            }}
                        />
                    )
                }
                return (
                    <DatePicker
                        onChange={(date, dateString) => {
                            setAlertModal({
                                status: true,
                                key: 'expected_delivery_date',
                                item: row,
                                newValue: dateString,
                            })
                        }}
                        size="large"
                        className="rounded-md w-52"
                        defaultValue={moment(row.expected_delivery_date, dateFormat)}
                    />
                )
            },
            sortable: true,
        },
        {
            name: 'Status',
            center: true,
            selector: (row: { status: string }) => row.status,
            cell: (row: { status: string; order_opportunity: string; id: string }) => {
                const baseType = String(row.status).split(' ')[0]
                return (
                    <Select
                        value={baseType}
                        size="large"
                        className="rounded-md w-52 uppercase"
                        dropdownStyle={{ textTransform: 'uppercase' }}
                        onChange={value => {
                            setAlertModal({
                                status: true,
                                key: 'status',
                                item: row,
                                newValue: value,
                            })
                        }}
                    >
                        {filter(statusDropDown, item => item.label !== 'all').map(
                            (item: OrderOpportunityType) => (
                                <Select.Option value={item.value}>{item.label}</Select.Option>
                            ),
                        )}
                    </Select>
                )
            },
            sortable: true,
        },
    ]

    const getListOfQuote = (finalList: any) => {
        const query = searchParam?.get('query') || ''
        const opportunity = searchParam?.get('op') || 'all'
        const status = searchParam?.get('st') || 'all'
        const selectedUser = searchParam?.get('us') || 'all'
        let tempDataList: any[] = finalList || []

        if (query === '' && opportunity === 'all' && status === 'all' && selectedUser === 'all')
            return finalList || []

        if (query !== '') {
            const fuse = new Fuse(
                opportunity === 'all' || opportunity === '' ? finalList : tempDataList,
                {
                    shouldSort: true,
                    threshold: 0.1,
                    keys: [
                        'quotation_name',
                        'quotation_for',
                        'brands',
                        'created_by',
                        'user_created_by',
                    ],
                },
            )
            const result = fuse.search(query)
            tempDataList = result.map(item => item.item)
        } else {
            tempDataList = []
        }

        if (opportunity !== 'all' && opportunity !== '0') {
            const filteredList = finalList.filter(
                (item: { order_opportunity: number }) =>
                    item.order_opportunity === Number(opportunity),
            )
            if (filteredList.length > 0) {
                tempDataList = filteredList
            } else {
                tempDataList = finalList || []
            }
        }

        if (status !== 'all') {
            const filteredList = (
                opportunity === 'all' || opportunity === '' ? finalList : tempDataList
            ).filter((item: { status: string }) => item.status === status)
            if (filteredList.length > 0) {
                tempDataList = filteredList
            } else {
                tempDataList = []
            }
        }

        if (selectedUser !== 'all') {
            const filteredList = (
                opportunity === 'all' || opportunity === '' ? finalList : tempDataList
            ).filter((item: { user_name: string }) => item.user_name === selectedUser)
            if (filteredList.length > 0) {
                tempDataList = filteredList
            } else {
                tempDataList = []
            }
        }

        return tempDataList
    }

    return (
        <div className={classnames(cardClass)}>
            <Tabs
                initialTab={activeTab}
                tabs={tabs || []}
                getActiveTab={(tab: TabType) => {
                    setActiveTab(tab)
                    refetch()
                }}
                leftContent={
                    <div className="hidden md:flex flex-1 w-full flex-col md:flex-row gap-4 justify-end md:items-center">
                        <div className="w-full md:w-auto">
                            <Select
                                value={searchParam.get('op') || 'all'}
                                size="large"
                                className="w-full md:w-64 rounded-md uppercase"
                                dropdownStyle={{ textTransform: 'uppercase' }}
                                placeholder="Opportunity"
                                onChange={onChangeOpportunity}
                            >
                                {orderOpportunity.map((item: OrderOpportunityType) => (
                                    <Select.Option value={item.value}>{item.label}</Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className="w-full md:w-auto">
                            <Select
                                value={searchParam.get('st') || 'all'}
                                size="large"
                                className="w-full md:w-64 rounded-md uppercase"
                                dropdownStyle={{ textTransform: 'uppercase' }}
                                placeholder="Status"
                                onChange={onChangeStatus}
                            >
                                {statusSelectList.map((item: OrderOpportunityType) => (
                                    <Select.Option value={item.value}>{item.label}</Select.Option>
                                ))}
                            </Select>
                        </div>

                        {activeTab && activeTab.value === 'team-quotes' && (
                            <div className="w-full md:w-auto">
                                <Select
                                    value={searchParam.get('us') || 'all'}
                                    size="large"
                                    className="w-full md:w-64 rounded-md uppercase"
                                    dropdownStyle={{ textTransform: 'uppercase' }}
                                    placeholder="User"
                                    onChange={onUserChange}
                                >
                                    {userList.map((item: OrderOpportunityType) => (
                                        <Select.Option value={item.value}>
                                            {item.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        )}

                        <Input
                            placeholder="Search Quotes"
                            size="large"
                            className="rounded-md"
                            defaultValue={searchParam.get('query') || ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const params = new URLSearchParams(searchParam)
                                if (e.target.value === '' || e.target.value === undefined) {
                                    params.delete('query')
                                } else {
                                    params.set('query', e.target.value)
                                }
                                setSearchParams(params)
                            }}
                        />
                        <div className="w-full md:w-auto">
                            <Button
                                id="create-quote"
                                label="Create Quote"
                                variant="primary"
                                onClick={() => navigate('/quotes/create')}
                            />
                        </div>
                    </div>
                }
            />
            <Table
                columns={columns}
                // data={filter(dataList, item => item?.status !== 'pending order') || []}
                data={getListOfQuote(data?.data || [])}
                loading={isLoading}
                striped
                pagination
                totalRows={25}
                expandableRows={Boolean(userExtra?.is_manager)}
                ExpandedComponent={(rowData: any) => {
                    return (
                        <ExpandQuoteComponent
                            quoteId={rowData?.data?.id}
                            userId={rowData?.data?.user_id}
                            configurations={rowData?.data?.configurations}
                            callback={refetch}
                        />
                    )
                }}
            />

            <AlertModal
                show={
                    alertModal.status &&
                    alertModal.key === 'status' &&
                    alertModal.newValue !== 'ordered'
                }
                content={
                    <>
                        <p className="text-center text-lg">Status Change Reason</p>

                        <div className="bg-red-200 p-3 rounded-md mt-4">
                            <p className="mt-2 text-left text-sm">
                                Status to be changed from{' '}
                                <strong>{alertModal?.item?.status} </strong> to{' '}
                                <strong>{alertModal?.newValue}</strong>.
                            </p>
                            <p className="text-left text-sm">
                                Why do you want to change the status of this quote?{' '}
                                <strong>{alertModal?.item.quotation_name}</strong>
                            </p>
                        </div>

                        <div className="mt-4">
                            <Form.Item
                                name="generic_id"
                                className="flex flex-col"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <h5 className="capitalize text-lg text-left mb-2">Reason</h5>
                                <Select
                                    onChange={(value: string) =>
                                        genericQuestionFormik.setFieldValue('generic_id', value)
                                    }
                                    value={genericQuestionFormik.values.generic_id}
                                    className="rounded-lg"
                                    size={size}
                                >
                                    <Select.Option value="">Choose option</Select.Option>
                                    {map(genericeQuestion?.data?.data || [], (option: any) => (
                                        <Select.Option value={option.generic_id}>
                                            {option.generic_question}
                                        </Select.Option>
                                    ))}
                                </Select>
                                {genericQuestionFormik.errors &&
                                    genericQuestionFormik.errors.generic_id &&
                                    genericQuestionFormik.touched.generic_id && (
                                        <p className="text-red-600 mt-1">
                                            {genericQuestionFormik.errors.generic_id}
                                        </p>
                                    )}
                            </Form.Item>

                            <Form.Item
                                name="quotation_name"
                                className="flex flex-col"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <h5 className="capitalize text-lg text-left mb-2">
                                    Custom Message
                                </h5>
                                <Input
                                    name="custom_comment"
                                    value={genericQuestionFormik.values.custom_comment}
                                    onChange={genericQuestionFormik.handleChange}
                                    placeholder="Enter custom message"
                                    size={size}
                                    className={roundedClass}
                                />
                                {genericQuestionFormik.errors &&
                                    genericQuestionFormik.errors.custom_comment &&
                                    genericQuestionFormik.touched.custom_comment && (
                                        <p className="text-red-600 mt-1">
                                            {genericQuestionFormik.errors.custom_comment}
                                        </p>
                                    )}
                            </Form.Item>
                        </div>
                    </>
                }
                isSubmitting={createOrder.isLoading}
                onConfirm={() => genericQuestionFormik.handleSubmit()}
                onCancel={() => closeAlertModal()}
            />

            <AlertModal
                show={
                    (alertModal.status && alertModal.key !== 'status') ||
                    (alertModal.status && alertModal.newValue === 'ordered')
                }
                content={
                    <div className="text-xl">
                        Are your sure you want change the key {alertModal.key} to:{' '}
                        {alertModal.newValue}
                    </div>
                }
                isSubmitting={updateOrder.isLoading || updateExpDate.isLoading}
                onConfirm={() => handleConfirm()}
                onCancel={() => closeAlertModal()}
            />
        </div>
    )
}

export default Quotes
