import { AiOutlineUser, AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { useFormik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'

import Input from 'components/input'
import Button from 'components/button'
import { FORGET_PASSWORD_ROUTE } from 'route/appRoutes'
import { LoginDataProps, useAuth } from '../context/useAuth'
import useTranslationData from '../../translation/hooks/useTranslationData'

const LoginForm = () => {
    const { loginUser, loading } = useAuth()

    const {
        translation: { t },
    } = useTranslationData()

    const formik: FormikProps<LoginDataProps> = useFormik<LoginDataProps>({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Enter the valid email').required('Email is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: async (values, { setSubmitting }: FormikHelpers<LoginDataProps>) => {
            loginUser(values)
            setSubmitting(false)
        },
    })

    const [showPass, setShowPass] = useState<boolean>(false)
    return (
        <form className="bg-transparent rounded-md flex flex-col gap-4 w-auto flex-1">
            <div>
                <h1 className="text-2xl text-center font-medium">{t('api.login_text')}</h1>
            </div>
            <div className="w-full flex-1">
                <Input
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name="email"
                    placeholder="Email"
                    label="Email"
                    rightContent={
                        <span>
                            <AiOutlineUser />
                        </span>
                    }
                />
                {formik.touched.email && formik.errors.email && (
                    <p className="text-validation-warning">{formik.errors.email}</p>
                )}
            </div>
            <div>
                <Input
                    type={showPass ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    name="password"
                    label="Password"
                    rightContent={
                        <span onClick={() => setShowPass(!showPass)} className="cursor-pointer">
                            {showPass ? <AiFillEyeInvisible /> : <AiFillEye />}
                        </span>
                    }
                />
                {formik.touched.password && formik.errors.password && (
                    <p className="text-validation-warning">{formik.errors.password}</p>
                )}
            </div>
            <div className="text-right">
                <a href={FORGET_PASSWORD_ROUTE} className="link text-sm">
                    {t('api.forgot_password')}
                </a>
            </div>
            <div className="my-2">
                <Button
                    label="Login"
                    variant="primary"
                    isLoading={Boolean(loading) || Boolean(formik.isSubmitting)}
                    onClick={() => formik.handleSubmit()}
                />
            </div>
        </form>
    )
}

export default LoginForm
