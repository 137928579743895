import React from 'react'
import 'react-responsive-modal/styles.css'

import Table from '../../../components/table'
import { IAllRepliedNotificationUserName } from '../models/Discounts'

interface IProps {
    data: IAllRepliedNotificationUserName[]
}

const columns = [
    {
        name: 'Requested By',
        cell: (row: { requested_by_name: string }) => {
            return <span className="font-bold py-2">{row.requested_by_name}</span>
        },

        left: true,
    },
    {
        name: 'Requested Discount %',
        cell: (row: { requested_discount_percentage: string }) => {
            return <span className="font-bold">{row.requested_discount_percentage}</span>
        },
        center: true,
    },
    {
        name: 'Reason for discount requested',
        minWidth: '100px',
        height: 'fit-content',
        wrap: true,
        hide: 'sm',
        selector: (row: { requested_comment: string }) => row.requested_comment,
        left: true,
    },
    {
        name: 'Approved Discount %',
        cell: (row: { provided_discount: string }) => {
            return <span className="font-bold">{row.provided_discount}</span>
        },
        center: true,
    },
    {
        name: 'Configuration Name',
        minWidth: '100px',
        height: 'fit-content',
        wrap: true,
        selector: (row: { configuration_name: string }) => row.configuration_name,
        left: true,
    },
    {
        name: 'Reply Comment',
        minWidth: '100px',
        height: 'fit-content',
        wrap: true,
        hide: 'sm',
        selector: (row: { comment: string }) => row.comment,
        left: true,
    },
    {
        name: 'Requested At',
        hide: 'sm',
        cell: (row: { requested_at: string }) => {
            return <span className="font-bold">{row.requested_at}</span>
        },
        center: true,
    },
    {
        name: 'Replied By',
        cell: (row: { replied_by_name: string }) => {
            return <span className="font-bold">{row.replied_by_name}</span>
        },
        left: true,
    },
    {
        name: 'Replied At',
        hide: 'sm',
        cell: (row: { published_at: string }) => {
            return <span className="font-bold">{row.published_at}</span>
        },
        center: true,
    },
    {
        name: 'Configuration List',
        hide: 'sm',
        cell: (row: { requested_configuration_id: number }) => {
            return (
                <a
                    href={`configuration/${row.requested_configuration_id}`}
                    className="flex items-center justify-start gap-3 w-full py-2 px-4 text-sm text-primary"
                >
                    <span>See Configuration</span>
                </a>
            )
        },
        center: true,
    },
]

const DiscountHistory = ({ data }: IProps) => {
    return <Table columns={columns} data={data || []} striped pagination totalRows={10} />
}

export default DiscountHistory
