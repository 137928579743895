import { callAxios } from 'plugins/api'

const articlesApi = 'article'

const getPrivacyStatement = () => {
    return callAxios({
        url: `${articlesApi}/1`,
        method: 'GET',
    })
}

const getDisclaimer = () => {
    return callAxios({
        url: `${articlesApi}/2`,
        method: 'GET',
    })
}

export const ArticleQueries = {
    getPrivacyStatement,
    getDisclaimer,
}
