import { callAxios } from 'plugins/api'

const dashboardCountApi = 'dashboard-count/'
const discountCountApi = 'discount-notification/discount-count/'

const getDashboardCount = (params: { userId: number }) => {
    return callAxios({
        url: `${dashboardCountApi}${params.userId}`,
        method: 'GET',
    })
}
const getDiscountCount = (params: { userId: number }) => {
    return callAxios({
        url: `${discountCountApi}${params.userId}`,
        method: 'GET',
    })
}

export const DashboardQueries = {
    getDashboardCount,
    getDiscountCount,
}
