/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Select, TreeSelect } from 'antd'
import Button from 'components/button'
import { useFormik } from 'formik'
import { find, map } from 'lodash'
import { useState } from 'react'
import * as Yup from 'yup'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { errorToast, successToast } from 'utils/toasterUtil'
import { useAuth } from 'modules/auth/context/useAuth'
import {
    addMoreConfigurationOnQuote,
    getAllConfigurationForQuote,
    getAllTemplateForQuote,
} from '../queries/quotes'
import { useQuoteLayout } from '../QuoteLayout'

const size = 'large'
const roundedClass = 'rounded-lg flex-1'
export const AddConfigurationOnQuote = ({ callback }: { callback: () => void }) => {
    const { quoteDetail } = useQuoteLayout()
    const { user, userExtra } = useAuth()
    const [configurationListForQuote, setConfigurationListForConfiguration] = useState([])
    const [templates, setTemplates] = useState([])

    const configurationList = useQuery(
        ['configurationListForQuote'],
        () => getAllConfigurationForQuote(user.id),
        {
            onSuccess: data => {
                setConfigurationListForConfiguration(data.data)
            },
            onError: (error: any) => {
                toast(error?.message || 'Failed to fetch configuration', errorToast)
            },
        },
    )

    const templateList = useQuery(
        ['templateListForQuote'],
        () => getAllTemplateForQuote(userExtra.role_id),
        {
            onSuccess: data => {
                const templateOptions: any = map(data.data.selected_templates, item => {
                    return {
                        value: item.template_id,
                        label: item.template_name,
                    }
                })
                setTemplates(templateOptions)
            },
            onError: (error: any) => {
                toast(error?.message || 'Failed to fetch templates', errorToast)
            },
        },
    )

    const higherDiscountRequest = useMutation(
        ['addMoreConfigurationOnQuote'],
        (configurationQuoteDetail: any) =>
            addMoreConfigurationOnQuote(configurationQuoteDetail, user.id),
        {
            onSuccess: data => {
                toast(data?.data?.message || 'Configuration added', successToast)
                callback()
            },
            onError: (error: any) => {
                toast(error?.message || 'Failed to add configuration', errorToast)
            },
        },
    )

    const formik = useFormik({
        initialValues: {
            quotation_for: quoteDetail.quotation_for,
            quotation_name: quoteDetail.quotation_name,
            quote_id: quoteDetail.id,
            status: quoteDetail.status,
            configurations: [],
            template: '',
        },
        validationSchema: Yup.object().shape({
            configurations: Yup.array().min(1).required('Please select at least one configuration'),
            template: Yup.string().required('Please select a template'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true)
            await higherDiscountRequest.mutate(values)
            setSubmitting(false)
            resetForm()
        },
    })

    const getConfigurationLabel = (item: number) => {
        const config: any = find(configurationListForQuote, { value: item })
        return config?.label || item
    }

    const getTemplateLabel = (item: string) => {
        const config: any = find(templates, { value: item })
        return config?.label || item
    }

    if (configurationList.isLoading || templateList.isLoading) {
        return <p>Loading</p>
    }

    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <label>Select Configuration</label>
                <TreeSelect
                    className={`${roundedClass} search`}
                    showSearch
                    treeData={configurationListForQuote}
                    placeholder="Please select a configuration"
                    treeDefaultExpandAll
                    allowClear
                    virtual={false}
                    multiple
                    size={size}
                    value={formik.values.configurations}
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    onChange={values =>
                        formik.setFieldValue(
                            'configurations',
                            values.map(item => {
                                return {
                                    value: item,
                                    label: getConfigurationLabel(item),
                                }
                            }),
                            true,
                        )
                    }
                />
                {formik.errors && formik.errors.configurations && formik.touched.configurations && (
                    <p className="text-red-600 mt-1">{formik.errors.configurations}</p>
                )}
            </div>
            <div className="flex flex-col gap-2">
                <label>Select Template</label>
                <Select
                    defaultValue={formik.values.template}
                    className="rounded-lg"
                    size={size}
                    showSearch
                    virtual={false}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={value => formik.setFieldValue('template', value, true)}
                >
                    {map(templates || [], (option: any) => (
                        <Select.Option value={option.value} key={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
                {formik.errors && formik.errors.template && formik.touched.template && (
                    <p className="text-red-600 mt-1">{formik.errors.template}</p>
                )}
            </div>

            <div className="border border-slate-300 rounded-md">
                <p className="py-2 px-4 text-xl">Overview</p>
                <hr />
                <div className="py-2 px-4">
                    <table className="table-auto w-[100%]">
                        <tbody>
                            <tr>
                                <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    Quote Name
                                </th>
                                <td className="py-2 font-bold text-md text-gray-900 whitespace-nowrap text-right">
                                    {quoteDetail?.quotation_name || ''}
                                </td>
                            </tr>
                            <tr>
                                <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    Quote For
                                </th>
                                <td className="py-2 font-bold text-md text-gray-900 whitespace-nowrap text-right">
                                    {quoteDetail?.quotation_for || ''}
                                </td>
                            </tr>
                            <tr>
                                <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    Total Configuration List
                                </th>
                                <td className="py-2 font-bold text-md text-gray-900 whitespace-nowrap text-right">
                                    {formik.values.configurations.length}
                                </td>
                            </tr>
                            <tr>
                                <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    Template
                                </th>
                                <td className="py-2 font-bold text-md text-gray-900 whitespace-nowrap text-right">
                                    {getTemplateLabel(formik.values.template) || 'n/a'}
                                </td>
                            </tr>
                            <tr>
                                <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    Selected Configuration
                                </th>
                                <td className="py-2 font-bold text-md text-gray-900 whitespace-nowrap text-right flex flex-col">
                                    {map(formik.values.configurations, (item: any) => (
                                        <span key={item.value}>{item.label}</span>
                                    ))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex justify-end">
                <div className="w-fit">
                    <Button label="Confirm" onClick={() => formik.handleSubmit()} />
                </div>
            </div>
        </div>
    )
}
