import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { useAuth } from '../../auth/context/useAuth'
import DownloadsTable from '../components/DownloadsTable'
import { IDownloadTable } from '../models/DownloadTable'
import { PoliciesQueries } from '../queries/policies'

const Policies = () => {
    const { userExtra } = useAuth()
    const [tableData, setTableData] = useState<IDownloadTable[] | []>([])
    const {
        data: policies,
        isLoading,
        isFetching,
    } = useQuery(
        ['getBulletins'],
        () => PoliciesQueries.getPoliciesByRole({ roleId: userExtra.role_id }),
        {
            refetchInterval: false,
            refetchIntervalInBackground: false,
        },
    )

    useEffect(() => {
        const tableDataMap: IDownloadTable[] = policies?.data.map((item: any) => {
            return {
                name: item.policy_name,
                link: item.policy_document,
            }
        })
        setTableData(tableDataMap)
    }, [policies])

    return (
        <DownloadsTable
            heading="Policy Documents"
            data={tableData}
            isLoading={isLoading}
            isFetching={isFetching}
        />
    )
}

export default Policies
