/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { concat } from 'lodash'

export interface BreadCrumbType {
    link: string
    label: string
}

const breadCrumbContext = createContext({
    breadcrumb: [],
} as {
    breadcrumb: BreadCrumbType[]
})

const { Provider } = breadCrumbContext

const useBreadCrumbProvider = () => {
    const location = useLocation()
    const defaultBreadcrumb = {
        label: 'home',
        link: '/home',
    }

    const breadCrumbData: any = {
        '/home': [{ link: '/home', label: 'home' }],
        '/configuration': [{ link: '/configuration', label: 'configuration' }],
        '/configuration/create': [
            { link: '/configuration', label: 'configuration' },
            { link: '/configuration/create', label: 'create' },
        ],
        '/order': [{ link: '/order', label: 'order' }],
    }

    const [breadcrumb, setBreadcrumb] = useState<BreadCrumbType[]>([])

    const updateBreadCrumb = (newBreadcrumb: BreadCrumbType[]) => {
        setBreadcrumb(concat(defaultBreadcrumb, newBreadcrumb))
    }

    const getBreadCrumb = (path: string) => {
        switch (path) {
            case '/':
                return setBreadcrumb([defaultBreadcrumb])
            case '/home':
                return setBreadcrumb([defaultBreadcrumb])
            case '/configuration':
                return updateBreadCrumb(breadCrumbData['/configuration'])
            default:
                return breadcrumb
        }
    }

    useEffect(() => {
        if (location && location.pathname) {
            getBreadCrumb(location.pathname)
        }
    }, [location.pathname])

    return { breadcrumb }
}

export default useBreadCrumbProvider

export const BreadCrumbProvider = ({ children }: { children: React.ReactNode }) => {
    const breadCrumbData = useBreadCrumbProvider()
    return <Provider value={breadCrumbData}>{children}</Provider>
}

export const useBreadCrumb = () => useContext(breadCrumbContext)
