import DeleteModal from 'components/modal/deleteModal'
import { useState } from 'react'
import { AiOutlineDelete, AiOutlineEye, AiOutlineHistory } from 'react-icons/ai'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { errorToast, successToast } from 'utils/toasterUtil'
import { removeCongiurationFromQuotes } from '../queries/quotes'

const QuoteAction = ({
    configuration,
    isSameUser,
    refetch,
    quote,
}: {
    configuration: any
    isSameUser: boolean
    refetch: () => void
    quote?: string
}) => {
    const { quoteId } = useParams()
    const navigate = useNavigate()
    const [removeConfiguration, setRemoveConfiguration] = useState(false)
    const requestConfiguration = useMutation(
        ['approveQuoteRequest'],
        () =>
            removeCongiurationFromQuotes({
                quoteId: quote || quoteId,
                configurationId: configuration.id,
            }),
        {
            onSuccess: async (data: any) => {
                await refetch()
                toast(data?.message || ` Configuration Removed Successfully`, successToast)
                setRemoveConfiguration(false)
            },
            onError: (error: any) => {
                toast(error?.message || `Failed to remove Configuration`, errorToast)
            },
        },
    )

    const handleRemoveConfigurationRemove = async () => {
        await requestConfiguration.mutate()
    }

    return (
        <div className="flex gap-4 items-center">
            <div
                title="View Configutration"
                className=" cursor-pointer hover:text-primary text-lg"
                onClick={() => navigate(`/quotes/${quote || quoteId}/${configuration.id}`)}
            >
                <AiOutlineEye size={30} className="text-placeholderColor hover:text-primary" />
            </div>
            <div
                title="ReviewConfiguration History"
                className=" cursor-pointer hover:text-primary text-lg"
                onClick={() => navigate(`/quotes/${quote || quoteId}/${configuration.id}/history`)}
            >
                <AiOutlineHistory size={25} className="text-placeholderColor hover:text-primary" />
            </div>
            {isSameUser && (
                <div
                    title="Remove Configuration"
                    className=" cursor-pointer hover:text-primary text-lg"
                    onClick={() => setRemoveConfiguration(true)}
                >
                    <AiOutlineDelete
                        size={25}
                        className="text-placeholderColor hover:text-primary"
                    />
                </div>
            )}

            <DeleteModal
                modal={removeConfiguration}
                closeModal={() => setRemoveConfiguration(!removeConfiguration)}
                label="Are you sure you want to remove this ?"
                title={`Configuration: ${configuration.name}`}
                onConfirm={handleRemoveConfigurationRemove}
                loading={requestConfiguration.isLoading}
            />
        </div>
    )
}

export default QuoteAction
