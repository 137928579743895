/* eslint-disable camelcase */
import { callAxios } from 'plugins/api'
import { getUserRole, UserRole } from 'utils/userRole'

// const user = JSON.parse(localStorage.getItem('CPQ_LOGGED_IN_USER') || '{}')
// const userInfo = JSON.parse(localStorage.getItem('CPQ_LOGGED_IN_USER_EXTRA_INFO') || '{}')

// const getApi = () => {
//     const role: UserRole = getUserRole()
//     switch (role) {
//         case 'sales-support':
//             return 'sales-support/'
//         default:
//             return 'quote/user'
//     }
// }

export interface ICreateQuote {
    configurations: any
    quotation_for: string
    quotation_name: string
    template: number | string
    user?: string | number
    status?: string
}

export const getAllQuotes = (
    userId: string | number,
    isSupport: boolean,
    isDealer: boolean,
    isManager: boolean,
    roleId: number,
) => {
    const role: UserRole = getUserRole(isSupport, isDealer, isManager, roleId)
    if (role === 'sales-support') {
        return callAxios({
            url: `sales-support/getAllQuotesSalesSupport/${userId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `quote/user/${userId}`,
        method: 'GET',
    })
}

export const updateOrderOpportunity = (id: string, value: string | number) => {
    return callAxios({
        url: `quote/editOrderOpportunity/${id}`,
        method: 'POST',
        data: {
            order_opportunity: value,
        },
    })
}

export const updateExpectedDiscount = (id: string, value: string) => {
    return callAxios({
        url: `quote/editExpectedDeliveryDate/${id}`,
        method: 'POST',
        data: {
            expected_delivery_date: value,
        },
    })
}

export const updateQuoteStatus = (id: string, formData: any, userId: number) => {
    return callAxios({
        url: `quote/changeStatusOfQuote/${userId}/${id}`,
        method: 'POST',
        data: {
            custom_comment: formData.custom_comment,
            generic_id: formData.generic_id,
            status: formData.status,
        },
    })
}

export const updateQuoteTemplate = (id: string, value: string) => {
    return callAxios({
        url: `quote/updateTemplateForQuote/${id}`,
        method: 'POST',
        data: {
            template_id: value,
        },
    })
}

export const orderQuoteData = (formData: any, userId: number) => {
    return callAxios({
        url: `order/${userId}`,
        method: 'POST',
        data: {
            configuration_id: formData.configuration_id,
            order_name: `${formData.quotation_name}-order`,
            quote_id: formData.quote_id,
        },
    })
}

export const createQuote = (formData: ICreateQuote, userId: number) => {
    return callAxios({
        url: `quote`,
        method: 'POST',
        data: {
            ...formData,
            user_id: userId || formData?.user,
        },
    })
}

export const deleteQuote = (quoteId: string) => {
    return callAxios({
        url: `quote/delete/${quoteId}`,
        method: 'GET',
    })
}

export const editQuote = (quoteId: number | string, param: any) => {
    return callAxios({
        url: `quote/edit/${quoteId}`,
        data: param,
        method: 'POST',
    })
}

export const approveQuote = (quoteId: string, userId: number) => {
    return callAxios({
        url: `quote/approvedQuoteNotification/${quoteId}/${userId}`,
        method: 'POST',
    })
}

export const requestQuoteApproval = (quoteId: number, userId: number) => {
    return callAxios({
        url: `quote/requestApprovalNotification/${quoteId}/${userId}`,
        method: 'POST',
    })
}

export const orderQuote = (quoteId: string) => {
    return callAxios({
        url: `quote/checkQuoteOrdered/${quoteId}`,
        method: 'GET',
    })
}

export const getQuoteDetail = (
    quoteId: string,
    userId: number,
    isSupport: boolean,
    isDealer: boolean,
    isManager: boolean,
    roleId: number,
) => {
    const role: UserRole = getUserRole(isSupport, isDealer, isManager, roleId)
    if (role === 'sales-support') {
        return callAxios({
            url: `sales-support/getSingleQuoteSalesSupport/${userId}/${quoteId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `quote/user/${userId}/${quoteId}`,
        method: 'GET',
    })
}

export const getAllSelectedTemplate = (role: string | number) => {
    return callAxios({
        url: `template/getCurrentTemplatesForRole/${role}`,
        method: 'GET',
    })
}

export const getAllConfigurationTemplate = (userId: string) => {
    return callAxios({
        url: `configuration/getUserConfigurationsForMultiselect/${userId}`,
        method: 'GET',
    })
}

export const getGenericQuestion = () => {
    return callAxios({
        url: `generic-question`,
        method: 'GET',
    })
}

export const getTeamsQuotes = (userId: number) => {
    return callAxios({
        url: `user-hierarchy/showChildrenQuotes/${userId}`,
        method: 'GET',
    })
}

export const requestMultipleDiscountRequest = (discountRequestData: any, userId: number) => {
    return callAxios({
        url: `discount-notification/QuotesDiscount/${userId}`,
        method: 'POST',
        data: discountRequestData,
    })
}

export const getAllConfigurationForQuote = (userId: number) => {
    return callAxios({
        url: `configuration/getUserConfigurationsForMultiselect/${userId}`,
        method: 'GET',
    })
}

export const getAllTemplateForQuote = (roleId: number) => {
    return callAxios({
        url: `template/getCurrentTemplatesForRole/${roleId}`,
        method: 'GET',
    })
}

export const addMoreConfigurationOnQuote = (configurationQuoteDetail: any, userId: number) => {
    return callAxios({
        url: `quote/edit/${configurationQuoteDetail.quote_id}`,
        method: 'POST',
        data: {
            ...configurationQuoteDetail,
            user_id: userId,
        },
    })
}

export const removeCongiurationFromQuotes = ({
    quoteId,
    configurationId,
}: {
    quoteId: any
    configurationId: string
}) => {
    return callAxios({
        url: `quote/removeConfigurationFromQuote/${quoteId}/${configurationId}`,
        method: 'GET',
    })
}

export const getProjectOverview = (quoteId: string | number) => {
    return callAxios({
        url: `getProjectOverviewData/${quoteId}`,
        method: 'GET',
    })
}

export const updateDiscountForConfiguration = (formData: {
    comments: string
    discount_amount: number
    discount_percentage: number
    machine_total_price: number
    quantity: number
    service_rate: number
    trade_in_value: number
    configuration_id: string
}) => {
    return callAxios({
        url: `configuration/updateDiscountForConfiguration/${formData.configuration_id}`,
        method: 'POST',
        data: {
            machine_total_price: formData.machine_total_price,
            discount_amount: formData?.discount_amount || 0,
            discount_percentage: formData?.discount_percentage || 0,
            quantity: formData?.quantity || 0,
            trade_in_value: formData?.trade_in_value || 0,
            service_rate: formData?.service_rate || 0,
            comments: formData?.comments || null,
        },
    })
}

export const downloadProjectOverviewDoc = async (
    quoteID: string | number,
    params: {
        export_array: string[]
        projectOverviewData: any
    },
) => {
    const res = await callAxios({
        url: `quote/exportProjectOverView/${quoteID}`,
        method: 'POST',
        data: {
            export_array: params.export_array,
            projectOverviewData: params?.projectOverviewData?.configurations,
        },
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
    })
    const bolbFile = new Blob([res.data])
    const url = URL.createObjectURL(bolbFile)
    const link = document.createElement('a')
    document.body.appendChild(link)
    link.href = url
    link.setAttribute('download', `${params?.projectOverviewData?.quote[0]?.quotation_name}.xlsx`)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
    return res?.data
}
