import { callAxios } from 'plugins/api'
import { getUserRole, UserRole } from 'utils/userRole'
import { ManualAdjustmentType } from '../views/createConfiguration'

export interface ConfigurationType {
    user_id: unknown
    configuration_name: string
    only_parts: boolean
    machine_name: string
    machine_total_price: number
    machine_category: number
    machine_category_year_price_count: number
    machine_category_cost_year_price_count: number
    discount_amount: number
    dealer_discount_amount: number
    oem_discount: number
    extra_discount: number
    sales_margin: number
    sales_margin_amount: number
    end_user_sales_price: number
    landed_cost: number
    quantity: number
    discount_percentage: number
    selected_parts: any
    manual_adjustments: ManualAdjustmentType[]
    is_library: boolean
    is_private: boolean
    country_id: number
    trade_in_value?: number
}

const getConfiguration = (
    userId: number,
    isSupport: boolean,
    isDealer: boolean,
    isManager: boolean,
    roleId: number,
) => {
    const role: UserRole = getUserRole(isSupport, isDealer, isManager, roleId)
    if (role === 'sales-support') {
        return callAxios({
            url: `sales-support/getAllConfigurationsSalesSupport/${userId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `configuration/user/${userId}`,
        method: 'GET',
    })
}

const getUserConfigurations = (params: { userId: string | number }) => {
    return callAxios({
        url: `configuration/user/${params.userId}`,
        method: 'GET',
    })
}

const getById = (params: {
    configurationId: string
    edit?: boolean
    is_support?: boolean
    userId: number
}) => {
    return callAxios({
        url: `${
            params.is_support
                ? 'sales-support/getSingleConfigurationSalesSupport'
                : 'configuration/user'
        }${params.edit ? '/edit' : ''}/${params.userId}/${params.configurationId}`,
        method: 'GET',
    })
}

const getLibraryConfigurationById = (params: {
    configurationId: string
    edit?: boolean
    role: string
}) => {
    return callAxios({
        url: `${params.role ? `configuration/library/user` : 'configuration/library/user'}${
            params.edit ? '/edit' : ''
        }/${params.configurationId}`,
        method: 'GET',
    })
}

const getByIdForLibrary = (params: { configurationId: string; edit?: boolean }) => {
    return callAxios({
        url: `configuration/library/user/single/${params.edit ? 'edit/' : ''}${
            params.configurationId
        }`,
        method: 'GET',
    })
}

const getMachineDetailAndFeature = (params: { configurationId: number }) => {
    return callAxios({
        url: `machine-category/detailsWithSpecificationAndFeatures/${params.configurationId}`,
        method: 'GET',
    })
}

const getLastConfiguration = () => {
    return callAxios({
        url: `configuration/get/lastConfiguration`,
        method: 'GET',
    })
}

const getDetailById = (params: { configurationId: number }) => {
    return callAxios({
        url: `machine-category/${params.configurationId}`,
        method: 'GET',
    })
}

const getSequenceByID = (params: { configurationId: number }) => {
    return callAxios({
        url: `sequence/${params.configurationId}`,
        method: 'GET',
    })
}

const changeLibraryConfigurationStatus = (params: {
    configurationId: number
    configurationStatus: number
}) => {
    return callAxios({
        url: `configuration/changeLibraryConfigurationStatus/${params.configurationId}`,
        method: 'POST',
        data: {
            is_private: params.configurationStatus,
        },
    })
}

const getStatusOfLibraryConfiguration = (params: { configurationId: number }) => {
    return callAxios({
        url: `configuration/getStatusOfLibraryConfiguration/${params.configurationId}`,
        method: 'GET',
    })
}

const getUserHasChildren = (params: { userId: number }) => {
    return callAxios({
        url: `auth/hasChildren/${params.userId}`,
        method: 'GET',
    })
}

const getCompatibleMachinePartsWithQuantity = (params: {
    configurationId: number
    countryId: number
}) => {
    return callAxios({
        url: `compatibility/getCompatibleMachinePartsWithQuantity/${params.configurationId}/${params.countryId}`,
        method: 'GET',
    })
}

const getMaxDiscountByRoleId = (params: { roleId: number; machineCategoryNumber: number }) => {
    return callAxios({
        url: `machine-category/getMachineCategoryMaxDiscountByRole/${params.roleId}/${params.machineCategoryNumber}`,
        method: 'GET',
    })
}

const getCompanyRates = () => {
    return callAxios({
        url: 'company-rates',
        method: 'GET',
    })
}

const createConfigurations = (params: { configuration: ConfigurationType }) => {
    return callAxios({
        url: `configuration${params.configuration.is_library ? '/libraryConfiguration' : ''}`,
        method: 'POST',
        data: params.configuration,
    })
}

const getChildrenConfiguration = (params: { userId: number }) => {
    return callAxios({
        url: `user-hierarchy/showChildrenConfigurations/${params.userId}`,
        method: 'GET',
    })
}

const getParentLibraryConfiguration = (params: { userId: number }) => {
    return callAxios({
        url: `configuration/getParentLibraryConfiguration/${params.userId}`,
        method: 'GET',
    })
}

const editLibraryConfiguration = (params: {
    configurationId: number
    configuration: ConfigurationType
}) => {
    return callAxios({
        url: `configuration/edit/libraryConfiguration/${params.configurationId}`,
        method: 'POST',
        data: params.configuration,
    })
}

const editConfiguration = (params: {
    configurationId: number
    configuration: ConfigurationType
}) => {
    return callAxios({
        url: `configuration/edit/${params.configurationId}`,
        method: 'POST',
        data: params.configuration,
    })
}

const deleteConfiguration = (params: { configurationId: number; tag?: string }) => {
    if (params.tag === 'library') {
        return callAxios({
            url: `configuration/delete-library/${params.configurationId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `configuration/delete/${params.configurationId}`,
        method: 'GET',
    })
}

const checkIfConfigurationIsOrdered = (params: { configurationId: string }) => {
    return callAxios({
        url: `configuration/checkIfConfigurationIsOrderd/${params.configurationId}`,
        method: 'GET',
    })
}

// https://test-cpq.nextlevelvalue.com/api/discount-notification/requestDiscountNotification/10

const requestHigherDiscount = (params: {
    user_id: string
    requested_comment: string
    requested_configuration_id: string
    requested_discount_percentage: string
}) => {
    return callAxios({
        url: `discount-notification/requestDiscountNotification/${params.user_id}`,
        method: 'POST',
        data: {
            requested_comment: params.requested_comment,
            requested_configuration_id: params.requested_configuration_id,
            requested_discount_percentage: params.requested_discount_percentage,
        },
    })
}

const checkIfConfigurationIsOrderd = (params: { configurationId: string }) => {
    return callAxios({
        url: `configuration/checkIfConfigurationIsOrderd/${params.configurationId}`,
        method: 'GET',
    })
}

const checkIfConfigurationNameExist = (params: { configurationName: string }) => {
    return callAxios({
        url: `configuration/checkIfConfigurationNameExists`,
        data: {
            name: params.configurationName,
        },
        method: 'POST',
    })
}

const getUserList = () => {
    return callAxios({
        url: `user-hierarchy/getTeamAndChildUser`,
        method: 'GET',
    })
}

export const ConfigurationQueries = {
    getConfiguration,
    getUserConfigurations,
    configurationDetail: getById,
    configurationDetailLibrary: getByIdForLibrary,
    getMachineDetailAndFeature,
    getLastConfiguration,
    getDetailById,
    getSequenceByID,
    getCompatibleMachinePartsWithQuantity,
    getCompanyRates,
    getMaxDiscountByRoleId,
    createConfigurations,
    getChildrenConfiguration,
    editLibraryConfiguration,
    editConfiguration,
    deleteConfiguration,
    checkIfConfigurationIsOrdered,
    getParentLibraryConfiguration,
    requestHigherDiscount,
    checkIfConfigurationIsOrderd,
    getLibraryConfigurationById,
    getStatusOfLibraryConfiguration,
    getUserHasChildren,
    changeLibraryConfigurationStatus,
    getUserList,
    checkIfConfigurationNameExist,
}
