import React from 'react'

import Image from '../../../components/image'
import emptyDocuments from '../../../assets/svg/emptydocuments.svg'

const NoDocuments = () => {
    return (
        <div className="flex flex-col justify-center items-center h-90">
            <div className="h-48 w-48 mt-32">
                <Image src={emptyDocuments} altText="No Documents" objectFit="object-contain" />
            </div>
            <h2 className="mt-10 font-bold text-lg">Currently no open/new discount request</h2>
            <p className="text-gray-200">There is no current new/open discount request for now.</p>
            <p className="text-gray-200">See history for old requests</p>
        </div>
    )
}

export default NoDocuments
