import companyLogo from 'assets/heffiq/logo.svg'
import Image from '../image'
import appConfig from '../../app.json'
import './splash.css'

const Splash = () => {
    const { companyName } = appConfig

    return (
        <div className="bg-white w-screen h-screen flex flex-col align-center items-center justify-center">
            <div className="flex flex-col items-center gap-4">
                <div>
                    <div className="h-14">
                        <Image
                            src={companyLogo}
                            altText={companyName}
                            height="h-14"
                            objectFit="object-cover"
                        />
                    </div>
                </div>
                <div className="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </div>
    )
}

export default Splash
