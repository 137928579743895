import { callAxios } from 'plugins/api'

const discountApi = 'discount-notification/getAllDiscountForManager/'
const approveDiscountApi = 'discount-notification/approveDiscountRequest/'
const rejectDiscountApi = 'discount-notification/rejectDiscountRequest/'

const getDiscountsForManagerByUserId = (params: { userId: number }) => {
    return callAxios({
        url: `${discountApi}${params.userId}`,
        method: 'GET',
    })
}

const getAllDiscountByUserId = (params: { userId: number }) => {
    return callAxios({
        url: `discount-notification/getAllDiscount/${params.userId}`,
        method: 'GET',
    })
}

const getOldDiscountRequestLogs = (params: { userId: number }) => {
    return callAxios({
        url: `discount-notification/getOldLogs/${params.userId}`,
        method: 'GET',
    })
}

const approveDiscountRequest = (params: {
    userId: number
    notificationId: number
    comment: string
    discount: string
}) => {
    return callAxios({
        url: `${approveDiscountApi}${params.userId}/${params.notificationId}`,
        method: 'POST',
        data: { comment: params.comment, discount: params.discount },
    })
}

const rejectDiscountRequest = (params: {
    userId: number
    notificationId: number
    comment: string
}) => {
    return callAxios({
        url: `${rejectDiscountApi}${params.userId}/${params.notificationId}`,
        method: 'POST',
        data: { comment: params.comment },
    })
}

export const DiscountQueries = {
    getDiscountsByUserId: getDiscountsForManagerByUserId,
    approveDiscountRequest,
    rejectDiscountRequest,
    getAllDiscountByUserId,
    getOldDiscountRequestLogs,
}
