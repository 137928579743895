import { errorToast } from 'utils/toasterUtil'
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, Method } from 'axios'
import { toast } from 'react-toastify'

interface CallAxiosAPI {
    url: string
    method: Method
    data?: any
    headers?: any
    params?: string
    responseType?: any
    isAuthentication?: boolean
    isAuth?: boolean
}

const baseUrl = `${process.env.REACT_APP_API_URL}`

export const callAxios = async ({
    url,
    method,
    data,
    headers,
    params,
    responseType,
    isAuth,
}: CallAxiosAPI) => {
    const token = await localStorage.getItem('CPQ_ACCESS_TOKEN')
    const accessToken = token !== null ? JSON.parse(token) : null

    const config: AxiosRequestConfig = {
        method: method || 'GET',
        url: `${baseUrl}${url}`,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: accessToken !== null ? `Bearer ${accessToken}` : '',
            ...headers,
        },
        data,
        params,
        timeout: 20000, // 20 seconds
        responseType,
    }
    return axios(config)
        .then((res: any) => {
            return res
        })
        .catch(err => {
            if (isAuth) {
                return err
            }
            // UnAuthenticated
            if (err.response.status === 401) {
                localStorage.removeItem('CPQ_LOGGED_IN_USER')
                localStorage.removeItem('CPQ_ACCESS_TOKEN')
                localStorage.clear()
                window.location.reload()
                toast('Session expired Please login again', errorToast)
                throw new Error(err?.response?.data?.message || 'Process failed')
            } else {
                toast(err?.response?.data?.message || 'Process failed', errorToast)
                throw new Error(err?.response?.data?.message || 'Process failed')
            }
            // toast(err?.response?.data?.message || 'Process failed', errorToast)
            // throw new Error(err?.response?.data?.message || 'Process failed')
        })
}
