export const getPillsColor = (
    type: string,
    noSplit?: boolean,
): {
    bg: string
    text: string
} => {
    const baseType = noSplit ? type : String(type).split(' ')[0]
    switch (baseType) {
        case 'submitted':
            return {
                bg: 'bg-[#CDFFCD]',
                text: 'text-[#007F00]',
            }

        case 'pending':
            return {
                bg: 'bg-[#FFECCC]',
                text: 'text-[#965E00]',
            }

        case 'ordered':
            return {
                bg: 'bg-[#BBE7FF]',
                text: 'text-[#0098EE]',
            }

        case 'cancelled':
            return {
                bg: 'bg-[#FFE0E0]',
                text: 'text-[#D30000]',
            }

        case 'lost':
            return {
                bg: 'bg-[#FFE0E0]',
                text: 'text-[#D30000]',
            }

        case 'postponed':
            return {
                bg: 'bg-[#FFE0E0]',
                text: 'text-[#D30000]',
            }

        default:
            return {
                bg: 'bg-white',
                text: 'bg-black',
            }
    }
}
