import React, { useState } from 'react'
import { useQuery } from 'react-query'
import BlankLayout from 'layout/BlankLayout'
import { ArticleQueries } from '../queries/articles'
import { Article } from '../model/Article'
import LongDescription from '../components/LongDescription'
import Loading from '../../../components/loading'

const Disclaimer = () => {
    const [privacyStatement, setPrivacyStatement] = useState<Article[] | []>([])

    const { isLoading, isFetching } = useQuery(['privacy'], () => ArticleQueries.getDisclaimer(), {
        onSuccess: (data: any) => {
            setPrivacyStatement(data.data)
        },
    })

    if (isLoading || isFetching) {
        return (
            <BlankLayout>
                <div className="flex w-full justify-center items-center">
                    <Loading />
                </div>
            </BlankLayout>
        )
    }

    return (
        <BlankLayout>
            <LongDescription
                description={privacyStatement[0].article_description}
                isFetching={isFetching}
                isLoading={isLoading}
                title={privacyStatement[0].article_title}
            />
        </BlankLayout>
    )
}

export default Disclaimer
