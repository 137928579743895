/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Input, Select } from 'antd'
import AlertModal from 'components/modal/alertModal'
import { useFormik } from 'formik'
import { map } from 'lodash'
import { useAuth } from 'modules/auth/context/useAuth'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { getGenericQuestion, updateQuoteStatus } from '../queries/quotes'

const size = 'large'
const roundedClass = 'rounded-lg flex-1'

interface QuoteGenericQuestionProps {
    openModal: boolean
    quoteId: string | number
    oldStatus: string
    newStatus: string
    callback: () => void
    refetch: () => void
}

const QuoteGenericQuestion = (props: QuoteGenericQuestionProps) => {
    const { user } = useAuth()
    const { quoteId, oldStatus, newStatus, callback, openModal, refetch } = props
    const genericeQuestion = useQuery('genericQuestion', () => getGenericQuestion())
    const updateStatus = useMutation(
        'updateStatus',
        formData => updateQuoteStatus(String(quoteId) || '', formData, user.id),
        {
            onSuccess: () => {
                toast('Quote Status Changed Successfully')
                refetch()
            },
        },
    )

    const genericQuestionFormik = useFormik({
        initialValues: {
            generic_id: '',
            custom_comment: '',
            status: '',
        },
        onSubmit: async (values: any) => {
            await updateStatus.mutate({
                ...values,
                status: newStatus,
            })
            refetch()
            callback()
        },
    })

    return (
        <AlertModal
            show={openModal}
            content={
                <>
                    <p className="text-center text-lg">Status Change Reason</p>
                    <div className="bg-red-200 p-3 rounded-md mt-4">
                        <p className="mt-2 text-left text-sm">
                            Status to be changed from{' '}
                            <strong className="uppercase"> {oldStatus} </strong> to{' '}
                            <strong className="uppercase"> {newStatus} </strong>
                        </p>
                        <p className="text-left text-sm">
                            Why do you want to change the status of this quote?{' '}
                            <strong>{quoteId}</strong>
                        </p>
                    </div>
                    <div className="mt-4">
                        <Form.Item
                            name="generic_id"
                            className="flex flex-col"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <h5 className="capitalize text-lg text-left mb-2">Reason</h5>
                            <Select
                                defaultValue="Choose your option"
                                onChange={(value: string) =>
                                    genericQuestionFormik.setFieldValue('generic_id', value)
                                }
                                className="rounded-lg"
                                size={size}
                            >
                                {map(genericeQuestion?.data?.data || [], (option: any) => (
                                    <Select.Option
                                        value={option.generic_id}
                                        key={option.generic_id}
                                    >
                                        {option.generic_question}
                                    </Select.Option>
                                ))}
                            </Select>
                            {genericQuestionFormik.errors &&
                                genericQuestionFormik.errors.generic_id &&
                                genericQuestionFormik.touched.generic_id && (
                                    <p className="text-red-600 mt-1">
                                        {genericQuestionFormik.errors.generic_id}
                                    </p>
                                )}
                        </Form.Item>

                        <Form.Item
                            name="quotation_name"
                            className="flex flex-col"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <h5 className="capitalize text-lg text-left mb-2">Custom Message</h5>
                            <Input
                                name="custom_comment"
                                value={genericQuestionFormik.values.custom_comment}
                                onChange={genericQuestionFormik.handleChange}
                                placeholder="Enter custom message"
                                size={size}
                                className={roundedClass}
                            />
                            {genericQuestionFormik.errors &&
                                genericQuestionFormik.errors.custom_comment &&
                                genericQuestionFormik.touched.custom_comment && (
                                    <p className="text-red-600 mt-1">
                                        {genericQuestionFormik.errors.custom_comment}
                                    </p>
                                )}
                        </Form.Item>
                    </div>
                </>
            }
            onConfirm={() => genericQuestionFormik.handleSubmit()}
            onCancel={callback}
        />
    )
}

export default QuoteGenericQuestion
