/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
import Table from 'components/table'
import { TbDiscount, TbDiscountOff } from 'react-icons/tb'

import { Collapse, Tooltip } from 'antd'
import appJson from '../../../../app.json'
import PanelHeader from '../panelHeader'
import ConfigureDetail from './configureDetail'

interface ConfigureProps {
    configuration: any
    orderId?: string
    quoteId?: string
    hideImage?: boolean
    isOrder?: boolean
}

const partsColumns: any = [
    {
        id: 'group',
        name: 'Model Number',
        left: true,
        cell: (row: {
            machine_part_model_number: number
            machine_category_model_number: number
        }) => {
            return (
                <div className="flex align-center items-center gap-2">
                    <h1 className="text-semibold mb-2 text-md text-center">
                        {row.machine_part_model_number
                            ? row.machine_part_model_number
                            : row.machine_category_model_number}
                    </h1>
                </div>
            )
        },
        width: '168px',
    },
    {
        id: 'discount_applicable',
        name: 'Discount Applicable',
        left: true,
        width: '200px',
        height: 'fit-content',
        wrap: true,
        selector: (row: any) => {
            if (row.discount_applicable) return null
            return (
                <Tooltip
                    title={
                        row.discount_applicable
                            ? 'Discount is applied in this part'
                            : 'Discount is not applied in this part (Fixed Price)'
                    }
                >
                    <div className="flex items-center gap-2 cursor-pointer">
                        <p>
                            {row.discount_applicable ? (
                                <TbDiscount size={22} className="text-green-300" />
                            ) : (
                                <TbDiscountOff size={22} className="text-red-300" />
                            )}
                        </p>
                        <p className="text-md capitalize">
                            {row.discount_applicable ? 'yes' : 'no'}
                        </p>
                    </div>
                </Tooltip>
            )
        },
    },
    {
        id: 'description',
        name: 'Description',
        left: true,
        width: '256px',
        selector: (row: { machine_part_name: string; machine_category: string }) => (
            <p title={row.machine_part_name} className="cursor-pointer text-md text-left">
                {row.machine_part_name ? row.machine_part_name : row.machine_category}
            </p>
        ),
    },
    {
        id: 'remarks',
        name: 'Remarks',
        left: true,
        width: '168px',
        selector: (row: { machine_part_remarks: string }) => {
            return <p className="text-md"> {row.machine_part_remarks} </p>
        },
    },
    {
        id: 'qty',
        name: 'Qty',
        center: true,
        selector: (row: any) => {
            return <p className="text-md">{row.quantity ? row.quantity : 0}</p>
        },
    },
    {
        id: 'price',
        name: 'Price',
        right: true,
        selector: (row: { price: number; is_included: boolean }) => {
            const { is_included } = row
            if (is_included) {
                return (
                    <p className="text-md">
                        {appJson.companyCurrency} {row.price ? row.price.toFixed(2) : 0} (Included)
                    </p>
                )
            }
            return (
                <p className="text-md">
                    {appJson.companyCurrency} {row.price ? row.price.toFixed(2) : 0}
                </p>
            )
        },
    },
    {
        id: 'total_price',
        name: 'Total Price',
        right: true,
        selector: (row: { is_included: boolean; price: number; quantity: number }) => {
            const { is_included } = row
            if (is_included) {
                return (
                    <p className="text-md">
                        {appJson.companyCurrency}{' '}
                        {row.price && row.quantity ? (row.price * row.quantity).toFixed(2) : 0}{' '}
                        (Included)
                    </p>
                )
            }
            return (
                <p className="text-md">
                    {appJson.companyCurrency}{' '}
                    {row.price && row.quantity ? (row.price * row.quantity).toFixed(2) : 0}
                </p>
            )
        },
    },
]

const columns = [
    {
        id: 'group',
        name: 'Model Number',
        left: true,
        cell: (row: {
            machine_part_model_number: number
            machine_category_model_number: number
        }) => {
            return (
                <div className="flex align-center items-center gap-2">
                    <h1 className="text-semibold mb-2 text-md text-center">
                        {row.machine_part_model_number
                            ? row.machine_part_model_number
                            : row.machine_category_model_number}
                    </h1>
                </div>
            )
        },
        width: '168px',
    },
    {
        id: 'discount_applicable',
        name: 'Discount Applicable',
        left: true,
        width: '200px',
        height: 'fit-content',
        wrap: true,
        selector: () => {
            return null
        },
    },
    {
        id: 'description',
        name: 'Description',
        left: true,
        width: '256px',
        selector: (row: { machine_part_name: string; machine_category: string }) => (
            <p title={row.machine_part_name} className="cursor-pointer text-md text-left">
                {row.machine_part_name ? row.machine_part_name : row.machine_category}
            </p>
        ),
    },
    {
        id: 'remarks',
        name: 'Remarks',
        left: true,
        width: '168px',
        selector: (row: { remarks: string }) => {
            return <p className="text-md"> {row.remarks} </p>
        },
    },
    {
        id: 'qty',
        name: 'Qty',
        center: true,
        selector: (row: { only_parts: boolean; quantity: number }) => {
            return <p className="text-md">{row.only_parts ? 0 : row.quantity}</p>
        },
    },
    {
        id: 'price',
        name: 'Price',
        right: true,
        selector: (row: { price: number }) => (
            <p className="text-md">
                {appJson.companyCurrency} {row.price ? row.price.toFixed(2) : 0}
            </p>
        ),
    },
    {
        id: 'total_price',
        name: 'Total Price',
        right: true,
        selector: (row: { price: number; quantity: number; only_parts: boolean }) => {
            if (row?.only_parts) {
                return <p className="text-md">{appJson.companyCurrency} 0</p>
            }
            return (
                <p className="text-md">
                    {appJson.companyCurrency}{' '}
                    {row.price && row.quantity ? (row.price * 1).toFixed(2) : 0}
                </p>
            )
        },
    },
    // {
    //     id: 'note',
    //     name: 'Note',
    //     selector: (row: any) => <p className="text-md"> {row.name} </p>,
    // },
]

const ManualAdjustmentColumns: any = [
    {
        id: 'group',
        name: 'Model Number',
        left: true,
        cell: (row: { manual_adjustment_model_number: number }) => {
            return (
                <div className="flex align-center items-center gap-2">
                    <h1 className="text-semibold mb-2 text-md text-center">
                        {row.manual_adjustment_model_number}
                    </h1>
                </div>
            )
        },
        width: '168px',
    },
    {
        id: 'description',
        name: 'Description',
        left: true,
        width: '256px',
        selector: (row: { manual_adjustment_name: string }) => (
            <p className="cursor-pointer text-md text-left">{row.manual_adjustment_name}</p>
        ),
    },
    {
        id: 'remarks',
        name: 'Remarks',
        left: true,
        width: '168px',
        selector: (row: { remarks: string }) => {
            return <p className="text-md"> {row.remarks} </p>
        },
    },
    {
        id: 'qty',
        name: 'Qty',
        center: true,
        selector: (row: { manual_adjustment_quantity: number }) => (
            <p className="text-md">{row.manual_adjustment_quantity.toFixed(2)}</p>
        ),
    },
    {
        id: 'price',
        name: 'Price',
        right: true,
        selector: (row: { manual_adjustment_price: number }) => (
            <p className="text-md">
                {appJson.companyCurrency} {row.manual_adjustment_price.toFixed(2)}
            </p>
        ),
    },
    {
        id: 'total_price',
        name: 'Total Price',
        right: true,
        selector: (row: any) => (
            <p className="text-md">
                {appJson.companyCurrency}{' '}
                {(
                    row.manual_adjustment_price &&
                    row.manual_adjustment_quantity &&
                    row.manual_adjustment_price * row.manual_adjustment_quantity
                ).toFixed(2)}
            </p>
        ),
    },
    // {
    //     id: 'note',
    //     name: 'Note',
    //     selector: (row: any) => <p className="text-md"> {row.manual_adjustment_note} </p>,
    // },
]

const Configure = (props: ConfigureProps) => {
    const { configuration, orderId, quoteId } = props
    const { Panel } = Collapse

    return (
        <div className="w-full bg-white px-4 py-4 rounded-md flex flex-col">
            <div>
                <ConfigureDetail
                    machine={configuration.machine}
                    orderId={Boolean(orderId)}
                    machineQuoteId={quoteId}
                    hideImage={false}
                />
            </div>
            <div>
                <Collapse
                    className="flex-1 w-full"
                    style={{ backgroundColor: 'transparent', width: '100%', flex: 1 }}
                    defaultActiveKey={[1, 2, 3]}
                >
                    <Panel
                        key={1}
                        className="flex-1 w-full"
                        header={PanelHeader({ title: 'Component' })}
                    >
                        <Table
                            columns={columns}
                            data={[configuration.machine]}
                            pagination={false}
                            striped={false}
                        />
                    </Panel>
                    {configuration.parts.length > 0 && (
                        <Panel
                            className="flex-1 w-full"
                            key={2}
                            header={PanelHeader({ title: 'Compatible Parts' })}
                        >
                            <Table
                                columns={partsColumns}
                                data={configuration.parts}
                                pagination={false}
                                noHeader
                                striped={false}
                            />
                        </Panel>
                    )}
                    {configuration.adjustment.length > 0 && (
                        <Panel
                            className="flex-1 w-full"
                            key={3}
                            header={PanelHeader({ title: 'Manual Adjustments' })}
                        >
                            <Table
                                columns={ManualAdjustmentColumns}
                                data={configuration.adjustment}
                                pagination={false}
                                noHeader
                                striped={false}
                            />
                        </Panel>
                    )}
                </Collapse>
            </div>
        </div>
    )
}

export default Configure
